import {useFormContext} from 'react-hook-form'
import {RealEstateLaunch} from '../../../../../../services/urbit-gis/types'
import {GetEstadoCidadeChosen} from '../../../../../components/UI/Chosens/GetEstadoCidadeChosen'
import {FormInputAndLabel} from '../../../../../components/UI/Inputs/FormInputAndLabel'
import {RealEstateLaunchFormData} from '../typologies/formValidations'
import {AutocompleteGoogle} from '../../../../../components/UI/Autocomplete/Autocomplete'
import {useEmpreendimentos} from '../../context/useEmpreendimentos'
import {ApiUrbit} from '../../../../../../infra/http/urbit'

interface Props {
  data?: RealEstateLaunch
}

export function Localization({data}: Props) {
  const {
    register,
    setValue,
    formState: {errors},
  } = useFormContext<RealEstateLaunchFormData>()
  const {setLaunchData} = useEmpreendimentos()

  async function getMunicipio(lat: number, lng: number) {
    // TODO: rever consulta do municipio
    const result = await ApiUrbit.get(`municipio/sao-paulo/lote-fiscal/${lat}/${lng}?distancia=300`)
    return result
  }
  async function onChangeAddres(endereco) {
    const municipio = await getMunicipio(endereco.longitude, endereco.latitude)
    setLaunchData({
      address: endereco.endereco_completo,
      zipcode: endereco.cep,
      id_state: Number(municipio.itens[0]?.id_estado),
      id_city: Number(municipio.itens[0]?.id_cidade),
      neighborhood: endereco.bairro,
      number: endereco.numero,
      latitude: Number(endereco.latitude),
      longitude: Number(endereco.longitude),
      geom: {
        type: 'Point',
        coordinates: [Number(endereco.longitude), Number(endereco.latitude)],
      },
    })
  }

  if (!data) {
    return (
      <div className='row'>
        <div className='form-group col-md-12 form-line'>
          <div className='row'>
            <div className='form-group'>
              <label className='col-form-label'>Endereço</label>
              <AutocompleteGoogle
                onChangeAddress={onChangeAddres}
                endereco={null}
              ></AutocompleteGoogle>
            </div>
          </div>
          <div className='row mt-4'>
            <div className='col-4'>
              <FormInputAndLabel
                label='CEP'
                className='form-control form-control-solid'
                placeholder='Ex: 11111-111'
                register={{...register('zipcode')}}
                error={errors ? errors.zipcode?.message : ''}
              />
            </div>
            <GetEstadoCidadeChosen
              defaultValueCidade={null}
              defaultValueEstado={null}
              classNameCity={'col-4 col-xs-12 mt-1'}
              classNameEstado={'col-4 col-xs-12 mt-1'}
              SetValueEstado={(value) => {
                setValue('id_state', Number(value))
              }}
              SetValueCidade={(value) => {
                setValue('id_city', Number(value))
              }}
              errorCity={errors ? errors.id_city?.message : ''}
              errorState={errors ? errors.id_state?.message : ''}
            />
          </div>
          <div className='row mt-4'>
            <div className='col-4'>
              <FormInputAndLabel
                label='Bairro'
                className='form-control form-control-solid'
                placeholder='Ex: Vila Madalena, Pinheiros, Liberdade...'
                register={{...register('neighborhood')}}
                error={errors ? errors.neighborhood?.message : ''}
              />
            </div>
            <div className='col-4'>
              <FormInputAndLabel
                label='Numero'
                className='form-control form-control-solid'
                placeholder='Ex: 123'
                register={{...register('number')}}
                error={errors ? errors.number?.message : ''}
              />
            </div>
            <div className='col-4'>
              <FormInputAndLabel
                label='Complemento'
                className='form-control form-control-solid'
                placeholder='Ex: Casa, Andar, Apartamento'
                register={{...register('complement')}}
                error={errors ? errors.complement?.message : ''}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='row'>
      <div className='form-group col-md-12 form-line'>
        <div className='row'>
          <div className='form-group'>
            <label className='col-form-label'>Endereço</label>
            <AutocompleteGoogle
              onChangeAddress={onChangeAddres}
              endereco={data?.address}
            ></AutocompleteGoogle>
          </div>
        </div>
        <div className='row mt-4'>
          <div className='col-4'>
            <FormInputAndLabel
              label='CEP'
              className='form-control form-control-solid'
              placeholder='Ex: 11111-111'
              defaultValue={data.zipcode}
              register={{...register('zipcode')}}
              error={errors ? errors.zipcode?.message : ''}
            />
          </div>
          <GetEstadoCidadeChosen
            defaultValueCidade={data.id_city}
            defaultValueEstado={data.id_state}
            classNameCity={'col-4 col-xs-12 mt-1'}
            classNameEstado={'col-4 col-xs-12 mt-1'}
            SetValueEstado={(value) => {
              setValue('id_state', Number(value))
            }}
            SetValueCidade={(value) => {
              setValue('id_city', Number(value))
            }}
            errorCity={errors ? errors.id_city?.message : ''}
            errorState={errors ? errors.id_state?.message : ''}
          />
        </div>
        <div className='row mt-4'>
          <div className='col-4'>
            <FormInputAndLabel
              label='Bairro'
              className='form-control form-control-solid'
              placeholder='Ex: Vila Madalena, Pinheiros, Liberdade...'
              defaultValue={data.neighborhood}
              register={{...register('neighborhood')}}
              error={errors ? errors.neighborhood?.message : ''}
            />
          </div>
          <div className='col-4'>
            <FormInputAndLabel
              label='Numero'
              className='form-control form-control-solid'
              placeholder='Ex: 123'
              defaultValue={data.number}
              register={{...register('number')}}
              error={errors ? errors.number?.message : ''}
            />
          </div>
          <div className='col-4'>
            <FormInputAndLabel
              label='Complemento'
              className='form-control form-control-solid'
              placeholder='Ex: Casa, Andar, Apartamento'
              defaultValue={data.complement}
              register={{...register('complement')}}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
