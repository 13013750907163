import {BiRuler} from 'react-icons/bi'
import {DrawFactory} from '../../Draw/DrawFactory'
import {useRef} from 'react'

export const DrawMeasure = ({map}) => {
  const refBtn = useRef<HTMLButtonElement>()
  const meassure = DrawFactory.Draw('measure', map)
  function clearActiveAction() {
    document.querySelectorAll('.btn-action-map').forEach(function (x) {
      x.classList.remove('active')
    })
  }

  function onClickMeasure(e) {
    if (!e.currentTarget.classList.contains('active')) {
      const child = refBtn.current.childNodes[1]
      child['classList'].remove('hide')
      e.currentTarget.classList.add('active')
      meassure.setActive(true)
    } else {
      refBtn.current.childNodes[1]['classList'].add('hide')
      meassure.setActive(false)
      meassure.setMeassureDeactive()
      clearActiveAction()
      e.currentTarget.classList.remove('active')
    }
  }

  return (
    <>
      <button
        ref={refBtn}
        className='d-flex p-4 btn btn-action-map'
        style={{backgroundColor: '#1BA278', color: '#d6d6d6'}}
        onClick={onClickMeasure}
      >
        <BiRuler className='fs-1 text-white'></BiRuler>
        <span className='ms-2 text-white hide'>Cancelar</span>
      </button>
    </>
  )
}
