import {DrawMeasure} from './DrawMeasure'

export const MapDrawControl = ({map}) => {
  return (
    <>
      <div>
        <ul className='list-unstyled mb-0 d-flex flex-column align-items-center mt-20 mt-25 border-top position-absolute'>
          <li className='mb-4'>
            <DrawMeasure map={map}></DrawMeasure>
          </li>
        </ul>
      </div>
    </>
  )
}
