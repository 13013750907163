import React from 'react'
import {useMutation} from '@apollo/client'

import {Link} from 'react-router-dom'
import {DELETE_EMPREENDIMENTO} from '../../../graphql/mutations/empreendimentos'
import {DeleteEmpreendimentosResponse} from '../../../graphql/type/empreendimentos'
import {GET_EMPREENDIMENTOS} from '../../../graphql/query/empreendimentos'
import {KTSVG} from '../../../../_metronic/helpers'
import Swal from 'sweetalert2'
import {MutationDeleteEmpreendimentos, MutationDesbloquearEmpreendimentos} from '../../../graphql'
import {MutationSetLogAcessoRecurso} from '../../../graphql/services/LogAcesso'
import {getPermissaoAcao, isDiretor} from '../../../utils/PermissoesAcesso'
import {BiLockOpen} from 'react-icons/bi'
import {ModalDesbloquearExcecao} from './ModalDesbloquearExcecao'

type Props = {
  id: number
  idUser: number
  bloqueado: number
}

export const Buttons: React.FC<Props> = ({id, idUser, bloqueado}) => {
  const {FormSetLogAcessoRecurso} = MutationSetLogAcessoRecurso()
  const {HandleDeleteEmpreendimentos, loading} = MutationDeleteEmpreendimentos()
  const {HandleDesbloquearEmpreendimentos} = MutationDesbloquearEmpreendimentos()
  if (loading) {
    Swal.fire('Enviando Informações...', '')
    Swal.showLoading()
  }

  function confirmDelete(id: number) {
    Swal.fire({
      title: 'Tem certeza que deseja excluir?',
      showCancelButton: true,
      input: 'textarea',
      inputLabel: 'Motivo',
      inputPlaceholder: 'Informe o motivo',
      inputValidator: (result) => {
        return !result && 'Informe o motivo.'
      },
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      showCloseButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (result.value === '') {
          Swal.showValidationMessage('You need to write something!')
          return false
          // Swal.fire('', 'Informe o motivo.', 'warning')
        } else {
          const resultDelete = await HandleDeleteEmpreendimentos({
            variables: {deleteEmpreendimentoId: id, motivo: result.value},
          })
          if (!resultDelete) {
            Swal.fire('ERRO!', 'Ocorreu um erro durante a execução.', 'warning')
          } else {
            Swal.fire('Sucesso!', 'Empreendimento deletado com sucesso.', 'success')
            FormSetLogAcessoRecurso({
              action: 'delete',
              ferramenta: 'empreendimento',
              funcionalidade: 'informacoes-gerais',
              url: `/empreendimentos/details/${id}#informacoes-gerais`,
            })
          }
        }
      }
    })
  }
  async function desbloquear() {
    Swal.fire({
      title: 'Deseja desbloqueado esse empreendimento?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Desbloquear',
      showCloseButton: true,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        await HandleDesbloquearEmpreendimentos({
          variables: {desbloquearEmpreendimentoId: Number(id)},
        }).then((retorno) => {
          if (retorno) {
            Swal.fire('Sucesso!', 'Empreendimento desbloqueado.', 'success')
          }
        })
      }
    })
  }

  return (
    <>
      <div className='d-flex justify-content-end flex-shrink-0'>
        {bloqueado === 1 && isDiretor() ? (
          <button className='btn btn-warning me-2' onClick={desbloquear}>
            <BiLockOpen />
          </button>
        ) : (
          <></>
        )}
        {bloqueado === 2 && isDiretor() ? <ModalDesbloquearExcecao id={id} /> : <></>}

        <Link
          onClick={() =>
            FormSetLogAcessoRecurso({
              ferramenta: 'empreendimentos',
              funcionalidade: 'composicao-terrenos',
              action: 'list',
              url: `/empreendimentos/details/${id}#composicao-terrenos`,
            })
          }
          to={`/empreendimentos/details/${id}#composicao-terrenos`}
          className='btn all-center btn-light-success  me-2'
        >
          Ver Mais
        </Link>
        {getPermissaoAcao('EXCLUSAO', idUser) ? (
          <button
            onClick={(e) => {
              confirmDelete(id)
            }}
            disabled={loading}
            className='btn btn-icon btn-light-danger  btn-sm'
          >
            <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
          </button>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}
