import {createContext, ReactNode, useState} from 'react'
import {
  CacheResult,
  DataPropsContext,
  ItensImoveisProps,
  SegmentacaoProps,
  Tipologia,
  VSO,
} from '../components/Types'

export const DataContext = createContext<DataPropsContext>({
  data: [
    {
      id: null,
      apts_por_andar: null,
      area_privativa_maxima: null,
      area_privativa_minima: null,
      data_lancamento: null,
      data_preco: null,
      distancia: null,
      endereco: null,
      estoque: null,
      geom: null,
      id_empreendimento: null,
      id_empreendimento_orulo: null,
      nome: null,
      nome_incorporador: null,
      numero_andares: null,
      numero_torres: null,
      preco_medio_por_area_privativa_atual: null,
      situacao: null,
      tipo: null,
      total_unidades: null,
      vendas: null,
      vendas_perc: null,
      webpage: null,
      removed: false,
      computed: true,
      ano_lancamento: null,
      tipologias: [],
      original_price_minimo: null,
      original_price_maximo: null,
      breve_lancamento: null,
      fase_projeto: 0,
      meses_ate_lancamento: 0,
      meses_construcao: 0,
      fase_projeto_ideal: 0,
      data_atualizacao: null,
      bairro: null,
      intersect: true,
      affordable_program: 0,
    },
  ],
  setData: () => {},
  setVsoIdeal: () => {},
  vsoIdeal: [],
  segmentacao: {
    preco: null,
    area: null,
  },
  setSegmentacao: () => {},
  setCacheResult: () => {},

  cacheResult: {
    sociodemografico: [],
    performance: {
      precoMedioM2Estoque: 0,
      vgvLancado: 0,
      vgvVendido: 0,
      vgvEstoque: 0,
      totalProdutosLancados: 0,
      unidadesLancadas: 0,
      unidadesVendidas: 0,
      estoque: 0,
      lancamentosPorAno: [],
      unidadesLancadasPorAno: [],
      stockPorAno: [],
      vendaPorAno: [],
    },
    performanceResumoMercado: {
      precoMedioEstoque: {
        chartData: [],
        tableData: [],
      },
      vsoPrograma: {
        chartData: [],
        tableData: [],
      },
      faixaMetragem: {
        chartData: [],
        tableData: [],
      },
      faixaTicket: {
        chartData: [],
        tableData: [],
      },
    },
    performanceMercadoRevenda: {
      precoMedianoM2: 0,
      precoQ1m2: 0,
      precoQ3m2: 0,
      totalAmostras: 0,
    },
  },

  naoComputados: [],
  setNaoComputados: (naoComputados: number[]) => {},
  imoveisAnalisados: [],
  setImoveisAnalisados: () => {},
  removeItem: (id: number) => {},
  addItem: (id: number) => {},
  clear: () => {},
  updateComputed: () => {},
  checkItemTipologiaExistTrue: (idTipologia: Tipologia[]) => {
    return []
  },
  removeItemTipologia: (id: number) => {},
  addItemTipologia: (id: number) => {},
  getComputados: (): ItensImoveisProps[] => {
    return []
  },
  getNaoComputados: (): ItensImoveisProps[] => {
    return []
  },
})

interface DataProviderProps {
  children: ReactNode
}

export function DataContextProvider({children}: DataProviderProps) {
  const [data, setData] = useState<ItensImoveisProps[]>(null)
  const [naoComputados, setNaoComputados] = useState<number[]>(null)
  const [imoveisAnalisados, setImoveisAnalisados] = useState<number[]>(null)

  const [vsoIdeal, setVsoIdeal] = useState<VSO>([
    {vso: 0, min: 0, max: 0},
    {vso: 30, min: 4, max: 5},
    {vso: 42, min: 9, max: 10},
    {vso: 48, min: 13, max: 14},
    {vso: 54, min: 17, max: 19},
    {vso: 60, min: 22, max: 24},
    {vso: 62, min: 26, max: 29},
    {vso: 64, min: 30, max: 34},
    {vso: 66, min: 35, max: 39},
    {vso: 68, min: 39, max: 43},
    {vso: 70, min: 44, max: 48},
    {vso: 72, min: 48, max: 53},
    {vso: 73, min: 52, max: 58},
    {vso: 74, min: 54, max: 60},
    {vso: 75, min: 56, max: 62},
    {vso: 77, min: 59, max: 65},
    {vso: 78, min: 61, max: 67},
    {vso: 79, min: 63, max: 69},
    {vso: 80, min: 65, max: 72},
    {vso: 81, min: 67, max: 74},
    {vso: 82, min: 69, max: 76},
    {vso: 84, min: 71, max: 79},
    {vso: 85, min: 73, max: 81},
    {vso: 86, min: 75, max: 83},
    {vso: 87, min: 78, max: 86},
    {vso: 88, min: 80, max: 88},
    {vso: 89, min: 82, max: 90},
    {vso: 91, min: 84, max: 93},
    {vso: 92, min: 86, max: 95},
    {vso: 93, min: 88, max: 97},
    {vso: 94, min: 90, max: 100},
    {vso: 95, min: 91, max: 100},
    {vso: 96, min: 92, max: 100},
    {vso: 97, min: 93, max: 100},
    {vso: 98, min: 93, max: 100},
    {vso: 99, min: 94, max: 100},
    {vso: 100, min: 95, max: 100},
  ])

  const [cacheResult, setCacheResult] = useState<CacheResult>({
    sociodemografico: [],
    performance: {
      precoMedioM2Estoque: 0,
      vgvLancado: 0,
      vgvVendido: 0,
      vgvEstoque: 0,
      totalProdutosLancados: 0,
      unidadesLancadas: 0,
      unidadesVendidas: 0,
      estoque: 0,
      lancamentosPorAno: {},
      unidadesLancadasPorAno: {},
      stockPorAno: {},
      vendaPorAno: {},
    },
    performanceResumoMercado: {
      precoMedioEstoque: {
        chartData: [],
        tableData: [],
      },
      faixaMetragem: {
        chartData: [],
        tableData: [],
      },
      faixaTicket: {
        chartData: [],
        tableData: [],
      },
      vsoPrograma: {
        chartData: [],
        tableData: [],
      },
    },
    performanceMercadoRevenda: {
      precoMedianoM2: 0,
      precoQ1m2: 0,
      precoQ3m2: 0,
      totalAmostras: 0,
    },
  })

  const [segmentacao, setSegmentacao] = useState<SegmentacaoProps>({
    area: [
      [
        [0, 30],
        [30, 45],
        [45, 60],
        [60, 90],
        [90, 120],
        [120, 150],
        [150, null],
      ],
      [
        [0, 120],
        [120, 170],
        [170, 220],
        [220, 270],
        [270, 320],
        [320, 370],
        [370, null],
      ],
    ],
    preco: [
      [
        [0, 225000],
        [255000, 350000],
        [350000, 500000],
        [500000, 750000],
        [750000, 1000000],
        [1000000, 1500000],
        [1500000, null],
      ],
      [
        [0, 1000000],
        [1000000, 1500000],
        [1500000, 2000000],
        [2000000, 2500000],
        [2500000, 3000000],
        [3000000, 3500000],
        [3500000, null],
      ],
    ],
  })

  function getNaoComputados() {
    const naoCoputadosTemp = []

    if (data) {
      // eslint-disable-next-line array-callback-return
      data.map((item: ItensImoveisProps) => {
        // eslint-disable-next-line array-callback-return
        item.tipologias.map((itemTipologia: Tipologia) => {
          if (itemTipologia.computed === false) {
            naoCoputadosTemp.push(itemTipologia.id_tipologia)
          }
        })
        return item
      })
      return naoCoputadosTemp
    }
  }
  function clear() {
    setData((state) => {
      return []
    })
  }
  function removeItem(id: number) {
    setData((state) => {
      return state.map((item: ItensImoveisProps) => {
        if (item.id === id) {
          item.tipologias.map((itemTipologia: Tipologia) => {
            itemTipologia.computed = false
            return {...itemTipologia}
          })
          item.removed = true
        }
        return {...item}
      })
    })
  }

  function addItem(id: number) {
    setData((state) => {
      return state.map((item: ItensImoveisProps) => {
        if (item.id === id) {
          item.tipologias.map((itemTipologia: Tipologia) => {
            itemTipologia.computed = true
            return {...itemTipologia}
          })
          item.removed = false
        }
        return {...item}
      })
    })
  }

  function checkItemTipologiaExistTrue(item: Tipologia[]) {
    const existiTipologiaComputed = item.filter((itemTipologia: Tipologia) => {
      if (itemTipologia.computed === true) {
        return true
      }
    })
    return existiTipologiaComputed.length > 0 ? true : false
  }

  function updateComputed() {
    data.map((item: ItensImoveisProps) => {
      if (checkItemTipologiaExistTrue(item.tipologias)) {
        item.computed = true
        item.removed = false
      } else {
        item.computed = false
        item.removed = true
      }
      return {...item}
    })
    return data
  }
  function removeItemTipologia(id: number) {
    setData((state) => {
      return state.map((item: ItensImoveisProps) => {
        item.tipologias.map((itemTipologia: Tipologia) => {
          if (itemTipologia.id_tipologia === id) {
            itemTipologia.computed = false
          }
          return {...itemTipologia}
        })
        if (checkItemTipologiaExistTrue(item.tipologias)) {
          item.computed = true
          item.removed = false
        } else {
          item.computed = false
          item.removed = true
        }
        return {...item}
      })
    })
  }

  function addItemTipologia(id: number) {
    setData((state) => {
      return state.map((item: ItensImoveisProps) => {
        item.tipologias.map((itemTipologia: Tipologia) => {
          if (itemTipologia.id_tipologia === id) {
            itemTipologia.computed = true
          }
          return {...itemTipologia}
        })
        if (checkItemTipologiaExistTrue(item.tipologias)) {
          item.computed = true
          item.removed = false
        } else {
          item.computed = false
          item.removed = true
        }
        return {...item}
      })
    })
  }

  function getComputados() {
    if (data) {
      return data.filter((item: ItensImoveisProps) => {
        // eslint-disable-next-line array-callback-return
        return item.tipologias.filter((itemTipologia: Tipologia) => {
          if (itemTipologia.computed) {
            return itemTipologia
          }
        })
      })
    } else {
      return []
    }
  }

  return (
    <DataContext.Provider
      value={{
        data,
        setData,
        vsoIdeal,
        segmentacao,
        setSegmentacao,
        cacheResult,
        setCacheResult,
        setVsoIdeal,
        setNaoComputados,
        naoComputados,
        setImoveisAnalisados,
        imoveisAnalisados,
        removeItem,
        clear,
        addItem,
        removeItemTipologia,
        addItemTipologia,
        getComputados,
        getNaoComputados,
        updateComputed,
        checkItemTipologiaExistTrue,
      }}
    >
      {children}
    </DataContext.Provider>
  )
}
