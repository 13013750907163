import {Layout} from '../../Layout'
import {Offcanvas} from 'react-bootstrap'
import Scrollbars from 'react-custom-scrollbars-2'
import {useEffect, useState} from 'react'
import {MenuAction} from '../MenuAction'
import {ClickInfoLancamento} from './ClickInfoLancamentos'
import {ClickInfoDefault} from './ClickInfoDesfault'
import {ClickInfoTerrenos} from './ClickInfoTerrenos'

type PropsClickInfo = {
  showHide: boolean
  info: any
  latLng: google.maps.LatLng
}

type PropsGetComponentInfo = {
  info: any
}

export const GetComponentInfo: React.FC<PropsGetComponentInfo> = ({info}) => {
  const layerinfoType = info.info[0].object.properties.layerName
  if (layerinfoType === 'view_explorer_launches') {
    return (
      <ClickInfoLancamento
        id_empreendimento={info.info[0].object.properties['id_empreendimento']}
      ></ClickInfoLancamento>
    )
  } else if (
    layerinfoType === '9d4c4046-8d59-449e-83ac-dfd66c983e9b' ||
    layerinfoType === 'd7397efd-1461-11ee-adfa-2618765f0467'
  ) {
    return <ClickInfoTerrenos info={info}></ClickInfoTerrenos>
  } else {
    return <ClickInfoDefault info={info}></ClickInfoDefault>
  }
}
export const ClickInfo: React.FC<PropsClickInfo> = ({showHide, info, latLng}) => {
  const handleClose = () => {
    Layout.setShowClickInfo(false)
  }

  const windowHeight = window.innerHeight - 50
  const [wsize, setWsite] = useState(windowHeight)

  useEffect(() => {
    window.addEventListener(
      'resize',
      function (event) {
        setWsite(window.innerHeight - 50)
      },
      true
    )
  }, [])

  let width = ''
  if (Array.isArray(info.info) && info.info.length > 0) {
    const layerinfoType = info.info[0].object.properties.layerName
    if (
      layerinfoType === 'view_explorer_launches' ||
      layerinfoType === '9d4c4046-8d59-449e-83ac-dfd66c983e9b' ||
      layerinfoType === 'd7397efd-1461-11ee-adfa-2618765f0467'
    ) {
      width = 'w-75 '
    }
  }
  return (
    <Offcanvas
      show={showHide}
      className={`${width} zIndex-99 `}
      tabIndex={null}
      onHide={handleClose}
      id='offcanvas-direita'
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className=''>Informações da Feição</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Scrollbars style={{width: '100%', height: wsize}}>
          <MenuAction showHide={false} latLng={latLng}></MenuAction>
          {showHide ? <GetComponentInfo info={info}></GetComponentInfo> : ''}
        </Scrollbars>
      </Offcanvas.Body>
    </Offcanvas>
  )
}
