import {BiLinkExternal} from 'react-icons/bi'
import {Dicionario} from '../../../../../../utils/Dicionario'
import {Explorer} from '../../Explorer'
import {BsFillUnlockFill} from 'react-icons/bs'
import Swal from 'sweetalert2'
import {MutationReativarEmpreendimentos} from '../../../../../../graphql'
import {Tab, Tabs} from 'react-bootstrap'
import {useState} from 'react'
import {MotivosDescarte} from './Terrenos/MotivosDescarte'
import {EmpreendimentoCorretores} from './Terrenos/EmpreendimentoCorretores'
import {HistoricoResponsaveis} from './Terrenos/HistoricoResponsaveis'

type PropsClickInfo = {
  info: any
}

export const ClickInfoTerrenos: React.FC<PropsClickInfo> = ({info}) => {
  const {HandleReativarEmpreendimentos} = MutationReativarEmpreendimentos()
  const [key, setKey] = useState('t-0')
  const [keyInfo, setKeyInfo] = useState('corretor')

  function openEmp(url) {
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  function reativaTerreno(id: number) {
    Swal.fire({
      title: 'Deseja reativar esse empreendimento?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Reativar',
      showCloseButton: true,
      html: `
      <div class="d-flex flex-row align-items-center justify-content-between ">
        <label class="col-4">Situação</label>
        <select class="form-control " id="swal-select" >
          <option value="">--selecione--</option>
          <option value="1">Prospecção Ativa</option>
          <option value="2">Negociação</option>
          <option value="3">Stand By</option>
          <option value="4">Descartado</option>
          <option value="12">Landbank</option>
        </select>
        </div>
      `,
      preConfirm: () => {
        const selectElement = document.getElementById('swal-select') as HTMLSelectElement
        return selectElement ? selectElement.value : null
      },
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        if (!result.value) {
          Swal.fire('Atenção!', 'Informe a situação.')
        } else {
          await HandleReativarEmpreendimentos({
            variables: {reativarEmpreendimentoId: Number(id), situacao: Number(result.value)},
          }).then((retorno) => {
            if (retorno) {
              if (retorno.data) {
                Swal.fire(
                  'Sucesso!',
                  `Empreendimento reativado com sucesso.<br><a href="/empreendimentos/details/${id}#composicao-terrenos">Abrir empreendimento</a>`,
                  'success'
                )
              } else {
                Swal.fire('Erro!', retorno.errors[0].message)
              }
            }
          })
        }
      }
    })
  }

  const dados = info.info[0].object.properties
  return (
    <div className='accordion' id='accordion-info'>
      <Tabs
        defaultActiveKey='resumo-empreendimento'
        activeKey={key}
        className='tab-report px-5 pt-5 pb-0'
        onSelect={(k) => setKey(k)}
      >
        {
          // eslint-disable-next-line array-callback-return
          info.info.map(function (data: any, item1: number) {
            let id = data['layer'].id
            let layer = Explorer.getInfoLayer(id)
            const idEmpreendimento = Number(data.object.properties.id_emp)
            if (layer && dados) {
              return typeof layer !== 'undefined' ? (
                <Tab
                  eventKey={`t-${item1}`}
                  title={`Terreno - ${data.object.properties.nome_empreendimento}-${idEmpreendimento}`}
                >
                  <div className='accordion-body'>
                    <div className='row'>
                      <div className='col-6'>
                        <div className='d-flex justify-content-end'>
                          {dados.link_empreendimento ? (
                            <a
                              href={dados.link_empreendimento}
                              className='btn btn-light'
                              target='_blank'
                              rel='noreferrer'
                            >
                              <BiLinkExternal className='me-2'></BiLinkExternal>Abrir Empreendimento
                            </a>
                          ) : (
                            <></>
                          )}
                          {dados.link_terreno ? (
                            <a
                              href={dados.link_terreno}
                              className='btn btn-light ms-2'
                              target='_blank'
                              rel='noreferrer'
                            >
                              <BiLinkExternal className='me-2'></BiLinkExternal>Abrir Lote
                            </a>
                          ) : (
                            <></>
                          )}
                          {/* {typeof layer['link'] !== 'undefined' &&
                          typeof data['object'].properties !== 'undefined' ? (
                            <a
                              className='btn btn-light-success btn-small    '
                              href={layer['link'].replace(
                                '[ID]',
                                data['object'].properties[layer['link_field']]
                              )}
                              target='_blank'
                              rel='noreferrer'
                            >
                              <BiLinkExternal></BiLinkExternal> + Detalhes
                            </a>
                          ) : (
                            ''
                          )} */}
                        </div>
                        <table className='table table-row-dashed table-row-gray-300 table-hover g-2 gy-2 mt-5'>
                          <tbody>
                            {typeof data['object'].properties !== 'undefined'
                              ? Object.entries(data['object'].properties).map(
                                  // eslint-disable-next-line array-callback-return
                                  function (item, item2) {
                                    const itensNotShow = [
                                      'geom',
                                      'gid',
                                      'id',
                                      'centroid',
                                      'layerName',
                                      'cor',
                                      'cor_zona',
                                      'id_emp',
                                      'link_empreendimento',
                                      'link_terreno',
                                      // 'link_anunc',
                                    ]
                                    if (itensNotShow.indexOf(item[0]) !== -1) {
                                    } else {
                                      return (
                                        <tr key={`tr${item2}`}>
                                          <td className='fw-bold'>
                                            {Dicionario.translate(item[0])}
                                          </td>
                                          <td className='text-right'>
                                            {Dicionario.formatValue(item[0], item[1])}
                                          </td>
                                        </tr>
                                      )
                                    }
                                  }
                                )
                              : ''}
                          </tbody>
                        </table>

                        <div className='d-flex justify-content-start'>
                          {dados.situacao === 'Descartado' ? (
                            <button
                              className='btn btn-info'
                              onClick={() => {
                                reativaTerreno(idEmpreendimento)
                              }}
                            >
                              <BsFillUnlockFill className='me-2'></BsFillUnlockFill>Reativar terreno
                            </button>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <div className='col-6'>
                        <Tabs
                          defaultActiveKey='resumo-empreendimento'
                          activeKey={keyInfo}
                          className='tab-report pt-0 pb-0'
                          fill
                          onSelect={(k) => setKeyInfo(k)}
                        >
                          <Tab eventKey={`corretor`} title={`Corretores`}>
                            <EmpreendimentoCorretores id={idEmpreendimento} />
                          </Tab>
                          <Tab eventKey={`motivo-descarte`} title={`Motivos Descarte`}>
                            <MotivosDescarte id={idEmpreendimento} />
                          </Tab>
                          <Tab eventKey={`historico-responsaveis`} title={`Responsaveis`}>
                            <HistoricoResponsaveis id={idEmpreendimento} />
                          </Tab>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </Tab>
              ) : (
                <></>
              )
            }
          })
        }
      </Tabs>
    </div>
  )
}
