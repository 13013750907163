import {useMutation, useQuery} from '@apollo/client'
import {
  DeleteEmpreendimentosResponse,
  DesbloquearEmpreendimentosResponse,
  EmpreendimentoClienteResponse,
  EmpreendimentoMapaClienteResponse,
  EmpreendimentoMotivoDescarteResponse,
  EmpreendimentoOffClienteResponse,
  EmpreendimentoResponse,
  EmpreendimentosHistoricoResponsaveisResponse,
  EmpreendimentosMesmaQuadraResponse,
  EmpreendimentosRelatorioResponse,
  EmpreendimentosRelatorioStatusResponse,
  EmpreendimentosRelatorioTemperaturaResponse,
  ReativarEmpreendimentosResponse,
  RelatorioEmpreendimentosMesmaQuadraResponse,
  TypesPutEmpreendimentosChangeUserResponse,
  TypesPutEmpreendimentosResponse,
  TypesSetEmpreendimentosResponse,
} from '../type'
import {
  GET_EMPREENDIMENTO,
  GET_EMPREENDIMENTOS,
  GET_EMPREENDIMENTOS_HISTORICO_RESPONSAVEIS,
  GET_EMPREENDIMENTOS_MAPA,
  GET_EMPREENDIMENTOS_MESMA_QUADRA,
  GET_EMPREENDIMENTOS_MOTIVO_DESCARTE,
  GET_EMPREENDIMENTOS_OFF,
  GET_RELATORIO_EMPREENDIMENTOS_MESMA_QUADRA,
} from '../query'
import {
  FormEmpreendimentoFormData,
  FormEmpreendimentoFormSchema,
  FormEmpreendimentoFormUpdateSchema,
  FormEmpreendimentoUpdateFormData,
} from '../formValidations'
import {zodResolver} from '@hookform/resolvers/zod'
import {useForm} from 'react-hook-form'
import {
  DELETE_EMPREENDIMENTO,
  PUT_EMPREENDIMENTO,
  SET_EMPREENDIMENTO,
  GET_EMPREENDIMENTOS_RELATORIO,
  PUT_EMPREENDIMENTO_CHANGE_USER,
  GET_EMPREENDIMENTOS_RELATORIO_STATUS,
  GET_EMPREENDIMENTOS_RELATORIO_TEMPERATURA,
  DESBLOQUEAR_EMPREENDIMENTO,
  REATIVAR_EMPREENDIMENTO,
  DESBLOQUEAR_EXCECAO_EMPREENDIMENTO,
} from '../mutations'

interface QueryEmpreendimentosProps {
  variables: any
  skip?: any
}

export function QueryGetEmpreendimentosMapa({variables}: QueryEmpreendimentosProps) {
  const {data, loading, error} = useQuery<EmpreendimentoMapaClienteResponse>(
    GET_EMPREENDIMENTOS_MAPA,
    {
      variables: {...variables},
      fetchPolicy: 'network-only',
    }
  )

  return {data, loading, error}
}

export function QueryGetEmpreendimentos({variables}: QueryEmpreendimentosProps) {
  const {data, loading, error} = useQuery<EmpreendimentoClienteResponse>(GET_EMPREENDIMENTOS, {
    variables: {...variables},
    fetchPolicy: 'network-only',
  })

  return {data, loading, error}
}

export function QueryGetEmpreendimentosMotivoDescarte({variables}: QueryEmpreendimentosProps) {
  const {data, loading, error} = useQuery<EmpreendimentoMotivoDescarteResponse>(
    GET_EMPREENDIMENTOS_MOTIVO_DESCARTE,
    {
      variables: {...variables},
    }
  )

  return {data, loading, error}
}

export function QueryGetEmpreendimentosHistoricoResponsaveis({
  variables,
}: QueryEmpreendimentosProps) {
  const {data, loading, error} = useQuery<EmpreendimentosHistoricoResponsaveisResponse>(
    GET_EMPREENDIMENTOS_HISTORICO_RESPONSAVEIS,
    {
      variables: {...variables},
    }
  )

  return {data, loading, error}
}

export function QueryGetEmpreendimentosRelatorio({variables}: QueryEmpreendimentosProps) {
  const {data, error, loading} = useQuery<EmpreendimentosRelatorioResponse>(
    GET_EMPREENDIMENTOS_RELATORIO,
    {
      variables: {...variables},
    }
  )

  return {data, error, loading}
}

export function QueryGetEmpreendimentosRelatorioStatus({variables}: QueryEmpreendimentosProps) {
  const {data, error, loading} = useQuery<EmpreendimentosRelatorioStatusResponse>(
    GET_EMPREENDIMENTOS_RELATORIO_STATUS,
    {
      variables: {...variables},
    }
  )

  return {data, error, loading}
}

export function QueryGetEmpreendimentosRelatorioTemperatura({
  variables,
}: QueryEmpreendimentosProps) {
  const {data, error, loading} = useQuery<EmpreendimentosRelatorioTemperaturaResponse>(
    GET_EMPREENDIMENTOS_RELATORIO_TEMPERATURA,
    {
      variables: {...variables},
    }
  )

  return {data, error, loading}
}

export function QueryGetEmpreendimentoById({variables}: QueryEmpreendimentosProps) {
  const {data, error, loading} = useQuery<EmpreendimentoResponse>(GET_EMPREENDIMENTO, {
    variables: {...variables},
  })

  return {data, error, loading}
}

export function QueryGetEmpreendimentoMesmaQuadra({variables}: QueryEmpreendimentosProps) {
  const {data, error, loading} = useQuery<EmpreendimentosMesmaQuadraResponse>(
    GET_EMPREENDIMENTOS_MESMA_QUADRA,
    {
      variables: {...variables},
      fetchPolicy: 'network-only',
    }
  )

  return {data, error, loading}
}
export function QueryGetRelatorioEmpreendimentoMesmaQuadra() {
  const {data, error, loading} = useQuery<RelatorioEmpreendimentosMesmaQuadraResponse>(
    GET_RELATORIO_EMPREENDIMENTOS_MESMA_QUADRA,
    {
      // variables: {...variables},
    }
  )

  return {data, error, loading}
}

export function QueryGetOffEmpreendimentos({variables}: QueryEmpreendimentosProps) {
  const {data} = useQuery<EmpreendimentoOffClienteResponse>(GET_EMPREENDIMENTOS_OFF, {
    variables: {...variables},
  })

  return data
}

export function MutationCreateEmpreendimento() {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: {errors},
  } = useForm<FormEmpreendimentoFormData>({
    resolver: zodResolver(FormEmpreendimentoFormSchema),
  })

  const [setEmpreendimentoBody, {error, loading, data: DataSetEmpreendimento}] =
    useMutation<TypesSetEmpreendimentosResponse>(SET_EMPREENDIMENTO, {
      refetchQueries: [GET_EMPREENDIMENTOS],
    })

  async function FormEmpreendimento(data: FormEmpreendimentoFormData) {
    await setEmpreendimentoBody({
      variables: {
        empreendimentos: {
          nome: data.nome,
          endereco: data.endereco,
        },
      },
    })
  }

  return {
    register,
    handleSubmit,
    FormEmpreendimento,
    loading,
    errors,
    error,
    control,
    DataSetEmpreendimento,
    reset,
  }
}

export function MutationPutEmpreendimento() {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    control,
    formState: {errors},
  } = useForm<FormEmpreendimentoUpdateFormData>({
    resolver: zodResolver(FormEmpreendimentoFormUpdateSchema),
  })

  const [setEmpreendimentoBody, {error, loading, data: DataPutEmpreendimento}] =
    useMutation<TypesPutEmpreendimentosResponse>(PUT_EMPREENDIMENTO, {
      refetchQueries: [GET_EMPREENDIMENTO, GET_EMPREENDIMENTOS],
    })

  async function FormEmpreendimento(data: FormEmpreendimentoUpdateFormData) {
    return await setEmpreendimentoBody({
      variables: {
        empreendimentos: {...data},
      },
    })
  }

  return {
    register,
    handleSubmit,
    FormEmpreendimento,
    loading,
    errors,
    error,
    control,
    setError,
    setValue,
    getValues,
    DataPutEmpreendimento,
  }
}

export function MutationPutEmpreendimentoChangeUser() {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    control,

    formState: {errors},
  } = useForm<FormEmpreendimentoUpdateFormData>({
    resolver: zodResolver(FormEmpreendimentoFormUpdateSchema),
  })

  const [setEmpreendimentoChangeUserBody, {error, loading, data: DataPutEmpreendimentoChangeUser}] =
    useMutation<TypesPutEmpreendimentosChangeUserResponse>(PUT_EMPREENDIMENTO_CHANGE_USER, {
      refetchQueries: [GET_EMPREENDIMENTO, GET_EMPREENDIMENTOS],
    })

  async function FormEmpreendimentoChangeUser({variables}: QueryEmpreendimentosProps) {
    return await setEmpreendimentoChangeUserBody({
      variables: {...variables},
    })
  }

  return {
    register,
    handleSubmit,
    FormEmpreendimentoChangeUser,
    loading,
    errors,
    error,
    control,
    setError,
    setValue,
    getValues,
    DataPutEmpreendimentoChangeUser,
  }
}
export function MutationDeleteEmpreendimentos() {
  const [deleteEmpreendimento, {loading, data, error}] = useMutation<DeleteEmpreendimentosResponse>(
    DELETE_EMPREENDIMENTO,
    {
      refetchQueries: [GET_EMPREENDIMENTOS, GET_EMPREENDIMENTO],
    }
  )

  async function HandleDeleteEmpreendimentos({variables}: QueryEmpreendimentosProps) {
    try {
      const result = await deleteEmpreendimento({
        variables: {...variables},
        refetchQueries: [GET_EMPREENDIMENTOS, GET_EMPREENDIMENTO],
      })
      return result
    } catch (e) {
      if (e) {
        return false
      }
    }
  }

  return {HandleDeleteEmpreendimentos, loading, error, data}
}

export function MutationDesbloquearEmpreendimentos() {
  const [desbloquearEmpreendimento, {loading, data, error}] =
    useMutation<DesbloquearEmpreendimentosResponse>(DESBLOQUEAR_EMPREENDIMENTO, {
      refetchQueries: [GET_EMPREENDIMENTOS, GET_EMPREENDIMENTO],
    })

  async function HandleDesbloquearEmpreendimentos({variables}: QueryEmpreendimentosProps) {
    try {
      const result = await desbloquearEmpreendimento({
        variables: {...variables},
        refetchQueries: [GET_EMPREENDIMENTOS, GET_EMPREENDIMENTO],
      })
      return result
    } catch (e) {
      if (e) {
        return false
      }
    }
  }

  return {HandleDesbloquearEmpreendimentos, loading, error, data}
}

export function QueryGetConflitoQuadraEmpreendimentos({variables}: QueryEmpreendimentosProps) {
  const {data, loading, error} = useQuery<EmpreendimentoClienteResponse>(GET_EMPREENDIMENTOS, {
    variables: {...variables},
    fetchPolicy: 'network-only',
  })

  return {data, loading, error}
}

export function MutationDesbloquearExcecaoEmpreendimentos() {
  const [desbloquearExcecaoEmpreendimento, {loading, data, error}] =
    useMutation<DesbloquearEmpreendimentosResponse>(DESBLOQUEAR_EXCECAO_EMPREENDIMENTO, {
      refetchQueries: [GET_EMPREENDIMENTOS, GET_EMPREENDIMENTO],
    })

  async function HandleDesbloquearExcecaoEmpreendimentos({variables}: QueryEmpreendimentosProps) {
    try {
      const result = await desbloquearExcecaoEmpreendimento({
        variables: {...variables},
        refetchQueries: [GET_EMPREENDIMENTOS, GET_EMPREENDIMENTO],
      })
      return result
    } catch (e) {
      if (e) {
        return false
      }
    }
  }

  return {HandleDesbloquearExcecaoEmpreendimentos, loading, error, data}
}

export function MutationReativarEmpreendimentos() {
  const [reativarEmpreendimento, {loading, data, error}] =
    useMutation<ReativarEmpreendimentosResponse>(REATIVAR_EMPREENDIMENTO, {
      refetchQueries: [GET_EMPREENDIMENTOS, GET_EMPREENDIMENTO],
    })

  async function HandleReativarEmpreendimentos({variables}: QueryEmpreendimentosProps) {
    try {
      const result = await reativarEmpreendimento({
        variables: {...variables},
        refetchQueries: [GET_EMPREENDIMENTOS, GET_EMPREENDIMENTO],
      })
      return result
    } catch (e) {
      if (e) {
        return false
      }
    }
  }

  return {HandleReativarEmpreendimentos, loading, error, data}
}

export function MutationRecoveryEmpreendimento() {
  const [putEmpreendimento, {loading, data, error}] = useMutation<TypesPutEmpreendimentosResponse>(
    PUT_EMPREENDIMENTO,
    {
      refetchQueries: [GET_EMPREENDIMENTOS, GET_EMPREENDIMENTO],
    }
  )

  async function HandleRecoveryEmpreendimento({variables}: QueryEmpreendimentosProps) {
    try {
      const result = await putEmpreendimento({
        variables: {...variables},
        refetchQueries: [GET_EMPREENDIMENTOS, GET_EMPREENDIMENTO],
      })
      return result
    } catch (e) {
      if (e) {
        return false
      }
    }
  }

  return {HandleRecoveryEmpreendimento, loading, error, data}
}
