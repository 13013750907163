import {useEffect, useState} from 'react'
import {RealEstateLaunch} from '../../../../../services/urbit-gis/types'
import {FormInputAndLabel} from '../../../../components/UI/Inputs/FormInputAndLabel'
import {useFormContext} from 'react-hook-form'
import {RealEstateLaunchFormData} from './typologies/formValidations'
import {ErrorMessageComponent} from '../../../components/UI/ErrorMessage'
import {AreaInput} from '../../../../components/UI/Inputs/AreaInput'
import {useEmpreendimentos} from '../context/useEmpreendimentos'
import {GetDevelopersChosen} from '../../../../components/UI/Chosens/GetDevelopersChosen'

interface Props {
  data?: RealEstateLaunch
}

export function CardHeaderNavigation({data}: Props) {
  const {
    register,
    setValue,
    getValues,
    control,
    formState: {errors},
  } = useFormContext<RealEstateLaunchFormData>()
  const [isChecked, setIsChecked] = useState(false)
  const [isCheckedMCMV, setIsCheckedMCMV] = useState(false)

  // Sync checkbox state with the prop data when data changes
  useEffect(() => {
    setIsChecked(data ? (data.future_launch === 1 ? true : false) : false)
    setValue('future_launch', isChecked ? 1 : 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    setIsCheckedMCMV(data ? (data.affordable_program === 1 ? true : false) : false)
    setValue('affordable_program', isChecked ? 1 : 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked
    setIsChecked(checked)
    setValue('future_launch', checked ? 1 : 0)
  }

  const handleCheckboxMCMVChange = (e) => {
    const checked = e.target.checked
    setIsCheckedMCMV(checked)
    setValue('affordable_program', checked ? 1 : 0)
  }
  // const address = watch('address')
  // const id_state = watch('id_state')
  // const neighborhood = watch('neighborhood')
  // const number = watch('number')
  // const zipcode = watch('zipcode')

  useEffect(() => {
    if (data) {
      setValue('address', data.address)
      setValue('id_city', data.id_city)
      setValue('id_state', data.id_state)
      setValue('latitude', data.latitude)
      setValue('longitude', data.longitude)
      setValue('neighborhood', data.neighborhood)
      setValue('number', data.number)
      setValue('zipcode', data.zipcode)
    }
    // Atualiza os campos com os valores iniciais quando o 'data' for alterado
    if (data?.launch_date) {
      setValue('launch_date', data.launch_date)
    }
    if (data?.opening_date) {
      setValue('opening_date', data.opening_date)
    }
  }, [data, setValue])

  const handleLaunchDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    // Se houver uma data válida, formate-a antes de definir o valor
    if (value) {
      const formattedDate = formatDate(new Date(value))
      setValue('launch_date', formattedDate)
    }
  }

  const handleOpeningDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    // Se houver uma data válida, formate-a antes de definir o valor
    if (value) {
      const formattedDate = formatDate(new Date(value))
      setValue('opening_date', formattedDate)
    }
  }

  // Função para formatar a data como AAAA-MM-DD
  const formatDate = (date: Date): string => {
    const isoDate = date.toISOString().split('T')[0]
    return isoDate
  }

  return (
    <div className='card mb-5 mt-6 mb-xl-10'>
      <div className='card-body pb-0'>
        <h2 className='fw-bolder pb-4 m-0'>Informações Gerais</h2>
        <div className='row'>
          <div className='form-group col-md-12 col-lg-2'>
            <FormInputAndLabel
              label='Nome do condomínio'
              className='form-control form-control-solid'
              placeholder='Nome do condomínio'
              type='text'
              defaultValue={data ? data.name : ''}
              register={{...register('name')}}
              error={errors?.name?.message || ''}
            />
          </div>

          <div className='form-group col-sm-12 col-md-6 col-lg-2'>
            <GetDevelopersChosen
              SetValueSituacao={(value: number) => {
                console.log(value)
                setValue('id_developer', value)
              }}
              valueSituacao={data ? (data.id_developer ? data.id_developer : '') : ''} // name='id_developer'
            />
            {errors && <ErrorMessageComponent message={errors?.id_developer?.message} />}
          </div>

          <div className='form-group col-sm-12 col-md-6 col-lg-2'>
            <label className='col-form-label'>Situação do Empreendimento</label>
            <select
              className='form-select form-select-solid'
              defaultValue={data ? data.status : ''}
              {...register('status')}
            >
              <option value={''}>Selecione</option>
              <option value={'Pronto novo'}>Pronto novo</option>
              <option value={'Em construção'}>Em construção</option>
              <option value={'Breve lançamento'}>Breve lançamento</option>
            </select>
            {errors && <ErrorMessageComponent message={errors?.status?.message} />}
          </div>
          <div className='form-group col-sm-12 col-md-6 col-lg-2'>
            <div className='form-group'>
              <label className='col-form-label'>Data de Lançamento</label>
              <input
                type='date'
                className='form-control form-control-solid'
                onChange={handleLaunchDateChange}
                {...register('launch_date')}
              />
              {errors?.launch_date && (
                <ErrorMessageComponent message={errors?.launch_date?.message} />
              )}
            </div>
          </div>
          <div className='form-group col-sm-12 col-md-6 col-lg-2'>
            <div className='form-group'>
              <label className='col-form-label'>Data de entrega</label>
              <input
                type='date'
                className='form-control form-control-solid'
                onChange={handleOpeningDateChange}
                {...register('opening_date')}
              />
              {errors?.opening_date && (
                <ErrorMessageComponent message={errors?.opening_date?.message} />
              )}
            </div>
          </div>

          <div className='form-group col-sm-12 col-md-6 col-lg-2'>
            <FormInputAndLabel
              type='number'
              label='Apartamentos por Andar'
              className='form-control form-control-solid'
              placeholder='Ex: 0'
              defaultValue={data ? data.apts_per_floor : null}
              register={{...register('apts_per_floor', {valueAsNumber: true})}}
              error={errors ? errors?.apts_per_floor?.message : ''}
            />
          </div>
        </div>
        <div className='row mt-4'>
          <div className='form-group col-sm-12 col-md-6 col-lg-2'>
            <FormInputAndLabel
              type='number'
              label='Número de Torres'
              className='form-control form-control-solid'
              placeholder='Ex: 0'
              defaultValue={data ? data.number_of_towers : ''}
              register={{...register('number_of_towers', {valueAsNumber: true})}}
              error={errors ? errors?.number_of_towers?.message : ''}
            />
          </div>

          <div className='form-group col-sm-12 col-md-6 col-lg-2'>
            <FormInputAndLabel
              type='number'
              label='Número de Andares'
              className='form-control form-control-solid'
              placeholder='Ex: 0'
              defaultValue={data ? data.number_of_floors : ''}
              register={{...register('number_of_floors', {valueAsNumber: true})}}
              error={errors ? errors?.number_of_floors?.message : ''}
            />
          </div>

          <div className='form-group col-sm-12 col-md-6 col-lg-2'>
            <FormInputAndLabel
              type='number'
              label='Total Unidades'
              className='form-control form-control-solid'
              placeholder='Ex: 0'
              defaultValue={data ? data.total_units : ''}
              register={{...register('total_units', {valueAsNumber: true})}}
              error={errors ? errors?.total_units?.message : ''}
            />
          </div>

          <input type='hidden' {...register('latitude')} value={'-145345'} />
          <input type='hidden' {...register('longitude')} value={'+1425345'} />

          <div className='form-group col-sm-12 col-md-6 col-lg-2'>
            <AreaInput
              solid={true}
              label='Área Andar:'
              name='floor_area'
              control={control}
              // {...register('floor_area', { valueAsNumber: true })}

              error={errors?.floor_area?.message}
            />
          </div>
          <div className='form-group col-sm-12 col-md-6 col-lg-2'>
            <AreaInput
              solid={true}
              label='Área total:'
              name='total_area'
              control={control}
              error={errors?.total_area?.message}
            />
          </div>
          <div className='form-group col-sm-12 col-md-6 col-lg-2'>
            <FormInputAndLabel
              type='text'
              label='Qtd. Meses de entrega'
              className='form-control form-control-solid'
              placeholder='Ex: 0'
              defaultValue={data ? data.opening_months : ''}
              register={{...register('opening_months', {valueAsNumber: true})}}
              error={errors ? errors?.opening_months?.message : ''}
            />
          </div>
        </div>

        <div className='row mt-4'>
          <div className='form-group col-sm-12 col-md-6 col-lg-2'>
            <FormInputAndLabel
              type='text'
              label='Número de alvará'
              className='form-control form-control-solid'
              placeholder='Ex: 12345'
              defaultValue={data ? data.notary_registration_number : ''}
              register={{...register('notary_registration_number')}}
              error={errors ? errors?.notary_registration_number?.message : ''}
            />
          </div>

          <div className='form-group col-sm-12 col-md-6 col-lg-2'>
            <label className='col-form-label'>Finalidade</label>
            <select
              className='form-select form-select-solid'
              defaultValue={data ? data.finality : ''}
              {...register('finality')}
            >
              <option value=''>Selecione</option>
              <option value='Residencial'>Residencial</option>
              <option value='Comercial'>Comercial</option>
            </select>
            {errors && <ErrorMessageComponent message={errors?.finality?.message} />}
          </div>

          <div className='form-group col-sm-12 col-md-6 col-lg-2'>
            <FormInputAndLabel
              type='text'
              label='Web Page'
              className='form-control form-control-solid'
              placeholder='Ex: https://www.site.com.br'
              defaultValue={data ? data.web_page : ''}
              register={{...register('webpage')}}
              error={errors ? errors?.webpage?.message : ''}
            />
          </div>

          <div className='form-group col-sm-12 col-md-6 col-lg-2'>
            <label className='col-form-label'>Status da Publicação</label>
            <select
              className='form-select form-select-solid'
              defaultValue={data ? data.id_status : ''}
              {...register('id_status', {valueAsNumber: true})}
            >
              <option value=''>Selecione</option>
              <option value={1}>Publicado</option>
              <option value={2}>Em Edição</option>
              <option value={3}>Pendente Pulicação</option>
              <option value={4}>Decartado</option>
            </select>
            {errors && <ErrorMessageComponent message={errors?.id_status?.message} />}
          </div>

          <div className='form-group col-sm-12 col-md-6 col-lg-2'>
            <label className='col-form-label'>Futuro lançamento</label>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                id='futuroLancamento'
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              {errors && <ErrorMessageComponent message={errors?.future_launch?.message} />}
            </div>
          </div>

          <div className='form-group col-sm-12 col-md-6 col-lg-2'>
            <label className='col-form-label'>MCMV</label>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                id='affordable_program'
                checked={isCheckedMCMV}
                onChange={handleCheckboxMCMVChange}
              />
              {errors && <ErrorMessageComponent message={errors?.affordable_program?.message} />}
            </div>
          </div>
        </div>

        <div className='row mt-4'>
          <div className={`form-group col-md-12 ${data ? '' : 'pb-8'}`}>
            <label className='col-form-label'>Descrição</label>
            <textarea
              className='form-control form-control-solid'
              style={{resize: 'none'}}
              defaultValue={data ? data.description : ''}
              {...register('description')}
            ></textarea>
            {errors && <ErrorMessageComponent message={errors?.description?.message} />}
          </div>
        </div>
      </div>

      {data ? (
        <ul className='nav nav-map-info mt-6' id='tab' role='tablist'>
          <li className='nav-item' role='presentation'>
            <button
              className='nav-link text-active-primary ms-0 me-10 py-1 active'
              id='tab-ab'
              data-bs-toggle='pill'
              data-bs-target='#tab-perfil'
              type='button'
              role='tab'
              aria-controls='tab-perfil'
              aria-selected='false'
            >
              Dados Cadastrais
            </button>
          </li>
          <li className='nav-item' role='presentation'>
            <button
              className='nav-link text-active-primary ms-0 me-10 py-1'
              id='tab-historico-tab'
              data-bs-toggle='pill'
              data-bs-target='#tab-historico'
              type='button'
              role='tab'
              aria-controls='tab-historico'
              aria-selected='false'
            >
              Historico de preços
            </button>
          </li>
          <li className='nav-item' role='presentation'>
            <button
              className='nav-link text-active-primary ms-0 me-10 py-1'
              id='tab-media-tab'
              data-bs-toggle='pill'
              data-bs-target='#tab-media'
              type='button'
              role='tab'
              aria-controls='tab-media'
              aria-selected='false'
            >
              Mídia
            </button>
          </li>
          <li className='nav-item' role='presentation'>
            <button
              className='nav-link text-active-primary ms-0 me-10 py-1'
              id='tab-doc-tab'
              data-bs-toggle='pill'
              data-bs-target='#tab-doc'
              type='button'
              role='tab'
              aria-controls='tab-doc'
              aria-selected='false'
            >
              Documentos
            </button>
          </li>
        </ul>
      ) : (
        <></>
      )}
    </div>
  )
}
