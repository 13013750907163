import React, {useEffect, useRef, useState} from 'react'
import {Autocomplete, GoogleMap, Marker} from '@react-google-maps/api'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {formatAdressSeachGoogleMapa} from '../../explorer/components/common'
import {latLng} from '../../../components/util'
import useMap from './hooks/useMap'
import useAutocomplete from './hooks/useAutocomplete'
import {Endereco, Imoveis, TMarcador} from '../types'
import {useImoveisStore} from '../context/DataContext'
import Isocrona from './Filtro/AreaInfluencia/Isocrona'
import Raio from './Filtro/AreaInfluencia/Raio'
import ZonaDeValor from './Filtro/AreaInfluencia/ZonaDeValor'
import DefinirManualmente from './Filtro/AreaInfluencia/DefinirManualmente'
import PanelAnuncio from './PanelAnuncio'

type Props = {
  height: number
  imoveis: Imoveis[]
}
const centerInit = {
  lat: -23.56834983242366,
  lng: -46.65739491734664,
}
type Showhide = {
  show: boolean
  id: number
}

const MapView: React.FC<Props> = ({height, imoveis}) => {
  const {setEndereco, endereco, filtro} = useImoveisStore()
  const [center, setCenter] = useState<latLng>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [show, setShow] = useState<Showhide>({
    show: false,
    id: null,
  })
  const markerRef = useRef<Marker>(null)
  const {onLoad, onUnmount, map, getGeocoder, handleMarkerClick, isOpen, handleMarkerMOuseOut} =
    useMap()
  const {
    marcador,
    setMarcador,
    onPlaceChanged,
    buscaPosition,
    setBuscaPosition,
    onLoadAutoComplete,
    setMarcadorAddress,
  } = useAutocomplete(map)

  useEffect(() => {
    if (endereco) {
      setCenter({
        lat: endereco.latitude,
        lng: endereco.longitude,
      })
    } else {
      setCenter(centerInit)
    }
  }, [endereco])

  function onchangeAdress(map, enderecoFormatado: Endereco) {
    if (map) {
      setMarcadorAddress(map, enderecoFormatado)
      setEndereco({...enderecoFormatado})
    }
  }
  useEffect(() => {
    if (endereco) {
      setMarcador([
        {
          lat: endereco.latitude,
          lng: endereco.longitude,
          endereco: endereco.endereco_completo,
        },
      ])
    }
  }, [endereco, setMarcador])

  if (!google.maps) {
    return <div>Carregando o mapa.</div>
  }
  return (
    <>
      {/* <button
          onClick={() => {
            onchangeAdress(map, {
              latitude: -23.5986377,
              longitude: -46.6689923,
              endereco_completo: 'R. Diogo Jácome, 818 - Moema, São Paulo - SP, 04512-001, Brasil',
              logradouro: 'Rua Diogo Jácome',
              numero: '818',
              bairro: 'Moema',
              cidade: 'São Paulo',
              estado: 'SP',
              cep: '04512-001',
            })
            setBuscaPosition('')
          }}
        >
          Load Bairros
        </button> */}

      <GoogleMap
        mapContainerStyle={{width: '100%', height: height}}
        center={center}
        zoom={18}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          // mapId: 'a28cbce2ae82f5e3',
          mapTypeControl: true,
          mapTypeControlOptions: {
            mapTypeIds: [
              'roadmap',
              'satellite',
              'hybrid',
              'noturno',
              'comercios_map',
              'onibus_map',
              'ligth',
              'simples',
              // 'a28cbce2ae82f5e3',
            ],
            style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
            position: google.maps.ControlPosition.LEFT_TOP,
          },
        }}
      >
        <div className={buscaPosition}>
          <Autocomplete
            onLoad={onLoadAutoComplete}
            onPlaceChanged={() => {
              onPlaceChanged(map)
            }}
          >
            <input
              type='text'
              placeholder='Digite um endereço...'
              className='form-control'
              style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `48%`,
                height: `32px`,
                padding: `18px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: 'absolute',
                left: '200px',
                top: '11px',
              }}
            />
          </Autocomplete>
        </div>

        {imoveis.length > 0 ? (
          // eslint-disable-next-line array-callback-return
          imoveis?.map((obj: Imoveis, i) => {
            if (obj.active) {
              if (obj.geom) {
                return (
                  <Marker
                    onClick={(e) => {
                      e.domEvent.preventDefault()
                      e.domEvent.stopImmediatePropagation()
                      handleMarkerClick(obj.id)
                    }}
                    onMouseDown={(e) => {
                      handleMarkerMOuseOut(obj.id)
                    }}
                    ref={markerRef}
                    visible={true}
                    key={obj.id}
                    position={{lat: obj.geom.coordinates[1], lng: obj.geom.coordinates[0]}}
                    // label={{
                    //   text: obj.tipo.split('-')[0],
                    //   className: `marker-label`,
                    //   color: '#4d3838',
                    // }}
                    icon={{
                      scaledSize: new google.maps.Size(20, 20),
                      url: obj.active
                        ? toAbsoluteUrl('/media/75210de1-444b-4077-8a30-d4aa401ee39c.png')
                        : toAbsoluteUrl('/media/f81039c7-b68a-462c-b90a-ccbf0a11e4ec.png'),
                      origin: new google.maps.Point(0, 0),
                      anchor: new google.maps.Point(0, 0),
                    }}
                    // animation={google.maps.Animation.DROP}
                  ></Marker>
                )
              }
            }
          })
        ) : (
          <>Carregando...</>
        )}

        {marcador ? (
          marcador.map(function (el: TMarcador, index: number) {
            return (
              <Marker
                key={index}
                position={{lat: el.lat, lng: el.lng}}
                draggable={true}
                icon={{
                  scaledSize: new google.maps.Size(35, 35),
                  url: toAbsoluteUrl('/media/placeholder.png'),
                  origin: new google.maps.Point(0, 0),
                  anchor: new google.maps.Point(25, 25),
                }}
                onDragEnd={async (e) => {
                  let latLng = e.latLng.toJSON()
                  let novoEndereco = await getGeocoder(latLng.lat, latLng.lng)
                  var adr_elements = formatAdressSeachGoogleMapa(novoEndereco.results[0])

                  setEndereco({...endereco, ...adr_elements})
                  setMarcador([
                    ...[
                      {
                        lat: latLng.lat,
                        lng: latLng.lng,
                        endereco: adr_elements.endereco_completo,
                      },
                    ],
                  ])
                }}
              ></Marker>
            )
          })
        ) : (
          <></>
        )}

        {filtro?.areaInfluencia.tipo === 'ISOCRONA' ? (
          <Isocrona
            lat={endereco.latitude}
            lng={endereco.longitude}
            tempo={filtro.areaInfluencia.valor}
            map={map}
          ></Isocrona>
        ) : (
          <></>
        )}
        {filtro?.areaInfluencia.tipo === 'RAIO' ? (
          <Raio
            lat={endereco.latitude}
            lng={endereco.longitude}
            distancia={filtro.areaInfluencia.valor}
            map={map}
          ></Raio>
        ) : (
          <></>
        )}

        {filtro?.areaInfluencia.tipo === 'ZONAVALOR' ? (
          <ZonaDeValor
            map={map}
            lat={endereco.latitude}
            lng={endereco.longitude}
            valor={filtro.areaInfluencia.valor}
          ></ZonaDeValor>
        ) : (
          <></>
        )}

        {filtro?.areaInfluencia.tipo === 'DEFINIRMANUALMENTE' ? (
          <DefinirManualmente map={map}></DefinirManualmente>
        ) : (
          <></>
        )}
      </GoogleMap>
      {isOpen && isOpen.open ? (
        <PanelAnuncio id={isOpen.id} nome={''} startShow={true}></PanelAnuncio>
      ) : (
        <></>
      )}

      {/* 
      <MaisInfoEmpreendimentos
        setShow={setShow}
        show={show}
        height={height}
      ></MaisInfoEmpreendimentos> */}
    </>
  )
}

export default MapView
