import {GoogleMap, useJsApiLoader, Marker, Circle} from '@react-google-maps/api'
import {GoogleMapsOverlay} from '@deck.gl/google-maps'
import {ColorMapboxToKepler, RgbToHex} from '../../../explorer/components/common'
import {useCallback, useRef, useState} from 'react'

// import {LatLngExpression} from 'leaflet'

type PropsContextMenu = {
  center: {
    lat: number
    lng: number
  }
  layer: any[]
  zoom?: number
  json: any
  dataImoveis: any
  style: any
}
export const MapView: React.FC<PropsContextMenu> = ({
  dataImoveis,
  center = {lat: -23.6, lng: -46.6},
  json,
  layer,
  zoom = 17,
  children,
  style,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [map, setMap] = useState(null)
  const MapRef = useRef(null)
  // const position: LatLngExpression = [-23.55052, -46.633308] // São Paulo

  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
  })

  const containerStyle = {
    // width: '400px',
    height: '100vh',
  }

  function clickCircle(index) {
    console.log(index)
  }
  function checkColor(properties): string {
    properties['preco_m2'] = properties.valor / properties.area
    let color = ColorMapboxToKepler(style, properties, 'fill-color')
    if (color) {
      return RgbToHex(color[0], color[1], color[2], color[3])
    } else {
      return '#333'
    }
  }

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])

  const deckOverlay = new GoogleMapsOverlay({
    debug: true,
    style: {zIndex: 30},
    pickingRadius: 10,
    onmousemove: () => {
      console.log('onmouse')
    },
  })

  const onLoad = useCallback(
    (map) => {
      deckOverlay.setMap(map)
      if (deckOverlay) {
        setMap(deckOverlay)
      }

      setMap(deckOverlay)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    null
  )

  return (
    <div className='row'>
      <div className='col-12 col-md-12 col-lg-12'>
        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={{...containerStyle, width: '100%', height: 'calc(60.8vh)'}}
            center={center}
            zoom={zoom}
            onLoad={onLoad}
            onUnmount={onUnmount}
            ref={MapRef}
            // onClick={onclick}
            options={{
              styles: [
                {
                  featureType: 'transit',
                  stylers: [{visibility: 'off'}],
                },
                {
                  featureType: 'poi',
                  stylers: [{visibility: 'off'}],
                },
              ],
            }}
          >
            {dataImoveis ? (
              dataImoveis.map((item, index) => {
                // console.log(item)
                let color = checkColor(item)
                return (
                  <Circle
                    key={index}
                    radius={25}
                    options={{
                      fillColor: color,
                      fillOpacity: 1,
                      strokeColor: '#000000',
                      strokeWeight: 0,
                    }}
                    onClick={() => {
                      clickCircle(index)
                    }}
                    center={{lat: item.geom.coordinates[1], lng: item.geom.coordinates[0]}}
                    // label={{
                    //   text: obj.nome.split('-')[0],
                    //   className: `marker-label`,
                    //   color: '#4d3838',
                    // }}
                    // icon={{
                    //   scaledSize: new google.maps.Size(50, 50),
                    //   url: obj.removed
                    //     ? toAbsoluteUrl('/media/f81039c7-b68a-462c-b90a-ccbf0a11e4ec.png')
                    //     : toAbsoluteUrl('/media/75210de1-444b-4077-8a30-d4aa401ee39c.png'),
                    //   origin: new google.maps.Point(0, 0),
                    //   anchor: new google.maps.Point(25, 50),
                    // }}
                    // animation={google.maps.Animation.DROP}
                  >
                    {/* {isOpen && isOpen.find((item) => item.id === obj.id) && (
      <InfoBox
        options={{
          closeBoxURL: `xx`,
          enableEventPropagation: true,
          maxWidth: 440,
          boxClass: 'infoBox2',
        }}
      >
        <Tootip dataImovel={obj} onClose={handleMarkerHover}></Tootip>
      </InfoBox>
    )} */}
                  </Circle>
                )
              })
            ) : (
              <></>
            )}
            {/* {iscoronas ? (
            iscoronas.map((item, index) => {
              return (
                <Polyline
                  options={{
                    strokeColor: index === 0 ? '#094e08' : '#e10c0c',
                    strokeOpacity: 0,
                    icons: [
                      {
                        icon: lineSymbol,
                        offset: '0',
                        repeat: '10px',
                      },
                    ],
                  }}
                  path={CoordinatesToPath(item.geometry.coordinates[0])}
                  key={index}
                  // options={optionsSelected}
                />
              )
            })
          ) : (
            <></>
          )} */}
            {/* {geometriaSelecionada ? (
            // geometria.map((item, index) => {
            // return (
            <Polyline
              options={{
                strokeColor: '#333',
                strokeOpacity: 0,
                icons: [
                  {
                    icon: lineSymbol,
                    offset: '0',
                    repeat: '2px',
                  },
                ],
              }}
              path={CoordinatesToPath(
                geometria.type === 'MultiPolygon'
                ? geometriaSelecionada['coordinates'][0]
                : typeof geometriaSelecionada.geometry.coordinates[0][0] !== 'undefined' ?geometriaSelecionada.geometry.coordinates[0][0] : geometriaSelecionada.geometry.coordinates[0]
              )}
              key={1}
              // options={optionsSelected}
            />
          ) : (
            // )
            // })
            <></>
          )} */}
            <Marker
              position={center}
              // label={{
              //   text: obj.nome.split('-')[0],
              //   className: `marker-label`,
              //   color: '#4d3838',
              // }}
              // icon={{
              //   scaledSize: new google.maps.Size(50, 50),
              //   url: obj.removed
              //     ? toAbsoluteUrl('/media/f81039c7-b68a-462c-b90a-ccbf0a11e4ec.png')
              //     : toAbsoluteUrl('/media/75210de1-444b-4077-8a30-d4aa401ee39c.png'),
              //   origin: new google.maps.Point(0, 0),
              //   anchor: new google.maps.Point(25, 50),
              // }}
              animation={google.maps.Animation.DROP}
            >
              {/* {isOpen && isOpen.find((item) => item.id === obj.id) && (
                <InfoBox
                  options={{
                    closeBoxURL: `xx`,
                    enableEventPropagation: true,
                    maxWidth: 440,
                    boxClass: 'infoBox2',
                  }}
                >
                  <Tootip dataImovel={obj} onClose={handleMarkerHover}></Tootip>
                </InfoBox>
              )} */}
            </Marker>

            {/* <OverlayView position={center} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
            <div style={divStyle}></div>
          </OverlayView> */}
            <></>
            {children}
          </GoogleMap>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}
