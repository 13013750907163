import {gql} from '@apollo/client'

export const GET_EMPREENDIMENTO_CORRETORES = gql`
  query GetEmpreendimentoCorretores($pagination: Pagination!, $idEmpreendimento: Float!) {
    GetEmpreendimentoCorretores(pagination: $pagination, id_empreendimento: $idEmpreendimento) {
      id
      nome
      telefone
      empresa
      email
      data_cadastro
      situacao
    }
  }
`
