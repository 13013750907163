import React, {useEffect, useState} from 'react'
import Select, {ActionMeta} from 'react-select'
import {CSSProperties} from 'styled-components'

type Props = {
  onChange?: any
  itensSelected: string[] | null
}
export interface Options {
  readonly value: string
  readonly label: string
  readonly color?: string
  readonly isFixed?: boolean
  readonly isDisabled?: boolean
}

export interface GroupedOption {
  readonly label: string
  readonly options: readonly Options[]
}

export const SelectTipologia: React.FC<Props> = ({onChange, itensSelected}) => {
  const residencialOptions: readonly Options[] = [
    {label: 'Apartamento', value: 'apartamento'},
    {label: 'Studio', value: 'studio'},
    {label: 'Garden', value: 'garden'},
    {label: 'Duplex', value: 'duplex'},
    {label: 'Triplex', value: 'triplex'},
    {label: 'Loft', value: 'loft'},
    {label: 'Cobertura', value: 'cobertura'},
    {label: 'Casa', value: 'casa'},
    {label: 'Flat', value: 'flat_residencial'},
    {label: 'Kitnet', value: 'kitnet'},
    // {label: 'Casa em Condomínio', value: 'Casa em Condomínio'},
    {label: 'Terreno', value: 'terreno'},
  ]

  const comercialOptions: readonly Options[] = [
    {label: 'Galpão/Pavilhão', value: 'galpao'},
    {label: 'Loja', value: 'loja'},
    {label: 'Consultório', value: 'consultorio'},
    {label: 'Imóvel Comercial', value: 'imovel-comercial'},
    {label: 'Ponto Comercial', value: 'ponto-comercial'},
    {label: 'Sala', value: 'sala-comercial'},
    // {label: 'Sala com Área Ext.', value: 'Sala com Área Ext.'},
    {label: 'Terreno/Lote Comercial', value: 'lote-terreno'},
  ]

  const groupedOptions: readonly GroupedOption[] = [
    {
      label: 'Residencial',
      options: residencialOptions,
    },
    {
      label: 'Comercial',
      options: comercialOptions,
    },
  ]
  const [selected, setSelected] = useState<Options[]>([])

  useEffect(() => {
    if (itensSelected) {
      const residencialSelecionados = residencialOptions.filter((itens) => {
        return itensSelected.indexOf(itens.value) !== -1 ? true : false
      })

      const comercialSelecionados = comercialOptions.filter((itens) => {
        return itensSelected.indexOf(itens.value) !== -1 ? true : false
      })

      setSelected([...residencialSelecionados, ...comercialSelecionados])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itensSelected])

  useEffect(() => {
    if (selected) {
      let itens = selected.map((item) => {
        return item.value
      })
      onChange(itens)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 13,
    fontWeight: 'bold',
  }

  const groupBadgeStyles: CSSProperties = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 13,
    fontWeight: 'bold',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  }

  const formatGroupLabel = (data: GroupedOption) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  )

  return (
    <>
      <Select
        options={groupedOptions}
        isMulti
        formatGroupLabel={formatGroupLabel}
        onChange={(option: Options[], actionMeta: ActionMeta<Options>) => {
          setSelected(option)
        }}
        value={selected}
      />
    </>
  )
}
