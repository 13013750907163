import {useContext, useMemo, useState} from 'react'
import {DataContext} from '../../context/DataContext'
import {FilterContext} from '../../context/FilterContext'
import {BarChartPorVagaQtde} from '../Charts/BarChartPorVagaQtde'
import {BarChartPorVagaQtde2} from '../Charts/BarChartPorVagaQtde2'

type Props = {
  label?: string
  itens: any[]
}

export default function ResumoVendasPorVaga({label, itens}: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {data} = useContext(DataContext)
  const {filter} = useContext(FilterContext)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedItem, setSelectedItem] = useState(null)
  let {chartData, groups} = useMemo(() => {
    let ticketMedio = Array(9).fill([], 0, 9)
    let totalUnidades = Array(9).fill(0, 0, 9)
    let chartData = []
    let groups = []

    if (data && filter !== null) {
      // eslint-disable-next-line array-callback-return
      data.map((item) => {
        // eslint-disable-next-line array-callback-return
        item.tipologias.map((itemTipologias) => {
          if (itemTipologias.computed) {
            if (itemTipologias.bedrooms < 10) {
              if (typeof ticketMedio[itemTipologias.bedrooms] === 'undefined') {
                ticketMedio = []
              }

              if (
                typeof ticketMedio[itemTipologias.bedrooms][itemTipologias.parking] === 'undefined'
              ) {
                ticketMedio[itemTipologias.bedrooms] = Array(9).fill(0, 0, 9)
              }

              if (
                typeof totalUnidades[itemTipologias.bedrooms][itemTipologias.parking] ===
                'undefined'
              ) {
                totalUnidades[itemTipologias.bedrooms] = Array(9).fill(0, 0, 9)
              }
              const vendas = itemTipologias.total_units - itemTipologias.stock
              totalUnidades[itemTipologias.bedrooms][itemTipologias.parking] +=
                itemTipologias.total_units
              ticketMedio[itemTipologias.bedrooms][itemTipologias.parking] += vendas
              // item.valor &&
              // Number(item.valor) / Number(item.area) / totalQuartos[itemTipologias.bedrooms][itemTipologias.parking]
            }
          }
        })
      })

      chartData = [
        {
          name: 'Vendas',
          data: [],
        },
        {
          name: 'Total Unidades',
          data: [],
        },
      ]
      // eslint-disable-next-line array-callback-return
      ticketMedio.map((item, index) => {
        if (item.length > 0) {
          let count = 0
          // eslint-disable-next-line array-callback-return
          item.map((valor, indexVagas) => {
            if (valor) {
              chartData[0].data.push({
                x: '' + indexVagas, //Vagas
                y: (valor / totalUnidades[index][indexVagas]) * 100,
                title: (valor / totalUnidades[index][indexVagas]) * 100 + '%',
                dormitorios: index + '\r\n Dorm.',
              })
              chartData[1].data.push({
                x: '' + indexVagas, //Vagas
                y: 100,
                title: totalUnidades[index][indexVagas] + '\r\n Uni.',
                dormitorios: index + '\r\n Dorm.',
              })
              count++
            }
          })
          groups.push({title: index, cols: count})
          // groups.push({title: index, cols: count})
        }
      })

      chartData = ordenarPorVagas(chartData)
    }
    return {chartData, groups}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itens, filter])
  function ordenarPorVagas(array) {
    return array.sort((a, b) => a.vagas - b.vagas)
  }

  if (!itens) {
    return <>Carregando..</>
  }
  //  ;;;;; Analise Temporal
  return (
    <BarChartPorVagaQtde2
      className={'primary'}
      title={'Oferta total por Dorm-Vaga x Vendas'}
      subtitle={''}
      data={chartData}
      groups={groups}
      onChange={(id: number) => {
        setSelectedItem(id)
      }}
      legenda={['Total Unidades']}
    >
      <table className='table  table-row-dashed table-row-gray-300 table-hover'>
        <thead>
          <tr className='text-dark fw-bold'>
            {/* {chartDataVSOMetragemTable && chartDataVSOMetragemTable.length > 0
              ? Object.entries(chartDataVSOMetragemTable[0]).map((item, index) => {
                  return item[0] !== 'id' ? (
                    <th key={index} className='text-center'>
                      {Dicionario.translate(item[0])}
                    </th>
                  ) : null
                })
              : null} */}
          </tr>
        </thead>
        <tbody>
          {/* {chartDataVSOMetragemTable
            ? chartDataVSOMetragemTable.map((item, index) => {
                return (
                  <tr key={index} className={item.id === selectedItem ? 'select-data-table' : ''}>
                    <td className='text-center'>{item.preco}</td>
                    <td className='text-center'>
                      {formatNumber(item.ticketMedio, 'currency_inteiro')}
                    </td>
                    <td className='text-center'>
                      {formatNumber(item.precoMedioM2, 'currency_inteiro')}
                    </td>
                    <td className='text-center'>{item.totalUnidades}</td>
                  </tr>
                )
              })
            : null} */}
        </tbody>
      </table>
    </BarChartPorVagaQtde2>
  )
}
