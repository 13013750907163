import {GoogleMap, Marker, InfoBox} from '@react-google-maps/api'
import {useContext, useState} from 'react'
import {DataContext} from '../../context/DataContext'
import {TIsOpen} from '../Types'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import Tootip from '../Tootip'

export function PerformanceBrevesLancamentos() {
  const [isOpen, setIsOpen] = useState<TIsOpen>([])

  const {data} = useContext(DataContext)

  const centerInit = {
    lat: -23.56834983242366,
    lng: -46.65739491734664,
  }

  // const { isLoaded } = useJsApiLoader({
  //   id: 'google-map-script',
  //   googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
  // })
  function handleMarkerHover(id: number) {
    if (isOpen.length > 0) {
      let existItem = isOpen.findIndex((item) => item.id === id)
      if (existItem !== -1) {
        isOpen.splice(existItem, 1)
      } else {
        isOpen.push({
          id: id,
          open: true,
        })
      }
      setIsOpen([...isOpen])
    } else {
      isOpen.push({
        id: id,
        open: true,
      })
      setIsOpen([...isOpen])
    }
  }

  return (
    <>
      <div className=' d-flex flex-column justify-content-start p-10' style={{height: '92.1vh'}}>
        <div className='header-report'>
          <h1 className='fw-bolder'>Resumo de Breves Lançamentos</h1>
          <span>PERFORMANCE 2020 A 2025</span>
        </div>
        <div className='content pt-15'>
          <div className='row g-5 g-xl-8 mb-10'>
            <div style={{height: '400px'}}>
              <div className='card bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance'>
                <GoogleMap
                  mapContainerStyle={{width: '100%', height: '400px'}}
                  center={centerInit}
                  zoom={18}
                >
                  {data ? (
                    // eslint-disable-next-line array-callback-return
                    data.map((obj, index) => {
                      if (obj.breve_lancamento === '1') {
                        return (
                          <Marker
                            onClick={() => {
                              // handleMarkerHover(obj.id)
                            }}
                            onMouseOver={() => {
                              console.log('mouse')
                            }}
                            // ref={markerRef}
                            key={index}
                            position={{lat: obj.geom.coordinates[1], lng: obj.geom.coordinates[0]}}
                            label={{
                              // text: obj.name,
                              text: obj.nome.split('-')[0],
                              // .map((name: string) => name[0])
                              // .join('')
                              // .toUpperCase(),
                              className: `marker-label`,
                              color: '#4d3838',
                            }}
                            icon={{
                              scaledSize: new google.maps.Size(50, 50),
                              url: obj.removed
                                ? toAbsoluteUrl('/media/f81039c7-b68a-462c-b90a-ccbf0a11e4ec.png')
                                : toAbsoluteUrl('/media/75210de1-444b-4077-8a30-d4aa401ee39c.png'),
                              origin: new google.maps.Point(0, 0),
                              anchor: new google.maps.Point(25, 50),
                            }}
                            animation={google.maps.Animation.DROP}
                            // draggable={true}
                          >
                            {/* {isOpen && isOpen.find((item) => item.id === obj.id) && (
                        <InfoBox
                          // onCloseClick={onToggleOpen}
                          options={{closeBoxURL: ``, enableEventPropagation: false}}
                        >
                          <Tootip data={obj}></Tootip>
                        </InfoBox>
                      )} */}

                            {isOpen && isOpen.find((item) => item.id === obj.id) && (
                              <InfoBox
                                // onCloseClick={onToggleOpen}
                                options={{
                                  closeBoxURL: `xx`,
                                  enableEventPropagation: true,
                                  maxWidth: 440,
                                  boxClass: 'infoBox2',
                                }}
                              >
                                <Tootip
                                  dataImovel={obj}
                                  onClose={handleMarkerHover}
                                  showInfo={(item) => {
                                    console.log(item)
                                  }}
                                ></Tootip>
                              </InfoBox>
                            )}
                          </Marker>
                        )
                      }
                    })
                  ) : (
                    <></>
                  )}
                </GoogleMap>
              </div>
            </div>
            <div className='mt-0'>
              <table className='table table-rounded table-row-bordered border gy-2 gs-3'>
                <thead>
                  <tr className='fw-bolder fs-6  mt-3'>
                    <th>Empreendimento</th>
                    <th>Endereço</th>

                    <th>Incorporadora</th>
                    <th>Dorms.</th>
                    <th>Área Útil</th>
                    <th>Vagas</th>
                    <th>Unidades</th>
                    <th>Preços do m²</th>
                  </tr>
                </thead>
                <tbody>
                  {data ? (
                    // eslint-disable-next-line array-callback-return
                    data.map((obj, index) => {
                      if (obj.situacao === 'Lamçamento') {
                        return (
                          <tr>
                            <td>{obj.nome}</td>
                            <td>{obj.endereco}</td>
                            <td>-</td>
                            <td>{obj.nome_incorporador}</td>
                            <td>{obj.endereco}</td>
                            <td>135m²</td>
                            <td>3</td>
                            <td>92</td>
                            <td>R$ 12.500,00</td>
                          </tr>
                        )
                      }
                    })
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
