import {useEffect, useRef, useState} from 'react'
import {Button, FormControl, Modal} from 'react-bootstrap'
import Swal from 'sweetalert2'
import {GetEmpreendimentoSituacaoChosen} from '../../../../../../components/UI/Chosens/GetEmpreedimentoSituacao'
import {useExplorerComposicao} from '../../../../../../../hooks/useExplorerComposicao'
import {useMutation} from '@apollo/client'
import {
  CREATE_EMPREENDIMENTOS_IMOVEIS,
  GET_EMPREENDIMENTOS,
  GET_IMOVEIS,
  QueryGetEmpreendimentoMesmaQuadra,
  TypesSetEmpreendimentosImovelResponse,
} from '../../../../../../graphql'
import {Composicao as ComposicaoClass} from '../../../Map/ComponentsControl/ComposicaoControl/composicao'
import {errorHandler} from '../../../../../../graphql/errorHandler'
import {Explorer} from '../../../Map/Explorer'
import {GetCorretoresChosen} from '../../../../../../components/UI/Chosens/GetCorretoresChosen'
import secureLocalStorage from 'react-secure-storage'

type Props = {
  showInt?: boolean
  setShowModal: any
  layerCurrrent: string
}

export const ComposicaoModalSave: React.FC<Props> = ({showInt, setShowModal, layerCurrrent}) => {
  const {composicao} = useExplorerComposicao()
  const [show, setShow] = useState<boolean>(false)
  const [situacao, setSituacao] = useState()
  const [corretor, setCorretor] = useState()
  const inputRef = useRef<HTMLInputElement>()
  const corretor_obrigatorio = secureLocalStorage.getItem('corretor_obrigatorio')

  const [createEmpreendimento] = useMutation<TypesSetEmpreendimentosImovelResponse>(
    CREATE_EMPREENDIMENTOS_IMOVEIS,
    {refetchQueries: [GET_EMPREENDIMENTOS, GET_IMOVEIS]}
  )

  const {data, loading, error} = QueryGetEmpreendimentoMesmaQuadra({
    variables: {
      setorquadra:
        composicao && composicao.length > 0
          ? (composicao[0].setor ? composicao[0].setor : '') +
            (composicao[0].quadra ? composicao[0].quadra : '')
          : undefined,
    },
  })
  useEffect(() => {
    setShow(showInt)
  }, [showInt])

  useEffect(() => {}, [])

  function dismiss() {
    setShow(false)
    setShowModal(false)
  }

  async function salvar() {
    let id_corretor = undefined

    if (!corretor && corretor_obrigatorio) {
      Swal.fire('Campo obrigatório!', 'Informe o corretor!')
      return false
    } else {
      id_corretor = corretor
    }

    if (!inputRef.current.value) {
      Swal.fire('Campo obrigatório!', 'Informe o nome do empreendimento!')
      return false
    }

    if (!situacao) {
      Swal.fire('Campo obrigatório!', 'Informe a situação inicial do empreendimento!')
      return false
    }

    if (inputRef.current.value && situacao) {
      let input = inputRef.current.value
      Swal.fire('Enviando dados...')

      let area_total_privativa = 0
      let preco_medio_m2 = 0
      let area_total_terreno = 0
      let geom = null
      let terrenos = composicao.map(function (comp) {
        let ponto = null
        if (typeof comp.geom_closest_point == 'object') {
          ponto = comp.geom_closest_point.geometry
        } else {
          if (typeof comp.geom_closest_point === 'undefined') {
            ponto = {
              type: 'Point',
              coordinates: [comp.longitude, comp.latitude],
            }
          } else {
            ponto = JSON.parse(comp.geom_closest_point)
          }
        }

        if (typeof comp.geom == 'object') {
          geom = JSON.stringify(comp.geom.geometry)
        } else {
          geom = comp.geom
        }
        area_total_privativa = area_total_privativa + Number(comp.area_lote)
        preco_medio_m2 = Number(comp.valor_do_m2_do_terreno)
        area_total_terreno = area_total_terreno + Number(comp.area_lote)

        return {
          numero: String(comp.numero),
          nome: comp.logradouro + ',' + comp.numero,
          endereco: comp.logradouro,
          cidade: comp.cidade,
          estado: comp.estado,
          bairro: comp.bairro,
          id_cidade: Number(comp.id_cidade),
          id_estado: Number(comp.id_estado),
          terrain_type: 'IMOVEL',
          postgis_municipio_id: Number(comp.id_cidade),
          complemento: comp.complemento,
          cep: comp.cep,
          area_iptu: Number(comp.area_lote),
          area_real: Number(comp.area_lote),
          numero_contribuinte: comp.numero_contribuinte,
          latitude: String(ponto.coordinates[1]),
          longitude: String(ponto.coordinates[0]),
          setor_quadra: (comp.setor ? comp.setor : '') + (comp.quadra ? comp.quadra : ''),
          uso_atual_terreno: comp.uso_atual,
          codlog: comp.codlog,
          terreno_esquina: comp.tipo_de_terreno,
          numero_lote: comp.lote,
          lote: geom,
          testada: Number(comp.testada),
          preco_m2: Number(comp.valor_do_m2_do_terreno),
          // cod_condomio: comp.cd_condomi,
          id_situacao: 1,
        }
      })
      const nome = input
      try {
        await createEmpreendimento({
          variables: {
            empreendimentos: {
              nome: nome,
              endereco: nome,
              area_total_privativa: area_total_privativa,
              area_total_terreno: area_total_terreno,
              preco_medio_m2: preco_medio_m2,
              situacao_id: situacao,
              bloqueado: data.GetEmpreendimentosMesmaQuadra?.length > 0 ? 2 : undefined,
              empreendimento_terreno: {
                terreno: terrenos,
              },
            },
            idCorretor: corretor,
          },
        }).then((data) => {
          if (data.errors) {
            Swal.fire('Atenção!', errorHandler(data.errors))
          } else {
            Swal.fire(
              '',
              `Empreendimento cadastrado com sucesso.<br>
                      <br><a href="/empreendimentos/details/${data.data.SetEmpreendimentosImovel.id}#composicao-terrenos" class="btn " target="_blank">Abrir Empreendimento</a>`,
              'success'
            )

            ComposicaoClass.clear()
            Explorer.clearClickPolygon()
            Explorer.removeLayer(layerCurrrent)
            document.getElementById('offcanvas-direita').classList.remove('show')
            document.getElementById('btn-compor-area').classList.remove('active')

            // eslint-disable-next-line array-callback-return
            composicao.map(function (comp) {
              if (comp.polygon) {
                comp.polygon.setMap(null)
              }
            })
          }
          dismiss()
        })
      } catch (e) {
        alert('Ocorreu um erro ao cadastro o empreendimento.')
        console.log(e)
      }
    }
  }

  let empreendimentosMesmaQuadraOutroUsuario = new Set()
  if (data) {
    if (data.GetEmpreendimentosMesmaQuadra?.length > 0) {
      // eslint-disable-next-line array-callback-return
      data.GetEmpreendimentosMesmaQuadra.map((item) => {
        empreendimentosMesmaQuadraOutroUsuario.add(item.users.name)
      })
    }
  }

  return (
    <>
      <Modal animation={false} show={show} onHide={dismiss}>
        <Modal.Header>
          <Modal.Title>Salvar composição</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data && data.GetEmpreendimentosMesmaQuadra?.length > 0 ? (
            <div className='alert alert-warning'>
              Atenção! Existem empreendimentos nessa quadra em nome de :
              {Array.from(empreendimentosMesmaQuadraOutroUsuario).map((item, index) => {
                return <p key={index}> {item}</p>
              })}
            </div>
          ) : (
            <></>
          )}

          <label>Nome do empreendimento</label>
          <FormControl type='text' ref={inputRef} />
          <GetEmpreendimentoSituacaoChosen
            SetValueSituacao={(value) => {
              setSituacao(value)
            }}
            valueSituacao={situacao || null}
          ></GetEmpreendimentoSituacaoChosen>
          {corretor_obrigatorio ? (
            <GetCorretoresChosen
              SetValueCorretor={(value) => {
                setCorretor(value)
              }}
              valueCorretor={setCorretor || ''}
            ></GetCorretoresChosen>
          ) : (
            <></>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={dismiss} data-dismiss='modal' className='btn btn-secondary'>
            Cancel
          </Button>
          <Button className='button-l' onClick={salvar}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
