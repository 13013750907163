import {GoogleMapProps} from '@react-google-maps/api'
import {Meassure} from './Measure'

export class DrawFactory {
  static Draw(type: string, map: GoogleMapProps) {
    switch (type) {
      case 'measure':
        return new Meassure(map)
    }
  }
}
