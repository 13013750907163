import {z} from 'zod'

export const setAiChatHistoricoFormSchema = z.object({
  id: z.string(),
  nome: z.string().nonempty('O nome do corretor é obrigatório!'),
  email: z.string().nonempty('Email do corretor é obrigatório!'),
  telefone: z.string(),
  empresa: z.string(),
})

export type setAiChatHistoricoFormData = z.infer<typeof setAiChatHistoricoFormSchema>

export const putAiChatHistoricoFormSchema = z.object({
  id: z.string(),
  nome: z.string().nonempty('O nome do corretor é obrigatório!'),
  email: z.string().nonempty('Email do corretor é obrigatório!'),
  telefone: z.string(),
  empresa: z.string(),
})

export type putAiChatHistoricoFormData = z.infer<typeof putAiChatHistoricoFormSchema>
