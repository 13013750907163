import {useContext, useMemo, useState} from 'react'
import {DataContext} from '../../../context/DataContext'
import {FilterContext} from '../../../context/FilterContext'
import {BarChart} from './BarChart'

type Props = {
  label?: string
  itens: any[]
}

export default function OfertasUnidades({label, itens}: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {data} = useContext(DataContext)
  const {filter} = useContext(FilterContext)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedItem, setSelectedItem] = useState(null)
  let {chartData} = useMemo(() => {
    let chartData = []

    const totalUnidades = new Map()

    if (data && filter !== null) {
      // eslint-disable-next-line array-callback-return
      data.map((item) => {
        // eslint-disable-next-line array-callback-return
        item.tipologias.map((itemTipologias) => {
          if (itemTipologias.computed) {
            let totalunidaesAcumulador = totalUnidades.get(item.ano_lancamento) || 0
            totalUnidades.set(
              item.ano_lancamento,
              totalunidaesAcumulador + itemTipologias.total_units
            )
          }
        })
      })

      chartData = [
        {
          name: 'Total Unidades',
          data: [],
        },
      ]
      var totalUnidades2 = new Map([...totalUnidades.entries()].sort())
      // eslint-disable-next-line array-callback-return
      totalUnidades2.forEach((valor, ano) => {
        if (valor) {
          chartData[0].data.push({
            x: '' + ano, //Vagas
            y: valor,
            title: valor,
            ano: ano,
            total_unidades: totalUnidades.get(ano),
          })
        }
      })
      chartData = ordenarPorVagas(chartData)
    }
    return {chartData}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itens, filter])
  function ordenarPorVagas(array) {
    return array.sort((a, b) => a.vagas - b.vagas)
  }

  if (!itens) {
    return <>Carregando..</>
  }
  //  ;;;;; Analise Temporal
  return (
    <BarChart
      className={'primary'}
      title={'Oferta de unidades por ano de lançamento'}
      subtitle={''}
      data={chartData}
      // groups={groups}
      onChange={(id: number) => {
        setSelectedItem(id)
      }}
      legenda={['Total Unidades']}
    >
      <table className='table  table-row-dashed table-row-gray-300 table-hover'>
        <thead>
          <tr className='text-dark fw-bold'>
            {/* {chartDataVSOMetragemTable && chartDataVSOMetragemTable.length > 0
              ? Object.entries(chartDataVSOMetragemTable[0]).map((item, index) => {
                  return item[0] !== 'id' ? (
                    <th key={index} className='text-center'>
                      {Dicionario.translate(item[0])}
                    </th>
                  ) : null
                })
              : null} */}
          </tr>
        </thead>
        <tbody>
          {/* {chartDataVSOMetragemTable
            ? chartDataVSOMetragemTable.map((item, index) => {
                return (
                  <tr key={index} className={item.id === selectedItem ? 'select-data-table' : ''}>
                    <td className='text-center'>{item.preco}</td>
                    <td className='text-center'>
                      {formatNumber(item.ticketMedio, 'currency_inteiro')}
                    </td>
                    <td className='text-center'>
                      {formatNumber(item.precoMedioM2, 'currency_inteiro')}
                    </td>
                    <td className='text-center'>{item.totalUnidades}</td>
                  </tr>
                )
              })
            : null} */}
        </tbody>
      </table>
    </BarChart>
  )
}
