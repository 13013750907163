import {gql} from '@apollo/client'

export const GET_AI_CHAT_HISTORICO = gql`
  query GetAiChatHistorico($pagination: Pagination, $idProjeto: Float) {
    GetAiChatHistorico(pagination: $pagination, id_projeto: $idProjeto) {
      pageInfo {
        currentPage
        totalPages
        totalItems
        hasNextPage
        hasPreviousPage
      }
      result {
        data
        id
        id_referencia
        id_sessao
        id_usuario
        mensagem
        modulo
        qualidade
        status
        tipo_mensagem
      }
    }
  }
`

export const GET_AI_CHAT_HISTORICO_BY_ID = gql`
  query GetAiChatHistoricoById($getAiChatHistoricoByIdId: Float!) {
    GetAiChatHistoricoById(id: $getAiChatHistoricoByIdId) {
      data
      id
      id_referencia
      id_sessao
      id_usuario
      mensagem
      modulo
      qualidade
      status
    }
  }
`
