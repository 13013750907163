import {useContext, useMemo, useState} from 'react'

import {Dicionario} from '../../../../utils/Dicionario'
import {formatNumber} from '../../../../components/util'
import {BarChartPorVaga} from '../Charts/BarChartPorVaga'
import {DataContext} from '../../context/DataContext'
import {FilterContext} from '../../context/FilterContext'

type Props = {
  label?: string
  itens: any[]
}

export default function ResumoMercadoMediaTicketPorVaga({label}: Props) {
  // const {filtro, segmentacao} = useImoveisStore()

  const {data, segmentacao, vsoIdeal, setCacheResult, cacheResult} = useContext(DataContext)
  const {filter} = useContext(FilterContext)

  const [selectedItem, setSelectedItem] = useState(null)
  let {chartData, groups} = useMemo(() => {
    let ticketMedio = Array(9).fill([], 0, 9)
    let ticketMedioValor = Array(9).fill([], 0, 9)
    let ticketMedioQtde = Array(9).fill([], 0, 9)
    let totalQuartos = Array(9).fill(0, 0, 9)
    let chartData = []
    let groups = []

    if (data && filter !== null) {
      // eslint-disable-next-line array-callback-return
      data.map((item) => {
        // eslint-disable-next-line array-callback-return
        item.tipologias.map((itemTipologias) => {
          if (itemTipologias.computed) {
            if (itemTipologias.bedrooms < 10) {
              if (typeof ticketMedio[itemTipologias.bedrooms] === 'undefined') {
                ticketMedio = []
              }

              if (
                typeof ticketMedio[itemTipologias.bedrooms][itemTipologias.parking] === 'undefined'
              ) {
                ticketMedio[itemTipologias.bedrooms] = Array(9).fill(0, 0, 9)
              }

              if (typeof ticketMedioValor[itemTipologias.bedrooms] === 'undefined') {
                ticketMedioValor = []
              }

              if (
                typeof ticketMedioValor[itemTipologias.bedrooms][itemTipologias.parking] ===
                'undefined'
              ) {
                ticketMedioValor[itemTipologias.bedrooms] = Array(9).fill(0, 0, 9)
              }

              if (typeof ticketMedioQtde[itemTipologias.bedrooms] === 'undefined') {
                ticketMedioQtde = []
              }

              if (
                typeof ticketMedioQtde[itemTipologias.bedrooms][itemTipologias.parking] ===
                'undefined'
              ) {
                ticketMedioQtde[itemTipologias.bedrooms] = Array(9).fill(0, 0, 9)
              }

              if (
                typeof totalQuartos[itemTipologias.bedrooms][itemTipologias.parking] === 'undefined'
              ) {
                totalQuartos[itemTipologias.bedrooms] = Array(9).fill(0, 0, 9)
              }

              totalQuartos[itemTipologias.bedrooms][itemTipologias.parking] =
                totalQuartos[itemTipologias.bedrooms][itemTipologias.parking] + 1

              ticketMedioValor[itemTipologias.bedrooms][itemTipologias.parking] += Number(
                itemTipologias.original_price
              )
              ticketMedioQtde[itemTipologias.bedrooms][itemTipologias.parking] +=
                totalQuartos[itemTipologias.bedrooms][itemTipologias.parking]
            }
          }
        })
      })

      // eslint-disable-next-line array-callback-return
      ticketMedioValor.map((item, index) => {
        if (item.length > 0) {
          let count = 0

          // eslint-disable-next-line array-callback-return
          item.map((valor, indexVagas) => {
            if (valor) {
              chartData.push({
                x: '' + indexVagas, //Vagas
                y: valor / totalQuartos[index][indexVagas],
                vagas: index,
              })

              count++
            }
          })

          groups.push({title: index, cols: count})
        }
      })
      chartData = ordenarPorVagas(chartData)
      console.log('chartData-ResumoMercadoMediaTicketPorVaga')
      console.log(chartData)
    }
    return {chartData, groups}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, filter])
  function ordenarPorVagas(array) {
    return array.sort((a, b) => a.parking - b.parking)
  }

  if (!data) {
    return <>Carregando..</>
  }

  return (
    <BarChartPorVaga
      className={'primary'}
      title={'Média do Ticket por Dorm-Vaga'}
      subtitle={''}
      data={chartData}
      groups={groups}
      onChange={(id: number) => {
        setSelectedItem(id)
      }}
      legenda={['Total Unidades']}
    >
      <table className='table  table-row-dashed table-row-gray-300 table-hover'>
        <thead>
          <tr className='text-dark fw-bold'>
            {/* {chartDataVSOMetragemTable && chartDataVSOMetragemTable.length > 0
              ? Object.entries(chartDataVSOMetragemTable[0]).map((item, index) => {
                  return item[0] !== 'id' ? (
                    <th key={index} className='text-center'>
                      {Dicionario.translate(item[0])}
                    </th>
                  ) : null
                })
              : null} */}
          </tr>
        </thead>
        <tbody>
          {/* {chartDataVSOMetragemTable
            ? chartDataVSOMetragemTable.map((item, index) => {
                return (
                  <tr key={index} className={item.id === selectedItem ? 'select-data-table' : ''}>
                    <td className='text-center'>{item.preco}</td>
                    <td className='text-center'>
                      {formatNumber(item.ticketMedio, 'currency_inteiro')}
                    </td>
                    <td className='text-center'>
                      {formatNumber(item.precoMedioM2, 'currency_inteiro')}
                    </td>
                    <td className='text-center'>{item.totalUnidades}</td>
                  </tr>
                )
              })
            : null} */}
        </tbody>
      </table>
    </BarChartPorVaga>
  )
}
