import {CardTables} from '../../components/UI/CardTables'
import LoadingTable from '../../components/UI/CardTables/LoadingTable'
import ErrorTable from '../../components/UI/CardTables/ErrorTable'
import {QueryGetMercadoSecundario} from '../../graphql'
import {useState} from 'react'

import {Buttons} from './venda/Buttons'
import {GetAcessoUsuarioProduto} from '../../utils/PermissoesAcesso'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

type Props = {
  filter: any[]
}

export function TableMercadoSecundarioVenda({filter}: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [paginacao, setPaginacao] = useState({pagina: 0, quantidade: 10})

  let fields = [
    {
      field: ['tipo'],
      operator: 'IN',
      value: 'VENDA',
    },
  ]

  if (filter.length > 0) {
    fields.push(filter[0])
  }

  const {data, error, loading} = QueryGetMercadoSecundario({
    variables: {
      pagination: {
        pagina: paginacao.pagina,
        quantidade: paginacao.quantidade,
      },
      filter: {
        fields,
      },
    },
  })

  const permissao = GetAcessoUsuarioProduto(67)

  return (
    <>
      {!permissao ? (
        <div className='content  d-flex flex-column flex-column-fluid' id='kt_content'>
          <div className='card-form px-4 py-12 mb-6' style={{height: '74vh'}}>
            <div id='kt_content_container' className='container-fluid'>
              <div className='alert alert-warning'>
                <h3 className='pt-2 ps-2'>
                  Você não tem este produto contratado, por favor entre em contato no email:{' '}
                  <a href='mailto:contato@urbit.com.br?subject=Sobre Avaliação de Imóveis&body=Gostaria de informações sobre o produto de avaliação de imóveis.'>
                    contato@urbit.com.br
                  </a>
                </h3>
                {/* <MensagemAlertAcesso mensagem='Somente para assinantes do Produto - Urbit Pricing.'></MensagemAlertAcesso> */}
              </div>
              <div className='d-flex justify-content-center align-content-center'>
                <img src={toAbsoluteUrl('/media/prin-ms-venda.png')} alt='print-im' />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {permissao ? (
        <CardTables
          headerTitle={'Projetos'}
          headerSubtTitle={`
              Listando ${data ? data.GetMercadoSecundario.result.length : 0} projetos registrados.`}
          headerToolbar={
            <>
              <a href='/mercado-secundario/venda/novo' className='btn btn-sm btn-light-primary'>
                Novo Projeto
              </a>
            </>
          }
          footer={<></>}
        >
          <div className='table-responsive  '>
            <table className='table table-striped table-row-dashed  gy-2 gs-6  table-row-bordered   table-hover  table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                  <th className='w-50px'>ID</th>
                  <th className='min-w-150px'>Nome</th>
                  <th className='min-w-100px text-end'>Ações</th>
                </tr>
              </thead>
              <tbody>
                <LoadingTable loading={loading} />
                <ErrorTable error={error} />
                {data ? (
                  data.GetMercadoSecundario.result.length > 0 ? (
                    data.GetMercadoSecundario.result.map((response) => {
                      return (
                        <tr key={response.id}>
                          <td>{response.id}</td>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                  {response.nome}
                                </span>
                              </div>
                            </div>
                          </td>

                          <td>
                            <Buttons id={response.id} />
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={10} className='text-center'>
                        Nenhum registro encontrado
                      </td>
                    </tr>
                  )
                ) : (
                  <></>
                )}
              </tbody>
            </table>
          </div>
        </CardTables>
      ) : (
        <></>
      )}
    </>
  )
}
