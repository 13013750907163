import {useState} from 'react'
import {BiLockOpen} from 'react-icons/bi'
import {FaRegClipboard} from 'react-icons/fa'
import Swal from 'sweetalert2'
import {
  MutationDesbloquearEmpreendimentos,
  MutationDesbloquearExcecaoEmpreendimentos,
  QueryGetConflitoQuadraEmpreendimentos,
} from '../../../graphql'
import {Button, Modal} from 'react-bootstrap'
import {GetEmpreendimentoSituacaoChosen} from '../../../components/UI/Chosens/GetEmpreedimentoSituacao'
import {GetUsuariosEquipesByCargoChosen} from '../../../components/UI/Chosens/GetUsuariosEquipesByCargo'
interface Props {
  id: number
}
export const ModalDesbloquearExcecao: React.FC<Props> = ({id}) => {
  const [show, setShow] = useState(false)
  const [usuario, setUsuario] = useState(false)
  const [situacao, setSituacao] = useState(false)
  const {HandleDesbloquearExcecaoEmpreendimentos} = MutationDesbloquearExcecaoEmpreendimentos()
  // const {data} = QueryGetConflitoQuadraEmpreendimentos({
  //   variables:{}
  // })

  async function desbloquear(id: number) {
    Swal.fire({
      title: 'Deseja desbloqueado esse empreendimento?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Desbloquear',
      showCloseButton: true,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */

      if (result.isConfirmed) {
        console.log(id)
        console.log(Number(usuario))
        console.log(Number(situacao))
        await HandleDesbloquearExcecaoEmpreendimentos({
          variables: {
            desbloquearExcecaoEmpreendimentoId: id,
            usuario: Number(usuario),
            situacao: Number(situacao),
          },
        }).then((retorno) => {
          if (retorno) {
            Swal.fire('Sucesso!', 'Empreendimento desbloqueado.', 'success')
          }
        })
      }
    })
  }

  const handleClose = () => {
    setShow(false)
  }

  return (
    <>
      <button className='btn btn-danger me-2' onClick={() => setShow(true)}>
        <BiLockOpen />
      </button>
      <Modal centered animation show={show} onHide={() => handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title>Desbloquear Empreendimento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <GetUsuariosEquipesByCargoChosen
              SetValue={(value) => {
                setUsuario(value)
                // changeUser(response.id ,Number(value),Number(response.users.id))
                // setValue('situacao_id', value)
              }}
              value={null}
              showLabel={true}
            ></GetUsuariosEquipesByCargoChosen>
          </div>

          <div className='row'>
            <GetEmpreendimentoSituacaoChosen
              SetValueSituacao={(value) => {
                setSituacao(value)
              }}
              valueSituacao={null}
            ></GetEmpreendimentoSituacaoChosen>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' data-dismiss='modal' onClick={handleClose}>
            Fechar
          </Button>

          <Button variant='primary' data-dismiss='modal' onClick={() => desbloquear(Number(id))}>
            Desbloquear
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
