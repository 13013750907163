import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import Swal from 'sweetalert2'
import secureLocalStorage from 'react-secure-storage'
import {FaEye, FaEyeSlash, FaInfoCircle} from 'react-icons/fa'
import {MutationLogin} from '../../../graphql/services/auth'
import {errorHandler} from '../../../graphql/errorHandler'
import {MutationSetLogAcessoRecurso} from '../../../graphql/services/LogAcesso'
import Cookies from 'js-cookie'

export function Login() {
  const {FormLogin, errors, handleSubmit, register, loading, setValue} = MutationLogin()
  const [showPassword, setShowPassword] = useState(false)
  const {FormSetLogAcessoRecurso} = MutationSetLogAcessoRecurso()

  const onSubmit = async (data) => {
    await FormLogin(data)
      .then((result) => {
        if (!result.data) {
          let erroText = errorHandler(result.errors)
          erroText = 'Credenciais inválidas!'
          Swal.fire('Erro ao efutuar o login!', erroText, 'warning')
        } else {
          secureLocalStorage.setItem('usuario_orulo', null)

          let DataLogin = result.data
          FormSetLogAcessoRecurso({
            ferramenta: 'Login',
            funcionalidade: 'Login',
            action: 'click',
            url: '/auth/login',
          })
          if (DataLogin) {
            Cookies.set('login_email', DataLogin.Login.email, {
              expires: 7,
              secure: true,
              sameSite: 'Strict',
            })
            secureLocalStorage.setItem('profile_id', `${DataLogin.Login.profile.id}`)
            secureLocalStorage.setItem('id_user', DataLogin.Login.id)
            localStorage.setItem('token', DataLogin.Login.api_token)
            localStorage.setItem('name', DataLogin.Login.name)
            localStorage.setItem('email', DataLogin.Login.email)
            localStorage.setItem('user_pic', DataLogin.Login.user_pic)
            secureLocalStorage.setItem('tipo_usuario', DataLogin.Login.tipo_usuario)
            localStorage.setItem('area_atuacao', DataLogin.Login.perfil.length > 0 ? 'ok' : '')
            console.log(DataLogin)
            if (DataLogin.Login.empresa) {
              secureLocalStorage.setItem('id_empresa', DataLogin.Login.empresa.id)
              secureLocalStorage.setItem(
                'bloqueio_quadra',
                DataLogin.Login.empresa?.bloqueio_quadra
              )
              secureLocalStorage.setItem(
                'corretor_obrigatorio',
                DataLogin.Login.empresa?.corretor_obrigatorio
              )
              secureLocalStorage.setItem('uuid', DataLogin.Login.empresa?.uuid)
              secureLocalStorage.setItem('usuario_orulo', DataLogin.Login.empresa.usuario_orulo)
              secureLocalStorage.setItem('cor_fonte', DataLogin.Login.empresa.cor_fonte)
              secureLocalStorage.setItem('cor_background', DataLogin.Login.empresa.cor_background)
              secureLocalStorage.setItem('user_pic_thumb', DataLogin.Login.user_pic_thumb)
              secureLocalStorage.setItem('user_logo_empresa', DataLogin.Login.empresa.user_logo_pic)
              secureLocalStorage.setItem('empresa', JSON.stringify(DataLogin.Login.empresa))
              secureLocalStorage.setItem(
                'empresa_usuario',
                JSON.stringify(DataLogin.Login.empresa_usuario)
              )
              secureLocalStorage.setItem(
                'empresa_usuario',
                JSON.stringify(DataLogin.Login.empresa_usuario)
              )
            } else {
              secureLocalStorage.setItem('user_pic_thumb', DataLogin.Login.user_pic_thumb)
              secureLocalStorage.setItem('cor_fonte', DataLogin.Login.cor_fonte)
              secureLocalStorage.setItem('cor_background', DataLogin.Login.cor_background)
              secureLocalStorage.setItem('user_logo_empresa', '')
            }
            secureLocalStorage.setItem('produtos', JSON.stringify(DataLogin.Login.produtos))
            localStorage.setItem('produtos', JSON.stringify(DataLogin.Login.produtos))
            window.location.reload()
          }
        }
      })
      .catch((err) => {
        Swal.fire('Erro!', 'Ocorreu um erro inesperado, tente novamente mais tarde.', 'warning')
      })
  }

  useEffect(() => {
    // Carrega os dados dos cookies ao abrir a página
    const savedEmail = Cookies.get('login_email')
    const savedPassword = Cookies.get('login_password')
    if (savedEmail) setValue('email', savedEmail)
    if (savedPassword) setValue('password', savedPassword)
  }, [setValue])

  return (
    <form className='form w-100' onSubmit={handleSubmit(onSubmit)} id='kt_login_signin_form'>
      <div className='text-center mb-1'>
        <h1 className='text-center text-dark mb-3'>Entrar na Urbit</h1>
        <div className='text-gray-400 fw-bold fs-4'>
          Novo Aqui?{' '}
          <Link to='/auth/registration' className='link-primary fw-bolder'>
            Criar uma conta
          </Link>
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...register('email')}
          className={`form-control form-control-lg form-control-solid ${
            errors.email ? 'is-invalid' : ''
          }`}
          type='email'
          name='email'
          autoComplete='off'
        />
      </div>
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
            <Link
              to='/auth/recovery'
              className='link-primary fs-6 fw-bolder'
              style={{marginLeft: '5px'}}
            >
              Forgot Password ?
            </Link>
          </div>
        </div>
        <div className='input-group mb-4'>
          <input
            placeholder='Password'
            {...register('password')}
            className={`form-control form-control-lg form-control-solid ${
              errors.password ? 'is-invalid' : ''
            }`}
            type={showPassword ? 'text' : 'password'}
            name='password'
            autoComplete='off'
          />
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
          </button>
        </div>
        {errors.email || errors.password ? (
          <div className=' alert alert-danger mt-4'>
            {errors.email && (
              <div className='alert-text font-weight-bold'>
                <FaInfoCircle className='text-danger me-2' />
                {errors.email.message}
              </div>
            )}
            {errors.password && (
              <div className='alert-text font-weight-bold'>
                <FaInfoCircle className='text-danger me-2' />
                {errors.password.message}
              </div>
            )}
          </div>
        ) : (
          ''
        )}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-lg btnSuccess w-100 mt-10'
            disabled={!!errors.email || !!errors.password}
          >
            {loading ? <span className='spinner-border text-info' role='status'></span> : 'Entrar'}
          </button>
        </div>
      </div>
    </form>
  )
}
