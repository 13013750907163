import {createContext, ReactNode, useState} from 'react'
import {Endereco, Feature, FilterProps} from '../components/Types'
import {InteligenciaMercadoJSON} from '../../../graphql/type/inteligencia-mercado'

export type Projeto = {
  id: number
  json: InteligenciaMercadoJSON
  nome: string
  situacao: number
  id_usuario_cadastro: number
  data_cadastro: Date
  id_usuario_alteracao: number
  data_alteracao: Date
  id_usario_exclusao: number
  data_exclusao: Date
}
interface FilterPropsContext {
  setFilter: (filter: FilterProps) => void
  setEndereco: (endereco: Endereco) => void
  setGeometria: (geometria: any) => void
  setFilterData: (filter: FilterProps) => void
  setGeometriaData: (geometria: Feature) => void
  setGeometriaSelecionada: (geometria: Feature) => void
  setDadosProjetoSalvo: (projeto: Projeto) => void
  filter: FilterProps
  endereco: Endereco
  geometria?: Feature
  geometriaSelecionada?: Feature

  dadosProjetoSalvo: Projeto
}

export const FilterContext = createContext<FilterPropsContext>({} as FilterPropsContext)

interface FilterProviderProps {
  children: ReactNode
}

export function FilterContextProvider({children}: FilterProviderProps) {
  const [endereco, setEndereco] = useState<Endereco>({
    latitude: null,
    longitude: null,
    endereco_completo: null,
    logradouro: null,
    numero: null,
    bairro: null,
    cidade: null,
    estado: null,
    cep: null,
  })
  const [filter, setFilter] = useState<FilterProps>({
    areaInfluencia: {
      tipo: null,
      valor: null,
    },
    anoLancamento: [2019, 2025],
    areaUtil: [10, 1000],
    precoTotal: [100000, 30000000],
    precoM2: [1000, 60000],
    percentualVendido: [0, 100],
    estagioObra: null,
    tipologia: ['Apartamento', 'Studio'],
    incorporadora: null,
    segmentacaoArea: null,
    segmentacaoPreco: null,
    dormitorios: null,
    mcmv: null,
  })
  const [geometria, setGeometria] = useState<Feature>(null)
  const [dadosProjetoSalvo, setDadosProjetoSalvo] = useState<Projeto>(null)

  const [geometriaSelecionada, setGeometriaSelecionada] = useState<Feature>(null)

  function setFilterData(filtro) {
    return setFilter({...filtro})
  }
  function setGeometriaData(feature: Feature) {
    return setGeometria(feature)
  }

  return (
    <FilterContext.Provider
      value={{
        filter,
        setFilterData,
        setFilter,
        endereco,
        setEndereco,
        geometria,
        setGeometria,
        setGeometriaData,
        geometriaSelecionada,
        setGeometriaSelecionada,
        setDadosProjetoSalvo,
        dadosProjetoSalvo,
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}
