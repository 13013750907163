import {useEffect, useState} from 'react'
import {useLocation} from 'react-router'
import {DrawerComponent} from '../../../_metronic/assets/ts/components'
import '../global.css'
import {AsideDefault} from '../../../_metronic/layout/components/aside/AsideDefault'
import {TabsEmpreendimentoContainer} from './components'
// import './partials/partials.css'
import {HeaderWrapperFluid} from '../../../_metronic/layout/components/header/HeaderWrapperFluid'
import {
  BiBuildingHouse,
  BiCart,
  BiChat,
  BiFile,
  BiIdCard,
  BiLayerPlus,
  BiPurchaseTagAlt,
} from 'react-icons/bi'
import {
  MutationDesbloquearEmpreendimentos,
  MutationPutEmpreendimento,
  QueryGetEmpreendimentoById,
} from '../../graphql'
import {Link, useParams} from 'react-router-dom'
import {Loader} from '../../components/UI/Loader/Loader'
import {ImprimirModal} from './components/Modals/imprimir'
import {MeusImoveisModal} from './components/Modals/meus-imoveis'
import {BiCompass, BiPlus} from 'react-icons/bi'
import {useHistory} from 'react-router-dom'
import {encodeUrl} from '../../components/util'
import {MutationSetLogAcessoRecurso} from '../../graphql/services/LogAcesso'
import {ErrorMessageComponent} from '../../backoffice/components/UI/ErrorMessage'
import {isDiretor} from '../../utils/PermissoesAcesso'
import Swal from 'sweetalert2'

export function DetailsEmpreendimentos() {
  const {id} = useParams<{id: string}>()
  const location = useLocation()

  const [showCreate, setShowCreate] = useState(false)
  const [showMeusImoveis, setShowMeusImoveis] = useState(false)
  const [idModal, setIdModal] = useState(null)
  const [idMeuImovel, setIdMeuImovel] = useState({})
  let history = useHistory()
  const {FormSetLogAcessoRecurso} = MutationSetLogAcessoRecurso()

  const {data, error} = QueryGetEmpreendimentoById({variables: {idEmpreendimento: parseInt(id)}})
  const {HandleDesbloquearEmpreendimentos} = MutationDesbloquearEmpreendimentos()
  const [parametroDaURL, setParametroDaURL] = useState(null)

  useEffect(() => {
    const obterParametroHash = () => {
      const url = window.location.href
      const indiceHash = url.indexOf('#')

      if (indiceHash !== -1) {
        const parametroHash = url.substring(indiceHash + 1)
        return parametroHash
      }

      return null
    }

    const parametroAtual = obterParametroHash()

    if (parametroAtual !== parametroDaURL) {
      // Remove a classe 'active' e 'show' de todos os botões e divs
      document.querySelectorAll('.nav-link').forEach((button) => {
        button.classList.remove('active')
      })

      document.querySelectorAll('.tab-pane').forEach((div) => {
        div.classList.remove('active', 'show')
      })

      // Atualiza o estado apenas se o parâmetro for diferente do estado atual
      if (parametroAtual !== parametroDaURL) {
        setParametroDaURL(parametroAtual)
      }
    }
  }, [parametroDaURL])

  // Função para lidar com as mudanças de tab
  const handleTabClick = (parametro: string) => {
    // Atualiza o estado
    setParametroDaURL(parametro)

    // Atualiza a URL usando a API de histórico do navegador
    window.history.pushState(null, '', `#${parametro}`)
  }

  let tags = []
  if (data) {
    if (data.GetEmpreendimento.tag) {
      tags = data.GetEmpreendimento.tag.split(',')
    }
  }
  function CloseModalPrint() {
    setShowCreate(false)
    setIdModal(null)
  }

  function OpenModalPrint(id) {
    setShowCreate(true)
    setIdModal(id)
  }

  function CloseModalMeusImoveis() {
    setShowMeusImoveis(false)
    // setIdModal(null)
  }

  function OpenModalMeusImoveis(response) {
    setShowMeusImoveis(true)
    setIdMeuImovel(response)
  }

  function explorerOpen(idEmp: string) {
    FormSetLogAcessoRecurso({
      ferramenta: 'explorer',
      funcionalidade: 'inicio',
      action: 'list',
      url: `/explorer?eids=${encodeUrl(idEmp)}`,
    })
    history.push('/explorer?eids=' + encodeUrl(idEmp))
  }
  if (error) {
    return error && <ErrorMessageComponent message={error.message} />
  }

  async function desbloquear() {
    Swal.fire({
      title: 'Deseja desbloqueado esse empreendimento?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Desbloquear',
      showCloseButton: true,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        await HandleDesbloquearEmpreendimentos({
          variables: {desbloquearEmpreendimentoId: Number(id)},
        }).then((retorno) => {
          if (retorno) {
            Swal.fire('Sucesso!', 'Empreendimento desbloqueado.', 'success')
          }
        })
      }
    })
  }
  // console.log(errors)

  return (
    <>
      <AsideDefault />
      <HeaderWrapperFluid />
      {showCreate ? (
        <ImprimirModal
          id={data.GetEmpreendimento.id}
          closeModal={CloseModalPrint}
          show={showCreate}
        />
      ) : (
        <></>
      )}
      {showMeusImoveis ? (
        <MeusImoveisModal
          response={idMeuImovel}
          closeModal={CloseModalMeusImoveis}
          show={showMeusImoveis}
        ></MeusImoveisModal>
      ) : (
        ''
      )}
      <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
        <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
          <div className='post d-flex flex-column-fluid' id='kt_post'>
            <div id='kt_content_container' className='container-fluid'>
              <div
                className='py-6  d-flex align-items-center justify-content-between'
                id='kt_header_container'
              >
                <div
                  className='page-title d-flex flex-column align-items-start justify-content-center flex-wrap mt-n5 mt-lg-0 me-lg-2 pb-2 pb-lg-0'
                  data-kt-swapper='true'
                  data-kt-swapper-mode='prepend'
                  data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}"
                >
                  <h1 className='text-dark fw-bold my-0 fs-2'>Informações do Empreendimento</h1>
                  <ul className='breadcrumb fw-semibold fs-base my-1'>
                    <li className='breadcrumb-item text-muted'>
                      <a href='/dashboard' className='text-muted'>
                        Home
                      </a>
                    </li>
                    <li className='breadcrumb-item text-muted'>Dashboards</li>
                    <li className='breadcrumb-item text-dark'>Imóveis</li>
                  </ul>
                </div>
                <div className='d-flex align-items-center ms-3'>
                  <Link
                    onClick={() =>
                      FormSetLogAcessoRecurso({
                        ferramenta: 'empreendimentos',
                        funcionalidade: 'listagem',
                        action: 'list',
                        url: `/empreendimentos`,
                      })
                    }
                    to='/empreendimentos'
                    className='btn btn-secondary me-10'
                  >
                    Voltar
                  </Link>
                  <button
                    className='btn btn-info me-5'
                    onClick={() => {
                      explorerOpen(id)
                    }}
                  >
                    <BiCompass className='fs-2'></BiCompass> Explorer
                  </button>
                  <button
                    className='btn btn-primary'
                    onClick={(e) => {
                      e.preventDefault()
                      OpenModalPrint(null)
                    }}
                  >
                    Imprimir
                  </button>
                </div>
              </div>

              <div className='container-fluid p-0'>
                <div
                  className='d-flex align-items-center flex-lg-row flex-sm-row flex-md-row flex-column rounded'
                  id='topo'
                >
                  {!data ? (
                    <Loader text={'Carregando..'}></Loader>
                  ) : (
                    <>
                      <div className='col-lg-9 col-md-9 col-sm-6'>
                        <h1 className='text-white'>{data ? data.GetEmpreendimento.nome : ''}</h1>
                        <span className='p-1 border-rounded'>
                          {data ? data.GetEmpreendimento.endereco : ''}
                        </span>
                      </div>
                      <div className='col-lg-3 col-md-3 col-sm-6 mt-4'>
                        <h3 className='text-white'>Tags:</h3>
                        {tags.map(function (tag, y) {
                          return (
                            <span className='badge text-bg-info me-2 mb-2' key={y}>
                              {tag}
                            </span>
                          )
                        })}
                      </div>
                    </>
                  )}
                </div>
              </div>
              {data && data.GetEmpreendimento.bloqueado === 1 ? (
                <div className='alert alert-warning mt-4 d-flex justify-content-between align-items-center'>
                  <p>
                    O terreno foi descartado pois já se passaram 30 dias em Prospecção Ativa. Não é
                    possível fazer alterações.
                  </p>
                  {isDiretor() ? (
                    <button className='btn btn-warning' onClick={desbloquear}>
                      Desbloquear
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}
              {data && data.GetEmpreendimento.bloqueado === 2 ? (
                <div className='alert alert-danger mt-4'>
                  Terrenos com sobreposição de quadra pendente de aprovação.
                </div>
              ) : (
                <></>
              )}
              <div id='kt_content' className='row'>
                <div className='col-lg-2 col-md-3 col-xs-12 xs-mt-10 mb-4'>
                  <div className='card rounded'>
                    <h3 className='py-5 px-5'>Informações</h3>
                    <ul
                      className='nav nav-pills d-flex flex-column flex-column-fluid'
                      role='tablist'
                      id='menu-interno'
                    >
                      <li className='nav-item fs-6' role='presentation'>
                        <button
                          className={`nav-link ${
                            parametroDaURL === 'composicao-terrenos' ? 'active' : ''
                          }`}
                          onClick={() => {
                            handleTabClick('composicao-terrenos')
                            FormSetLogAcessoRecurso({
                              ferramenta: 'empreendimentos',
                              funcionalidade: 'composicao-terrenos',
                              action: 'list',
                              url: `/empreendimentos/details/${id}#composicao-terrenos`,
                            })
                          }}
                          id='composicao-terrenos'
                          data-bs-toggle='tab'
                          data-bs-target='#composicao-terrenos'
                          type='button'
                          role='tab'
                          aria-controls='composicao-terrenos'
                          aria-selected='false'
                        >
                          <BiBuildingHouse className='fs-2'></BiBuildingHouse>
                          <span>Composição</span>
                        </button>
                      </li>
                      <li className='nav-item  fs-6' role='presentation'>
                        <button
                          className={`nav-link ${
                            parametroDaURL === 'informacoes-gerais' ? 'active' : ''
                          }`}
                          onClick={() => {
                            handleTabClick('informacoes-gerais')
                            FormSetLogAcessoRecurso({
                              ferramenta: 'empreendimentos',
                              funcionalidade: 'informacoes-gerais',
                              action: 'list',
                              url: `/empreendimentos/details/${id}#informacoes-gerais`,
                            })
                          }}
                          id='informacoes-gerais'
                          data-bs-toggle='tab'
                          data-bs-target='#informacoes-gerais'
                          type='button'
                          role='tab'
                          aria-controls='informacoes-gerais'
                          aria-selected='true'
                        >
                          <BiFile className='fs-2'> </BiFile>
                          <span>Informações Gerais</span>
                        </button>
                      </li>
                      <li className='nav-item fs-6' role='presentation'>
                        <button
                          className={`nav-link ${
                            parametroDaURL === 'ficha-resumo' ? 'active' : ''
                          }`}
                          onClick={() => {
                            handleTabClick('ficha-resumo')
                            FormSetLogAcessoRecurso({
                              ferramenta: 'empreendimentos',
                              funcionalidade: 'ficha-resumo',
                              action: 'list',
                              url: `/empreendimentos/details/${id}#ficha-resumo`,
                            })
                          }}
                          data-bs-toggle='tab'
                          data-bs-target='#ficha-resumo'
                          type='button'
                          role='tab'
                          aria-controls='ficha-resumo'
                          aria-selected='false'
                        >
                          <BiFile className='fs-2'></BiFile>
                          <span>Ficha Resumo</span>
                        </button>
                      </li>
                      <li className='nav-item fs-6' role='presentation'>
                        <button
                          className={`nav-link ${parametroDaURL === 'massa' ? 'active' : ''}`}
                          onClick={() => {
                            handleTabClick('massa')
                            FormSetLogAcessoRecurso({
                              ferramenta: 'empreendimentos',
                              funcionalidade: 'massa',
                              action: 'list',
                              url: `/empreendimentos/details/${id}#massa`,
                            })
                          }}
                          id='massa'
                          data-bs-toggle='tab'
                          data-bs-target='#massa'
                          type='button'
                          role='tab'
                          aria-controls='massa'
                          aria-selected='false'
                        >
                          <BiLayerPlus className='fs-2'></BiLayerPlus>
                          <span>Massa</span>
                        </button>
                      </li>
                      <li className='nav-item fs-6 ' role='presentation'>
                        <button
                          className={`nav-link ${parametroDaURL === 'observacoes' ? 'active' : ''}`}
                          onClick={() => {
                            handleTabClick('observacoes')
                            FormSetLogAcessoRecurso({
                              ferramenta: 'empreendimentos',
                              funcionalidade: 'observacoes',
                              action: 'list',
                              url: `/empreendimentos/details/${id}#observacoes`,
                            })
                          }}
                          id='observacoes'
                          data-bs-toggle='tab'
                          data-bs-target='#observacoes'
                          type='button'
                          role='tab'
                          aria-controls='observacoes'
                          aria-selected='false'
                        >
                          <BiChat className='fs-2'></BiChat>
                          <span>Observações</span>
                        </button>
                      </li>
                      <li className='nav-item fs-6' role='presentation'>
                        <button
                          className={`nav-link ${parametroDaURL === 'corretores' ? 'active' : ''}`}
                          onClick={() => {
                            handleTabClick('corretores')
                            FormSetLogAcessoRecurso({
                              ferramenta: 'empreendimentos',
                              funcionalidade: 'corretores',
                              action: 'list',
                              url: `/empreendimentos/details/${id}#corretores`,
                            })
                          }}
                          id='corretores'
                          data-bs-toggle='tab'
                          data-bs-target='#corretores'
                          type='button'
                          role='tab'
                          aria-controls='corretores'
                          aria-selected='false'
                        >
                          <BiIdCard className='fs-2'></BiIdCard>
                          <span>Corretores</span>
                        </button>
                      </li>
                      <li className='nav-item fs-6' role='presentation'>
                        <button
                          className={`nav-link ${
                            parametroDaURL === 'projetos-associados' ? 'active' : ''
                          }`}
                          onClick={() => {
                            handleTabClick('projetos-associados')
                            FormSetLogAcessoRecurso({
                              ferramenta: 'empreendimentos',
                              funcionalidade: 'projetos-associados',
                              action: 'list',
                              url: `/empreendimentos/details/${id}#projetos-associados`,
                            })
                          }}
                          id='projetos-associados'
                          data-bs-toggle='tab'
                          data-bs-target='#projetos-associados'
                          type='button'
                          role='tab'
                          aria-controls='projetos-associados'
                          aria-selected='false'
                        >
                          <BiIdCard className='fs-2'></BiIdCard>
                          <span>Estudos associados</span>
                        </button>
                      </li>
                      <li className='nav-item fs-5' role='presentation'>
                        <button
                          className={`nav-link ${parametroDaURL === 'avaliacoes' ? 'active' : ''}`}
                          onClick={() => {
                            handleTabClick('avaliacoes')
                            FormSetLogAcessoRecurso({
                              ferramenta: 'empreendimentos',
                              funcionalidade: 'avaliacoes',
                              action: 'list',
                              url: `/empreendimentos/details/${id}#avaliacoes`,
                            })
                          }}
                          id='avaliacoes'
                          data-bs-toggle='tab'
                          data-bs-target='#avaliacoes'
                          type='button'
                          role='tab'
                          aria-controls='avaliacoes'
                          aria-selected='false'
                        >
                          <BiPurchaseTagAlt className='fs-2'></BiPurchaseTagAlt>
                          <span>Avaliações</span>
                        </button>
                      </li>

                      <li className='nav-item fs-6' role='presentation'>
                        <button
                          className={`nav-link ${
                            parametroDaURL === 'painel-mercado' ? 'active' : ''
                          }`}
                          onClick={() => {
                            handleTabClick('painel-mercado')
                            FormSetLogAcessoRecurso({
                              ferramenta: 'empreendimentos',
                              funcionalidade: 'painel-mercado',
                              action: 'list',
                              url: `/empreendimentos/details/${id}#painel-mercado`,
                            })
                          }}
                          id='painel-mercado'
                          data-bs-toggle='tab'
                          data-bs-target='#painel-mercado'
                          type='button'
                          role='tab'
                          aria-controls='painel-mercado'
                          aria-selected='false'
                        >
                          <BiCart className='fs-2'></BiCart>
                          <span>Painel de Mercado</span>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className='col-lg-10 col-md-9 col-xs-12'>
                  <div className='card mb-6 '>
                    <TabsEmpreendimentoContainer
                      parametroDaURL={parametroDaURL}
                      OpenModalMeusImoveis={OpenModalMeusImoveis}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
