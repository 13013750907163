import React, {useEffect, useState} from 'react'
import {BiCompass, BiInfoCircle, BiLinkExternal} from 'react-icons/bi'
import {Link, useHistory} from 'react-router-dom'
import Swal from 'sweetalert2'
import {
  EmpreendimentoClienteResponse,
  EmpreendimentosMesmaQuadraResponse,
  QueryGetEmpreendimentoMesmaQuadra,
  QueryGetEmpreendimentos,
  QueryGetRelatorioEmpreendimentoMesmaQuadra,
  RelatorioEmpreendimentosMesmaQuadraResponse,
} from '../../graphql'
import {encodeUrl} from '../../components/util'
import {Loader} from '../../components/UI/Loader/Loader'
import {Pagination} from 'react-bootstrap'
import {CardTables} from '../../components/UI/CardTables'
import PaginationComponent from '../../components/UI/PaginationComponents'
import {getPermissaoAcao} from '../../utils/PermissoesAcesso'
import LoadingTable from '../../components/UI/CardTables/LoadingTable'
import ErrorTable from '../../components/UI/CardTables/ErrorTable'
import {GetUsuariosEquipesByCargoChosen} from '../../components/UI/Chosens/GetUsuariosEquipesByCargo'

type Props = {
  active: boolean
  filtro: any[]
  objectFilter: {}
}

const TableMesmaQuadra: React.FC<Props> = ({active, filtro, objectFilter}) => {
  let result = null

  if (active === true) {
    result = 4
  } else if (active === false) {
    result = undefined
  }
  type DataRow = {
    id: number
    name: string | null
    price: number | null
    empreendimento_temperatura: any
  }

  const [pages, setPages] = useState(0)
  const [selecionado, setselecionado] = useState([])
  const history = useHistory()
  const [pagiancao, setPaginacao] = useState({pagina: 0, quantidade: 10})
  const [orderBy, setOrderBy] = useState({fields: null})
  const [dataTable, setDataTable] = useState<RelatorioEmpreendimentosMesmaQuadraResponse>()
  const [sortConfig, setSortConfig] = useState<{
    key: keyof DataRow
    direction: 'asc' | 'desc'
  } | null>({key: 'id', direction: 'asc'})

  useEffect(() => {
    setPaginacao({pagina: 0, quantidade: 10})
  }, [filtro])

  // let query = {
  //   variables: {
  //     pagination: {
  //       pagina: pagiancao.pagina,
  //       quantidade: pagiancao.quantidade,
  //     },
  //     filtro: {
  //       fields: filtro,
  //       ...objectFilter,
  //     },
  //     situation: result,
  //     orderBy: {...orderBy},
  //   },
  // }
  const {data, loading, error} = QueryGetRelatorioEmpreendimentoMesmaQuadra()

  useEffect(() => {
    setDataTable(data)
  }, [data])

  function sortDataTemperatura(campo) {
    let newfields = orderBy
    newfields.fields = campo
    setOrderBy({...newfields})
  }

  function explorerOpen() {
    history.push('/explorer?eids=' + encodeUrl(selecionado.join(',')))
  }

  // Tratativa de loading
  if (!data) {
    return (
      <div className='card  px-10 py-6'>
        <div className='border-0'>
          <div className='row'>
            <div className='col-xl-12'>
              <div className='card-xxl-stretch '>
                <div className='card-header border-0    p-0'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>Empreendimentos</span>
                    {/* <span className='text-muted mt-1 fw-bold fs-7'>
                      Até o momento  empreendimentos
                      registrados
                    </span> */}
                  </h3>
                  <div
                    className='card-toolbar all-end'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to add a user'
                  >
                    <button
                      className='btn btn-info me-5'
                      onClick={() => {
                        explorerOpen()
                      }}
                    ></button>
                    {/* <CreateEmpreendimentos /> */}
                  </div>
                  {/* <div className='all-end'></div> */}
                </div>
                {/* end::Header */}
                <div className='separator my-2'></div>
                <Loader text={'caregando...'}></Loader>
              </div>
              <div className='card-footer all-end'>
                {/* <Pagination setPages={setPages} pages={pages} total={100} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const isChecked = (id: number) => {
    return selecionado.includes(id)
  }

  const handleCheckboxAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked
    if (!checked) {
      setselecionado([])
    } else {
      const checkdAllItens = data.GetRelatorioEmpreendimentosMesmaQuadra.map((item) => {
        return item.id
      })
      setselecionado(checkdAllItens)
    }
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const checked = event.target.checked
    if (checked) {
      setselecionado((prevselecionado) => [...prevselecionado, id])
    } else {
      setselecionado((prevselecionado) => prevselecionado.filter((item) => item !== id))
    }
  }

  function changeUser(id_empreendimento: number, id_user_new: number, id_user_old: number) {
    // Swal.fire({
    //   title: 'Deseja alterar o dono do empreendimento?',
    //   showCancelButton: true,
    //   cancelButtonText: 'Cancelar',
    //   confirmButtonText: 'Alterar',
    //   showCloseButton: true,
    // }).then(async (result) => {
    //   if (result.isConfirmed) {
    //     const result = await FormEmpreendimentoChangeUser({
    //       variables: {
    //         idEmpreendimento: id_empreendimento,
    //         idUsuario: id_user_new,
    //         idUsuarioOld: id_user_old,
    //       },
    //     })
    //     if (!result.data) {
    //       Swal.fire('ERRO!', 'Ocorreu um erro durante a execução.', 'warning')
    //     } else {
    //       Swal.fire('Sucesso!', 'Empreendimento deletado com sucesso.', 'success')
    //     }
    //   }
    // })
  }

  return (
    <>
      <CardTables
        headerTitle={'Empreendimentos '}
        headerSubtTitle={`Até o momento ${
          dataTable ? dataTable.GetRelatorioEmpreendimentosMesmaQuadra.length : 0
        } empreendimento(s) registrado(s).`}
        // headerToolbar={
        //   // <button
        //   //   className='btn btn-info me-5'
        //   //   onClick={() => {
        //   //     explorerOpen()
        //   //   }}
        //   // >
        //   //   <BiCompass className='fs-2'></BiCompass> Explorer
        //   // </button>
        // }
        footer={''}
        //   data ? (
        //     <PaginationComponent
        //       pagesInfo={data.GetRelatorioEmpreendimentosMesmaQuadra.pageInfo}
        //       setPagesInfo={(pagina: number, quantidade: number) => {
        //         setPaginacao({pagina: pagina, quantidade: quantidade})
        //       }}
        //     ></PaginationComponent>
        //   ) : (
        //     <></>
        //   )
        // }
      >
        <div className='table-responsive  '>
          <table className='table table-striped table-row-dashed  gy-2 gs-6  table-row-bordered   table-hover  table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                <th className='text-center'>Link</th>
                <th className=''> Nome</th>
                <th className=''>Bairro</th>
                <th className='text-center'>Setor/Quadra</th>
                <th className=''>Usuário</th>
                <th className='text-center'>Qtde</th>
                <th className='text-center'>Temperatura</th>
                <th className='text-center'>Situação</th>
                <th className=''>Cadastrado em </th>
                {/* <th className=' text-end'>Ações</th> */}
              </tr>
            </thead>
            <tbody>
              <LoadingTable loading={loading} />
              <ErrorTable error={error} />
              {dataTable ? (
                dataTable.GetRelatorioEmpreendimentosMesmaQuadra.length > 0 ? (
                  dataTable.GetRelatorioEmpreendimentosMesmaQuadra.map((response) => {
                    // let color = null
                    // if (response.empreendimento_temperatura){
                    // //  color =  chroma(response.empreendimento_temperatura?.cor  ).alpha(0.5).css()
                    // }

                    return (
                      <tr key={response.id}>
                        <td className=''>
                          <div className='form-check form-check-sm form-check-custom form-check-solid'>
                            {/* {response.id} */}
                            <Link
                              to={`/empreendimentos/details/${response.id}#composicao-terrenos`}
                              className='btn all-center  me-2'
                            >
                              <BiLinkExternal />
                            </Link>

                            {/* <input
                              className='form-check-input widget-9-check'
                              type='checkbox'
                              checked={isChecked(response.id)}
                              onChange={(event) => handleCheckboxChange(event, response.id)}
                            /> */}
                          </div>
                        </td>
                        <td> {response.nome}</td>
                        <td> {response.bairro}</td>
                        <td className='text-center'> {response.setor_quadra}</td>
                        <td> {response.nome_usuario}</td>
                        <td className='text-center'> {response.qtde}</td>
                        <td className='w-50px text-center '>
                          <div className='d-flex justify-content-center align-items-center gap-1 '>
                            {response.temperatura ? (
                              <>
                                <span
                                  className='badge badge-temperatura fw-bold me-auto px-4 py-2 p-5 '
                                  style={{
                                    backgroundColor: response.temperatura_cor,
                                  }}
                                >
                                  {response.temperatura}
                                </span>
                              </>
                            ) : (
                              <span className='flex-fill'> </span>
                            )}
                          </div>
                        </td>
                        <td className='text-center'>
                          <div className='d-flex justify-content-center align-items-center gap-1 '>
                            {response.situacao ? (
                              <span className='flex-fill'>{response.situacao}</span>
                            ) : (
                              <></>
                            )}
                          </div>
                        </td>

                        <td> {response.data_cadastro}</td>

                        {/*  <td>
                         <Buttons
                            id={response.id}
                            idUser={response.users.id}
                            bloqueado={response.bloqueado}
                          /> 
                        </td>*/}
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={10} className='text-center'>
                      Nenhum registro encontrado
                    </td>
                  </tr>
                )
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      </CardTables>
    </>
  )
}

export {TableMesmaQuadra}
