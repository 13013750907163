import {gql} from '@apollo/client'

export const SET_EMPREENDIMENTO_IMOVEL_CORRETOR = gql`
  mutation SetEmpreendimentoImovelCorretores(
    $idEmpreendimento: Float!
    $idCorretor: Float
    $corretor: corretor
  ) {
    SetEmpreendimentoImovelCorretores(
      id_empreendimento: $idEmpreendimento
      id_corretor: $idCorretor
      corretor: $corretor
    ) {
      id
    }
  }
`

export const DELETE_EMPREENDIMENTO_IMOVEL_CORRETOR = gql`
  mutation DeleteEmpreendimentoImovelCorretores($idEmpreendimento: Float!, $idCorretor: Float) {
    DeleteEmpreendimentoImovelCorretores(
      id_empreendimento: $idEmpreendimento
      id_corretor: $idCorretor
    ) {
      id
    }
  }
`
