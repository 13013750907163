import {useContext} from 'react'
import {Nav} from 'react-bootstrap'
import {Scrollbars} from 'react-custom-scrollbars-2'
import {FilterContext} from '../../context/FilterContext'
import {useLocation} from 'react-router-dom'
import Swal from 'sweetalert2'

export function TabItens({windowHeight, setActiveTab}) {
  const {filter, endereco} = useContext(FilterContext)
  const search = useLocation().search
  const searchParams = new URLSearchParams(search)
  let printIntes = ''
  if (searchParams.get('debug')) {
    console.log(searchParams.get('debug'))
    printIntes = searchParams.get('debug')
  }

  return (
    <Scrollbars style={{width: '100%', height: windowHeight - 110}}>
      <Nav variant='pills' className='flex-column m-0 nav-im'>
        <Nav.Item className='m-0'>
          <Nav.Link
            eventKey='item1'
            className='fs-6'
            onClick={() => {
              setActiveTab('zonaDeValor')
            }}
          >
            1. Definir zona de valor
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey='item2'
            className='fs-6'
            disabled={
              filter
                ? filter.areaInfluencia.tipo === 'zonaValor' && !filter.areaInfluencia.valor
                  ? true
                  : false
                : true
            }
            onClick={() => {
              if (filter.areaInfluencia.tipo === 'zonaValor' && !filter.areaInfluencia.valor) {
                // setActiveTab('zonaDeValor')
                Swal.fire('Atenção!', 'Selecione uma zona de valor para continuar.')
              } else {
                setActiveTab('definirSegmentacao')
              }
            }}
          >
            2. Definir Segmentação
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey='item3'
            className='fs-6'
            disabled={
              filter
                ? filter.segmentacaoArea !== null && filter.segmentacaoPreco !== null
                  ? false
                  : true
                : true
            }
            onClick={() => {
              setActiveTab('resumoMercado')
            }}
          >
            3. Definir Lançamentos
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey='item4'
            className='fs-6'
            disabled={
              filter
                ? filter.segmentacaoArea !== null && filter.segmentacaoPreco !== null
                  ? false
                  : true
                : true
            }
            onClick={() => {
              setActiveTab('performance')
            }}
          >
            4. Performance
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey='item41'
            className='fs-6'
            disabled={
              filter
                ? filter.segmentacaoArea !== null && filter.segmentacaoPreco !== null
                  ? false
                  : true
                : true
            }
            onClick={() => {
              setActiveTab('sociodemografico')
            }}
          >
            4.1 Sociodemográfico
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey='item42'
            className='fs-6'
            disabled={
              filter
                ? filter.segmentacaoArea !== null && filter.segmentacaoPreco !== null
                  ? false
                  : true
                : true
            }
            onClick={() => {
              setActiveTab('performanceResumoMercado')
            }}
          >
            4.2 Resumo de mercado
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey='item412'
            className='fs-6'
            disabled={
              filter.segmentacaoArea !== null && filter.segmentacaoPreco !== null ? false : true
            }
            onClick={() => {
              setActiveTab('analiseTemporal')
            }}
          >
            4.2.1 Análise Temporal
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey='item43'
            className='fs-6'
            disabled={
              filter
                ? filter.segmentacaoArea !== null && filter.segmentacaoPreco !== null
                  ? false
                  : true
                : true
            }
            onClick={() => {
              setActiveTab('performanceAnaliseConcorrentes')
            }}
          >
            4.3 Análise Concorrentes
          </Nav.Link>
        </Nav.Item>
        {/* <Nav.Item>
          <Nav.Link
            eventKey='item49'
            className='fs-6'
            disabled={
              filter
                ? filter.segmentacaoArea !== null && filter.segmentacaoPreco !== null
                  ? false
                  : true
                : true
            }
            onClick={() => {
              setActiveTab('desempenhovendas')
            }}
          >
            4.4 Desempenho Vendas
          </Nav.Link>
        </Nav.Item> */}
        <Nav.Item>
          <Nav.Link
            eventKey='item44'
            className='fs-6'
            disabled={
              filter
                ? filter.segmentacaoArea !== null && filter.segmentacaoPreco !== null
                  ? false
                  : true
                : true
            }
            onClick={() => {
              setActiveTab('performanceVendas')
            }}
          >
            4.4 Performance de Vendas
          </Nav.Link>
        </Nav.Item>
        {/* <Nav.Item>
          <Nav.Link
            eventKey='item45'
            className='fs-6'
            disabled={
              true
              //   filter.segmentacaoArea !== null &&
              //   filter.segmentacaoPreco !== null
              //     ? false
              //     : true
            }
            onClick={() => {
              setActiveTab('performanceEscassezTerrenos')
            }}
          >
            4.5 Escassez de terrenos<br></br>(Em breve)
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey='item46'
            className='fs-6'
            disabled={
              true
              // filter.segmentacaoArea !== null &&  filter.segmentacaoPreco !== null ? false : true
            }
            onClick={() => {
              setActiveTab('performanceBrevesLancamentos')
            }}
          >
            4.6 Breves Lançamentos<br></br>(Em breve)
          </Nav.Link>
        </Nav.Item> */}
        {/* <Nav.Item>
          <Nav.Link
            eventKey='item47'
            className='fs-6'
            disabled={
              filter
                ? filter.segmentacaoArea !== null && filter.segmentacaoPreco !== null
                  ? false
                  : true
                : true
            }
            onClick={() => {
              setActiveTab('mercadoRevenda')
            }}
          >
            4.7 Mercado de Revenda
          </Nav.Link>
        </Nav.Item> */}
        {/* <Nav.Item>
          <Nav.Link
            eventKey='item48'
            className='fs-6'
            disabled={
              true
              // filter.segmentacaoArea !== null && filter.segmentacaoPreco !== null ? false : true
            }
            onClick={() => {
              setActiveTab('pontosInteresse')
            }}
          >
            4.7 Pontos de Interesse<br></br>(Em breve)
          </Nav.Link>
        </Nav.Item> */}
        <Nav.Item>
          <Nav.Link
            eventKey='item5'
            className='fs-6'
            disabled={endereco && endereco.latitude ? false : true}
            onClick={() => {
              setActiveTab('avm')
            }}
          >
            5. AVM<br></br>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey='item6'
            className={'fs-6'}
            disabled={endereco && endereco.latitude ? false : true}
            onClick={() => {
              setActiveTab('ai')
            }}
          >
            6. Análise com IA (Beta)<br></br>
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </Scrollbars>
  )
}
