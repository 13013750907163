import React from 'react'
import {useMutation} from '@apollo/client'
import {GET_CORRETORES} from '../../../../../../graphql/query/corretor'
import {
  DELETE_EMPREENDIMENTO_IMOVEL_CORRETOR,
  GET_EMPREENDIMENTO_CORRETORES,
} from '../../../../../../graphql'
import {DeleteEmpreendimentoCorretorResponse} from '../../../../../../graphql/type/empreendiemnto_corretor'
import Swal from 'sweetalert2'
import {BiTrash} from 'react-icons/bi'

type Props = {
  idCorretor: number
  idEmpreendimento: number
}

export const Buttons: React.FC<Props> = ({idCorretor, idEmpreendimento}) => {
  const [deleteTerreno, {loading}] = useMutation<DeleteEmpreendimentoCorretorResponse>(
    DELETE_EMPREENDIMENTO_IMOVEL_CORRETOR,
    {
      refetchQueries: [GET_EMPREENDIMENTO_CORRETORES],
    }
  )

  async function HandleDelete() {
    Swal.fire({
      title: 'Deseja remover este corretor para todos os lotes?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Remover',
      showCloseButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        deleteTerreno({
          variables: {
            idCorretor: idCorretor,
            idEmpreendimento: idEmpreendimento,
          },
          refetchQueries: [GET_EMPREENDIMENTO_CORRETORES],
        }).then((data) => {
          if (data) {
            Swal.fire('Item Removido', 'Removido com sucesso.', 'success')
          } else {
            Swal.fire('Erro', 'Erro ao remover o corretor.', 'warning')
          }
        })
      }
    })
  }
  return (
    <>
      <div className='d-flex justify-content-end flex-shrink-0'>
        <button
          onClick={HandleDelete}
          disabled={loading}
          className='btn btn-icon btn-danger btn-sm'
        >
          <BiTrash></BiTrash>
        </button>
      </div>
    </>
  )
}
