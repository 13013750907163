import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import {BiArrowBack} from 'react-icons/bi'
import {Link, useParams} from 'react-router-dom'
import SalvarProjeto from '../SalvarProjeto'
import AssociarProjeto from './AssociarProjeto'

export default function MenuTopo({nome, tipo}: {nome: string; tipo: string}) {
  const {id: idProjetoURL} = useParams<{id: string}>()
  return (
    <div
      className='header align-items-stretch w-100'
      style={{borderBottom: '1px solid #eff2f5', backgroundColor: '#fff'}}
    >
      <Navbar expand='lg' className=' ' style={{width: '94%'}}>
        <Container fluid>
          <Navbar.Brand href='#'>
            <h2>Estudo de Mercado Secundário - {tipo.toUpperCase()}</h2>
            <small>{nome}</small>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='navbarScroll' />
          <Navbar.Collapse id='navbarScroll ' className='justify-content-end'>
            <Nav>
              <AssociarProjeto id={Number(idProjetoURL)}></AssociarProjeto>
              <SalvarProjeto idParam={null}></SalvarProjeto>
              <Link to={`/mercado-secundario/${tipo}`} className='btn btn-sencondary ms-10'>
                <BiArrowBack></BiArrowBack> Voltar
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}
