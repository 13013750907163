import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router'

import {AsideDefault} from '../../../_metronic/layout/components/aside/AsideDefault'
import '../global.css'
import {DrawerComponent} from '../../../_metronic/assets/ts/components'
import {Footer} from '../../../_metronic/layout/components/Footer'

import {HeaderWrapperFluid} from '../../../_metronic/layout/components/header/HeaderWrapperFluid'
import {Table} from './partials/TableRelatorio'
import {KTSVG} from '../../../_metronic/helpers'
import {Form, FormLabel} from 'react-bootstrap'
import {GetEmpreendimentoTipoChosen} from './components/Chosen/GetEmpreendimentoTipoChosen'
import {
  ChartsWidget1,
  ChartsWidget2,
  ChartsWidget8,
  ListsWidget6,
  TablesWidget5,
} from '../../../_metronic/partials/widgets'
import {InfosRelatorios} from './partials/InfosRelatorios'
import {TemperatureBar} from '../../components/UI/Bars/TemperatureBar'
import {GetUsuariosEquipesByCargoChosen} from '../../components/UI/Chosens/GetUsuariosEquipesByCargo'
import {GetEmpreendimentosTagsChosen} from '../../components/UI/Chosens/GetEmpreendimentoTags'
import {GetCorretoresChosen} from '../../components/UI/Chosens/GetCorretoresChosen'
import {GetEmpreendimentoSituacaoChosen} from '../../components/UI/Chosens/GetEmpreedimentoSituacao'
import {TablePorSituacaoEmpreendimento} from './components/TableReport/EquipeSituacao/TablePorSituacaoEmpreendimento'

import {TablePorSituacaoLote} from './components/TableReport/TablePorSituacaoLote'
import {TablePorSituacaoPendencia} from './components/TableReport/TablePorSituacaoPendencia'
import {RadialBar} from '../../components/UI/Charts/RadialBar'
import {BarChart} from './components/TableReport/EquipeSituacao/BarChart'
import {TablePorTemeperaturaEmpreendimento} from './components/TableReport/EquipeEmpreendimentoTemperatura/TablePorTemeperaturaEmpreendimento'

export function DashboardEmpreendimento() {
  const location = useLocation()
  useEffect(() => {
    DrawerComponent.hideAll()
  }, [location])

  const [toogleFilter, setToogleFilter] = useState(false)
  const [toogleInfos, setInfos] = useState(true)

  const [descart, setDescart] = React.useState(false)

  const [filter, setFilter] = useState([])
  const [objectFilter, setObjectFilter] = useState({})
  const [filtroValores, setFiltroValores] = useState({
    id_tag: null,
    id_situacao: null,
    id_corretor: null,
    id_temperatura: null,
    id_usuario: null,
  })

  function addFilter(e) {
    e.preventDefault()
    const data = new FormData(e.target)
    const filterArray = []

    if (data.get('nome')) {
      filterArray.push({
        field: ['nome', 'endereco'],
        operator: 'LIKE',
        value: data.get('nome'),
      })
    }

    if (data.get('id_tag')) {
      filterArray.push({
        field: ['tag'],
        operator: 'LIKE',
        value: data.get('id_tag'),
      })
    }

    if (data.get('id_situacao')) {
      filterArray.push({
        field: ['situacao_id'],
        operator: 'in',
        value: [data.get('id_situacao')],
      })
    }
    if (data.get('id_corretor')) {
      if (data.get('id_corretor') === '') {
        setObjectFilter({})
      } else {
        setObjectFilter({
          objectFilter: {
            empreendimento_terreno: {
              some: {
                terreno: {
                  terreno_corretor: {
                    some: {
                      corretor: {
                        id: Number(data.get('id_corretor')),
                        situacao: 1,
                      },
                      situacao: 1,
                    },
                  },
                },
              },
            },
          },
        })
      }
    } else {
      setObjectFilter({})
    }
    if (data.get('id_temperatura')) {
      if (data.get('id_temperatura') !== 'null') {
        filterArray.push({
          field: ['id_temperatura'],
          operator: 'in',
          value: [filtroValores.id_temperatura + ''],
        })
      }
    }

    if (data.get('id_usuario')) {
      if (data.get('id_usuario') !== 'null') {
        filterArray.push({
          field: ['user_id'],
          operator: '=',
          value: data.get('id_usuario'),
        })
      }
    }

    setFilter([...filterArray])
    return false
  }

  // const handleChecked = (e) => {
  //   setDescart(e.target.checked)
  // }

  function toggleInfos() {
    if (toogleInfos === true) {
      setInfos(false)
    } else if (toogleInfos === false) {
      setInfos(true)
      // setToogleFilter(false)
    }
  }

  function toggleFilter() {
    if (toogleFilter === false) {
      // setInfos(false)
      setToogleFilter(true)
    } else if (toogleFilter === true) {
      setToogleFilter(false)
    }
  }

  function limparForm() {
    setFiltroValores({
      id_tag: '',
      id_situacao: null,
      id_corretor: null,
      id_temperatura: '',
      id_usuario: null,
    })

    setFilter([])
    setObjectFilter({})
  }
  return (
    <>
      <AsideDefault />
      <HeaderWrapperFluid title='Relatório - Empreendimentos' />
      {/* <Empreendimentos /> */}
      <div className='wrapper d-flex flex-column flex-column-fluid' id='kt_wrapper'>
        <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
          <div className='post d-flex flex-column-fluid' id='kt_post'>
            {/* <HeaderPage /> */}
            <div id='kt_content_container' className='container-fluid'>
              <div
                className='py-6  d-flex align-items-center justify-content-between'
                id='kt_header_container'
              >
                <div
                  className='page-title d-flex flex-column align-items-start justify-content-center flex-wrap mt-n5 mt-lg-0 me-lg-2 pb-2 pb-lg-0'
                  data-kt-swapper='true'
                  data-kt-swapper-mode='prepend'
                  data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}"
                >
                  <h1 className='text-dark fw-bold my-0 fs-2'>Relatório</h1>
                  <ul className='breadcrumb fw-semibold fs-base my-1'>
                    <li className='breadcrumb-item text-muted'>
                      <a href='/dashboard' className='text-muted'>
                        Dashboard
                      </a>
                    </li>
                    <li className='breadcrumb-item text-muted'>Empreendimentos</li>
                    <li className='breadcrumb-item text-muted'>Relatórios</li>
                  </ul>
                </div>
                <div className='d-flex align-items-center ms-3'></div>
              </div>
              <div className='content  d-flex flex-column flex-column-fluid '>
                <div className={toogleFilter === true ? 'card show mb-10' : ' card hide mb-10'}>
                  <div className='border-0 pt-6 ps-5 pe-5'>
                    <div className='row gy-5 gx-xl-8'>
                      <div className='col-xl-12'>
                        <div className='card-xxl-stretch '>
                          <div className='card-form px-4 py-8 mb-2'>
                            <Form className=' form form-horizontal' onSubmit={addFilter}>
                              <div className='row'>
                                <div className='col-md-4  col-sm-4 mb-2'>
                                  <FormLabel className='form-label'>Nome/Endereço</FormLabel>
                                  <input
                                    type='text'
                                    className='form-control'
                                    placeholder='Digite o nome ou Endereço'
                                    name='nome'
                                  />
                                </div>
                                {/* <div className='col-md-4 mb-2'>
                                  <FormLabel className='form-label'>Situação</FormLabel>
                                  <input
                                    type='text'
                                    className='form-control'
                                    placeholder='name@example.com'
                                  />
                                </div> */}
                                <div className='col-md-4 col-sm-4 mb-2'>
                                  <FormLabel className='form-label'>Zoneamento</FormLabel>
                                  <input
                                    type='text'
                                    className='form-control'
                                    placeholder='Zoneamento'
                                  />
                                </div>

                                <div className='col-md-4 col-sm-4 mb-2'>
                                  <GetEmpreendimentoSituacaoChosen
                                    SetValueSituacao={(value) => {
                                      setFiltroValores({
                                        ...filtroValores,
                                        ...{id_situacao: value},
                                      })
                                    }}
                                    valueSituacao={filtroValores.id_situacao || null}
                                  ></GetEmpreendimentoSituacaoChosen>
                                </div>
                              </div>

                              <div className='row pt-2'>
                                <div className='col-md-4  col-sm-4 mb-2'>
                                  <GetCorretoresChosen
                                    SetValueCorretor={(value) => {
                                      setFiltroValores({
                                        ...filtroValores,
                                        ...{id_corretor: value},
                                      })
                                    }}
                                    valueCorretor={filtroValores.id_corretor || ''}
                                  ></GetCorretoresChosen>
                                </div>
                                <div className='col-md-4  col-sm-4 mb-2'>
                                  <FormLabel className='form-label'>Tags</FormLabel>
                                  <GetEmpreendimentosTagsChosen
                                    defaultValue={filtroValores.id_tag || ''}
                                    onChange={(value) => {
                                      setFiltroValores({...filtroValores, ...{id_tag: value}})
                                    }}
                                  ></GetEmpreendimentosTagsChosen>
                                </div>

                                <div className='col-md-4  col-sm-4 '>
                                  <FormLabel className='form-label'>Descartados</FormLabel>
                                  <div className='form-check pt-2'>
                                    <label className='form-check-label'>
                                      <input
                                        className='form-check-input'
                                        onChange={(e) => {
                                          setDescart(e.target.checked)
                                        }}
                                        value='4'
                                        type='checkbox'
                                        id='gridCheck'
                                      />
                                      Exibir Descartados
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className='row pt-2'>
                                <div className='col-md-4  col-sm-4 mb-2'>
                                  {/* <FormLabel className='form-label align-itens'>Usuário</FormLabel> */}
                                  <GetUsuariosEquipesByCargoChosen
                                    SetValue={(value) => {
                                      setFiltroValores({
                                        ...filtroValores,
                                        ...{id_usuario: Number(value)},
                                      })

                                      // changeUser(response.id ,Number(value),Number(response.users.id))
                                      // setValue('situacao_id', value)
                                    }}
                                    value={filtroValores.id_usuario + '' || null}
                                    showLabel={true}
                                  ></GetUsuariosEquipesByCargoChosen>
                                </div>
                                <div className='col-md-4  col-sm-4 mb-2'>
                                  <TemperatureBar
                                    SetValueSituacao={(value) => {
                                      setFiltroValores({
                                        ...filtroValores,
                                        ...{id_temperatura: value},
                                      })

                                      // setValue('id_temperatura', value)
                                    }}
                                    valueSituacao={filtroValores.id_temperatura + '' || null}
                                    showLabel={true}
                                  />
                                </div>
                              </div>

                              <div className='row pt-2'>
                                <div className='col-md-12 all-end pt-5'>
                                  <button
                                    type='submit'
                                    className='btn btn-sencondary me-5'
                                    onClick={limparForm}
                                  >
                                    Limpar
                                  </button>

                                  <button type='submit' className='btn btn-primary'>
                                    Buscar
                                  </button>
                                </div>
                              </div>

                              <div className='row  mt-3'>
                                {/* <div className='col-md-4'>
                                  <FormLabel className='form-label'>Tipo</FormLabel>
                                  <GetEmpreendimentoTipoChosen />
                                </div>
                                <div className='col-md-4'>
                                  <FormLabel className='form-label'>Tags</FormLabel>
                                  <input
                                    type='text'
                                    className='form-control'
                                    placeholder='name@example.com'
                                  />
                                </div> */}
                              </div>
                              <div className='row  mt-3'>
                                <div className='col-md-4'></div>
                              </div>
                            </Form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className={toogleInfos === true ? 'card show mb-10' : ' card hide mb-10'}>
                  <div className='border-0 pt-2 pb-5'>
                    <div className='col-xl-12'>
                      <div className='card-xxl-stretch'>
                        <InfosRelatorios
                          icon='/media/icons/duotune/general/gen046.svg'
                          title='Informações Relevantes'
                          className='card-xl-stretch mb-xl-8'
                          filtro={filter} objectFilter={objectFilter}  
                        />
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>

              <div className='content  d-flex flex-column flex-column-fluid'>
                <div className='row'>
                  <div className='col-6'>
                    <TablePorSituacaoEmpreendimento
                      className={'mt-5'}
                    ></TablePorSituacaoEmpreendimento>
                  </div>
                  <div className='col-6'>
                    <TablePorTemeperaturaEmpreendimento
                      className={'mt-5'}
                    ></TablePorTemeperaturaEmpreendimento>
                    {/* <RadialBar className={''} chartColor={'#ff0000'} chartHeight={'200'} descricao={''} valor={2}></RadialBar> */}
                    {/* <TablePorTemeperaturaEmpreendimento className={'mt-5'}></TablePorTemeperaturaEmpreendimento> */}
                  </div>
                </div>
                {/* <div className='row'>
                  <div className='col-6'>
                    <TablePorSituacaoLote className={'mt-5'}></TablePorSituacaoLote>
                  </div>
                  <div className='col-6'>
                    <TablePorSituacaoPendencia className={'mt-5'}></TablePorSituacaoPendencia>
                  </div>
                </div> */}
                {/* <Table
                  toggleInfos={toggleInfos}
                  active={descart}
                  filtro={filter}
                  toggleFilter={toggleFilter} objectFilter={objectFilter}                /> */}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}
