import {useQuery} from '@apollo/client'
import {useState} from 'react'

import Select from 'react-select'
import {TypesGetImovelTagsResponse} from '../../../../graphql/type/tags'
import {GET_IMOVEL_TAGS} from '../../../../graphql/query/tags'

export function GetImovelTagsChosen({settag}) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tags, setTags] = useState('')
  const {data} = useQuery<TypesGetImovelTagsResponse>(GET_IMOVEL_TAGS)

  let options = []

  const handleChange = (selectedOption) => {
    let resultTags = selectedOption.map((item) => {
      return item.label
    })
    setTags(resultTags)
    settag(resultTags)
  }

  // Tratativa de loading
  if (!data) {
    return (
      <Select
        options={options}
        isMulti
        onChange={handleChange}
        isDisabled={true}
        placeholder='Tags do Imóvel'
      />
    )
  }

  data.GetImovelTags.map((Options) => {
    options.push({value: Options.id, label: Options.tag})
    return []
  })

  return (
    <Select
      options={options}
      isMulti
      onChange={handleChange}
      placeholder='Tags do Imóvel'
      menuPortalTarget={document.body}
      name='tags'
      // styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
    />
  )
}
