import {useMutation, useQuery} from '@apollo/client'
import {
  ImoveisCheckConfliosResponse,
  ImoveisRelatorioResponse,
  SetImovelResponse,
  TypesGetImovelEmpreendimentoResponse,
} from '../type'
import {DELETE_IMOVEL, PUT_IMOVEL, SET_IMOVEL, SET_IMOVEL_EMPREENDIMENTO} from '../mutations'
import {
  FormCreateBasicImovelFormData,
  FormCreateBasicImovelFormSchema,
  FormCreateImovelAddEmprendimentoData,
  FormCreateImovelAddEmprendimentoSchema,
  FormCreateImovelFormData,
  FormCreateImovelFormSchema,
  FormImovelFormData,
  FormImovelFormSchema,
} from '../formValidations/Imoveis'
import {useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {
  GET_CHECK_IMOVEL_CONFLITO,
  GET_EMPREENDIMENTO_IMOVEIS,
  GET_IMOVEIS,
  GET_IMOVEIS_RELATORIO,
  GET_IMOVEL_CONTRIBUINTES,
  GET_IMOVEL_EMPREENDIMENTO,
  GET_IMOVEL_FICHAS,
  GET_IMOVEL_TAGS,
  GET_ONE_IMOVEL,
  GET_ONE_IMOVEL_BY_UUID,
} from '../query'
import {
  GetImovelFichasResponse,
  ImoveisResponse,
  ImovelResponse,
  ImovelUUIDResponse,
  DeleteImovelResponse,
  PutImovelResponse,
} from '../type'

import {TypesGetImovelContribuintesResponse} from '../type/imovel-contribuintes'
import {errorHandler} from '../errorHandler'
import Swal from 'sweetalert2'

interface QueryProps {
  variables: any
}

export function QueryGetImoveis({variables}: QueryProps) {
  const {data, error, loading} = useQuery<ImoveisResponse>(GET_IMOVEIS, {
    variables: {...variables},
  })

  return {data, error, loading}
}

export function QueryGetCheckImovelConflito({variables}: QueryProps) {
  const {data, error, loading} = useQuery<ImoveisCheckConfliosResponse>(GET_CHECK_IMOVEL_CONFLITO, {
    variables: {...variables},
  })

  return {data, error, loading}
}

export function QueryGetImoveisRelatorio({variables}: QueryProps) {
  const {data, error, loading} = useQuery<ImoveisRelatorioResponse>(GET_IMOVEIS_RELATORIO, {
    variables: {...variables},
  })

  return {data, error, loading}
}

export function QueryGetImovel({variables}: QueryProps) {
  const {data, error, loading} = useQuery<ImovelResponse>(GET_ONE_IMOVEL, {
    variables: {...variables},
    fetchPolicy: 'network-only',
  })

  return {data, error, loading}
}

interface Props {
  variables: any
  skip?: any
}

export function QueryGetImovelEmpreendimento({variables, skip}: Props) {
  const {data, loading, error} = useQuery<TypesGetImovelEmpreendimentoResponse>(
    GET_IMOVEL_EMPREENDIMENTO,
    {
      variables: {...variables},
    }
  )

  return {data, loading, error}
}

export function MutationDeleteImovel() {
  const [deleteCorretor, {loading, data, error}] = useMutation<DeleteImovelResponse>(
    DELETE_IMOVEL,
    {
      refetchQueries: [GET_IMOVEIS],
    }
  )

  async function HandleDeleteImoveis({variables}: QueryProps) {
    try {
      const result = await deleteCorretor({
        variables: {...variables},
        refetchQueries: [GET_IMOVEIS],
      })
      return result
    } catch (e) {
      if (e) {
        return false
      }
    }
  }

  return {HandleDeleteImoveis, loading, error, data}
}

export function MutationSetImovel() {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    control,
    formState: {errors},
  } = useForm<FormCreateImovelFormData>({
    resolver: zodResolver(FormCreateImovelFormSchema),
  })

  const [setImovelBody, {error, loading, data: DataImovelResponse}] =
    useMutation<SetImovelResponse>(SET_IMOVEL, {
      refetchQueries: [GET_IMOVEIS],
    })

  async function FormImovel(data: FormCreateImovelFormData) {
    // const id = data.id
    // delete data.id
    return await setImovelBody({
      variables: {
        // putImovelId: id,
        imovel: {...data},
      },
    })
  }

  return {
    register,
    handleSubmit,
    FormImovel,
    loading,
    errors,
    error,
    DataImovelResponse,
    setError,
    setValue,
    control,
  }
}

export function MutationSetImovelAddEmpreedimento() {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    control,
    formState: {errors},
  } = useForm<FormCreateImovelAddEmprendimentoData>({
    resolver: zodResolver(FormCreateImovelAddEmprendimentoSchema),
  })

  const [setImovelBody, {error, loading, data: DataImovelResponse}] =
    useMutation<SetImovelResponse>(SET_IMOVEL_EMPREENDIMENTO, {
      refetchQueries: [GET_EMPREENDIMENTO_IMOVEIS],
    })

  async function FormImovelAddEmpreendimento(data: FormCreateImovelAddEmprendimentoData) {
    const id = data.idEmpreendimento
    delete data.idEmpreendimento
    await setImovelBody({
      variables: {
        idEmpreendimento: id,
        imovel: {...data},
      },
    })
      .then((result) => {
        if (!result.data) {
          let erroText = errorHandler(result.errors)
          Swal.fire('Erro!', erroText, 'warning')
        } else {
          Swal.fire('Cadastrado!', 'Cadastrado com sucesso!', 'success')
        }
      })
      .catch((err) => {
        Swal.fire('Erro!', 'Ocorreu um erro inesperado, tente novamente mais tarde.', 'warning')
      })
  }

  return {
    register,
    handleSubmit,
    FormImovelAddEmpreendimento,
    loading,
    errors,
    error,
    DataImovelResponse,
    setError,
    setValue,
    control,
  }
}

export function MutationSetBasicImovel() {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    control,
    formState: {errors},
  } = useForm<FormCreateBasicImovelFormData>({
    resolver: zodResolver(FormCreateBasicImovelFormSchema),
  })
  const [setImovelBody, {error, loading, data: DataImovelResponse}] =
    useMutation<SetImovelResponse>(SET_IMOVEL, {
      refetchQueries: [GET_IMOVEIS],
    })

  async function FormImovel(data: FormCreateBasicImovelFormData) {
    // const id = data.id
    // delete data.id
    await setImovelBody({
      variables: {
        // putImovelId: id,
        imovel: {...data},
      },
    })
      .then((result) => {
        if (!result.data) {
          let erroText = errorHandler(result.errors)
          Swal.fire('Erro!', erroText, 'warning')
        } else {
          Swal.fire('Sucesso!', 'Imóvel criado com sucesso.', 'success')
        }
      })
      .catch((err) => {
        Swal.fire('Erro!', 'Ocorreu um erro inesperado, tente novamente mais tarde.', 'warning')
      })
  }

  return {
    register,
    handleSubmit,
    FormImovel,
    loading,
    errors,
    error,
    DataImovelResponse,
    setError,
    setValue,
    control,
  }
}

export function MutationPutImovel() {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    control,

    formState: {errors},
  } = useForm<FormImovelFormData>({
    resolver: zodResolver(FormImovelFormSchema),
  })

  const [setImovelBody, {error, loading, data: DataImovelResponse}] =
    useMutation<PutImovelResponse>(PUT_IMOVEL, {
      refetchQueries: [GET_ONE_IMOVEL, GET_IMOVEIS, GET_IMOVEL_TAGS],
    })

  async function FormImovel(data: FormImovelFormData) {
    const id = data.id
    delete data.id
    console.log(id)
    console.log(data)
    return await setImovelBody({
      variables: {
        putImovelId: id,
        imovel: {...data},
      },
    })
  }

  return {
    register,
    handleSubmit,
    FormImovel,
    loading,
    errors,
    error,
    DataImovelResponse,
    setError,
    setValue,
    control,
    getValues,
  }
}

export function QueryGetImovelByUUID({variables}: QueryProps) {
  const {data} = useQuery<ImovelUUIDResponse>(GET_ONE_IMOVEL_BY_UUID, {
    variables: {...variables},
  })

  return data
}

export function QueryGetImovelFicha({variables}: QueryProps) {
  const {data} = useQuery<GetImovelFichasResponse>(GET_IMOVEL_FICHAS, {
    variables: {
      variables: {...variables},
    },
  })
  return data
}

export function QueryGetImovelContribuintes({variables}: QueryProps) {
  const {data} = useQuery<TypesGetImovelContribuintesResponse>(GET_IMOVEL_CONTRIBUINTES, {
    variables: {...variables},
  })
  return data
}
