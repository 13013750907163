import {useEffect, useState} from 'react'

import Select from 'react-select'
import {FormLabel} from 'react-bootstrap'
import {getLaunchesPartners} from '../../../../../services/urbit-gis'

export function GetDevelopersChosen({SetValueSituacao, valueSituacao, disabled = false}) {
  let situacaoArray = []

  const [situacao, setSituacao] = useState('')
  const [options, setOptions] = useState([])

  useEffect(() => {
    if (valueSituacao) {
      setSituacao(valueSituacao)
    }
  }, [valueSituacao])

  useEffect(() => {
    async function load() {
      const result = await getLaunchesPartners(1, 1500)
      if (result) {
        let situacaoArray = []
        situacaoArray.push({value: '', label: '--selecione--'})
        result.data.map((item) => {
          situacaoArray.push({value: item.id_developer_orulo, label: item.nome})
          return []
        })
        setOptions(situacaoArray)
      }
    }
    load()
  }, [])

  if (!options) {
    return (
      <>
        <div className='col-form-label'>
          <FormLabel className='form-label'>Construtora</FormLabel>
          <Select
            options={situacaoArray}
            onChange={(e) => {
              setSituacao(e.value)
            }}
            value={situacaoArray.filter((e) => e.value)[0]}
            name='id_developer'
            placeholder='Construtora'
          />
        </div>
      </>
    )
  }

  console.log(situacao)
  return (
    <>
      <div className='form-group'>
        <FormLabel className='form-label'>Construtora</FormLabel>
        <Select
          value={
            options.find((e) => {
              console.log(e.value)
              console.log(situacao)
              return e.value === situacao
            }) || null
          }
          options={options}
          onChange={(e) => {
            setSituacao(e.value)
            SetValueSituacao(e.value)
          }}
          name='id_developer'
          placeholder='Construtora'
          isDisabled={disabled}
        />
      </div>
    </>
  )
}
