import {
  QueryGetEmpreendimentoCorretores,
  QueryGetEmpreendimentosMotivoDescarte,
} from '../../../../../../../graphql'
import {formatDate} from '../../../../common'

type PropsClickInfo = {
  id: number
}

export const EmpreendimentoCorretores: React.FC<PropsClickInfo> = ({id}) => {
  const data = QueryGetEmpreendimentoCorretores({
    variables: {
      pagination: {
        pagina: 0,
        quantidade: 100,
      },
      idEmpreendimento: id,
    },
  })

  if (!data) {
    return <div>Sem registro</div>
  }
  return (
    <div className='py-10'>
      <table className='table table-striped'>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Telefone</th>
            <th>Data Cadastro</th>
            {/* <th>Situação</th> */}
          </tr>
        </thead>
        <tbody>
          {data ? (
            data.GetEmpreendimentoCorretores.map((item) => {
              return (
                <tr>
                  <th>{item.nome}</th>
                  <th>{item.email}</th>
                  <th>{formatDate(item.data_cadastro)}</th>
                  {/* <th>
                    {item.situacao === 1 ? (
                      <span className='badge badge-success'>Ativo</span>
                    ) : (
                      <span className='badge badge-danger'>Inativo</span>
                    )}
                  </th> */}
                </tr>
              )
            })
          ) : (
            <></>
          )}
        </tbody>
      </table>
    </div>
  )
}
