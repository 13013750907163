import {useMemo} from 'react'

import {BarChart} from './BarChart'
import {Imoveis} from '../../types'

type Props = {
  label?: string
  itens: Imoveis[]
}

export default function NumeroOfertas({label, itens}: Props) {
  let {chartDataVSOMetragem} = useMemo(() => {
    let totalUnidades = Array(15).fill(0, 0, 15)
    let chartDataVSOMetragem = [
      {
        name: 'Qtde Ofertas',
        data: [],
      },
    ]

    // eslint-disable-next-line array-callback-return
    itens.map((item) => {
      let i = item.quartos
      if (i <= 7) {
        totalUnidades[i] = totalUnidades[i] + 1
      }
    })

    // eslint-disable-next-line array-callback-return
    totalUnidades.map((item, key) => {
      chartDataVSOMetragem[0].data.push({
        x: key + ' Dorm.',
        y: item,
        total_unidades: item,
        id: key,
      })
    })

    return {chartDataVSOMetragem}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itens])
  return (
    <>
      {/* <div className='col-6'> */}
      <BarChart
        data={chartDataVSOMetragem}
        className={''}
        title={'Qtde de ofertas por dormitórios'}
        subtitle={''}
        onChange={() => {}}
        legenda={['Total Unidades']}
        tooltip={''}
      ></BarChart>
      {/* </div>
      <div className='col-6'>Map</div> */}
    </>
  )
}
