import {FC, useEffect, useRef, useState} from 'react'
import {chatAi} from '../../../services/urbit-gis'
import {useAi} from './useAI'
import {ErrorMessageComponent} from '../../backoffice/components/UI/ErrorMessage'
import Swal from 'sweetalert2'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import {QueryGetAiChatHistorico} from '../../graphql/services/AiChatHistorico'
import {Loader} from '../UI/Loader/Loader'
import {BiCopy} from 'react-icons/bi'

type Props = {
  persona: string
  dadosAnalise: string
  id: number
  modulo: 'im_mercado_primario' | 'im_mercado_secundario'
}
type MessageChat = {
  messageOrigem: string
  message: string
  sessionId: string
}

function BulletPointList({text}) {
  const regex = /\*\*([^*]+)\*\*/g
  const textoComNegrito = text.replace(regex, '<b>$1</b>')

  return <ul dangerouslySetInnerHTML={{__html: textoComNegrito}}></ul>
}

export default BulletPointList

export const AiChat: FC<Props> = ({persona, dadosAnalise, id, modulo}) => {
  // const [message, setMessage] = useState<string>('')
  const [historyMessage, setHistoryMessage] = useState<MessageChat[]>([])
  const [chatId, setChatId] = useState(null)
  const {handleSubmit, register, errors, setValue} = useAi()
  const chatRef = useRef<HTMLDivElement>(null)
  const refDiv = useRef<HTMLDivElement>(null)
  const buttomRef = useRef<HTMLButtonElement>(null)

  const {data, loading} = QueryGetAiChatHistorico({
    variables: {
      pagination: {
        pagina: 0,
        quantidade: 100,
      },
      idProjeto: id,
    },
  })

  useEffect(() => {
    if (data) {
      let mensagens: MessageChat[] = []
      // eslint-disable-next-line array-callback-return
      data.GetAiChatHistorico.result.map((item) => {
        mensagens.push({
          messageOrigem: item.tipo_mensagem,
          message: item.mensagem,
          sessionId: item.id_sessao,
        })
      })
      // setChatId(data.GetAiChatHistorico.result[0].id_sessao)
      setHistoryMessage(mensagens)
    }
  }, [data])

  async function chatMensagem(message: string) {
    let param = {
      prompt: message,
      sessionId: chatId || null,
      persona: 'especilistaIM',
      data: dadosAnalise,
      id_referencia: id,
      modulo: modulo,
      tipo_mensagem: 'cliente',
    }

    let chat = await chatAi(param)

    if (chat) {
      setChatId(chat.sessionId)
      let historyMessageTemp = historyMessage
      historyMessageTemp.push({
        messageOrigem: 'cliente',
        message: message,
        sessionId: chatId,
      })
      historyMessageTemp.push({
        messageOrigem: 'ia',
        message: chat.result,
        sessionId: chat.sessionId,
      })

      buttomRef.current.innerHTML = 'Enviar'
      buttomRef.current.classList.remove('disabled')
      buttomRef.current.disabled = false
      scrollChat()
      setHistoryMessage([...historyMessageTemp])
    }

    if (Swal.isVisible()) Swal.close()
  }

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text)
      console.log('Texto copiado para a área de transferência')
    } catch (err) {
      console.log('Falha ao copiar o texto', err)
    }
  }

  async function analiseInicial(message: string) {
    Swal.showLoading()

    let param = {
      prompt: message,
      sessionId: chatId || null,
      persona: 'especilistaIM',
      id_referencia: id,
      modulo: modulo,
      tipo_mensagem: 'sistema',
      // data: dadosAnalise,
    }
    let chat = await chatAi(param)
    setChatId(chat.sessionId)

    let resultMessage = {messageOrigem: 'ia', message: chat.result, sessionId: chat.sessionId}
    let historyMessageTemp = historyMessage
    historyMessageTemp.push({messageOrigem: 'cliente', message: message, sessionId: chatId})
    historyMessageTemp.push(resultMessage)
    if (Swal.isVisible()) Swal.close()

    setHistoryMessage([...historyMessageTemp])

    return resultMessage
  }

  async function onSubmit(data) {
    Swal.fire({
      title: 'IA trabalhando',
      html: 'Analisando os dados dos lançamentos.',
      // timer: 2000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
      },
    })

    buttomRef.current.innerHTML = 'Enviando....'
    buttomRef.current.classList.add('disabled')
    buttomRef.current.disabled = true
    // inputMessage.current.value = ''
    setValue('mensagem', '')

    // if (message.length > 0) {
    await chatMensagem(data.mensagem)
    // setMessage(data.mensagem)
    // }
  }

  function scrollChat() {
    if (chatRef.current) {
      // const element = chatRef.current
      // element.scrollTop = element.scrollHeight - 100
      document
        .getElementById('left-tabs-example-tabpane-item6')
        .scrollIntoView({behavior: 'smooth', block: 'end'})
    }
  }

  function analisarComIA(e) {
    e.target.classList.add('hide')
    Swal.fire({
      title: 'IA trabalhando',
      html: 'Analisando os dados dos lançamentos.',
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
      },
    })

    chatRef.current.classList.remove('hide')
    analiseInicial(dadosAnalise)
  }

  if (historyMessage) {
    if (chatRef.current) {
      chatRef.current.classList.remove('hide')
      document
        .getElementById('left-tabs-example-tabpane-item6')
        .scrollIntoView({behavior: 'smooth', block: 'end'})
    }
  }

  return (
    <div>
      <div
        className='card pt-10 bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance'
        ref={refDiv}
      >
        <div className='row g-5 g-xl-8'>
          <section className=' hide' ref={chatRef}>
            <section className='chat__messages pt-0'>
              {loading ? <Loader text={'Carregando mensagens'}></Loader> : <></>}
              {historyMessage.length === 0 ? (
                <div className='row' key={0}>
                  <div className='col-12 d-flex justify-content-center'>
                    <button
                      className='btn btn-primary mb-5'
                      onClick={(e) => {
                        analisarComIA(e)
                      }}
                    >
                      Analisar dados com IA
                    </button>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {
                // eslint-disable-next-line array-callback-return
                historyMessage.map((item, index) => {
                  if (index > 0) {
                    if (item.messageOrigem === 'ia') {
                      return (
                        <div className='message--other ' key={index}>
                          <div className='d-flex flex-row justify-content-between py-3'>
                            <h4 className='p-3  text-gray-700'>Urbit IA</h4>
                            <div className='px-5'>
                              <button className='btn' onClick={() => copyToClipboard(item.message)}>
                                <BiCopy className='fs-1 mouse-pointer'></BiCopy>
                              </button>
                            </div>
                          </div>

                          <div className='pull-left px-3 mb-0'>
                            {
                              <ReactMarkdown
                                children={item.message}
                                remarkPlugins={[remarkGfm]}
                                rehypePlugins={[rehypeRaw]}
                                components={{
                                  // Map `h1` (`# heading`) to use `h2`s.
                                  br: 'br',
                                  // Rewrite `em`s (`*like so*`) to `i` with a red foreground color.
                                  em: ({node, ...props}) => {
                                    return <i style={{color: 'red'}} {...props} />
                                  },
                                  table: ({node, ...props}) => (
                                    <table className='table table-striped' {...props} />
                                  ),
                                  th: ({node, ...props}) => (
                                    <th
                                      className='border border-gray-300 px-4 py-2 bg-gray-100'
                                      {...props}
                                    />
                                  ),
                                  td: ({node, ...props}) => (
                                    <td className='border border-gray-300 px-4 py-2' {...props} />
                                  ),
                                }}
                              ></ReactMarkdown>
                            }
                          </div>
                        </div>
                      )
                    } else if (item.messageOrigem === 'cliente') {
                      return (
                        <div className='message--self' key={index}>
                          <h4 className='p-3  text-gray-700'>Mensagem Cliente</h4>
                          <div className='pull-left  mb-0'>
                            {item.message.split('\n').map(function (item, idx) {
                              return (
                                <span key={idx}>
                                  <BulletPointList text={item} />
                                  <br />
                                </span>
                              )
                            })}
                          </div>
                        </div>
                      )
                    }
                  }
                })
              }
            </section>

            <form className='chat__form' onSubmit={handleSubmit(onSubmit)}>
              {chatId ? (
                <input
                  type='text'
                  defaultValue={''}
                  className='chat__input form-control'
                  placeholder='Digite sua perguta ou dúvida'
                  required
                  {...register('mensagem', {required: true})}
                />
              ) : (
                <></>
              )}
              {chatId ? (
                <button type='submit' ref={buttomRef} className='chat__button btn btn-sm btn-info'>
                  Enviar
                </button>
              ) : (
                <></>
              )}
            </form>
            <ErrorMessageComponent
              message={errors ? errors.mensagem?.message : ''}
            ></ErrorMessageComponent>
          </section>

          {/* <div className='chat' id='chat' ref={chat}></div> */}
        </div>
      </div>
    </div>
  )
}
