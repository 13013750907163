import {FC} from 'react'
import {Switch, Route, BrowserRouter, Redirect} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {MasterInit} from '../../_metronic/layout/MasterInit'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {ForgotPage, LoginPage, Logout, RegisterPage} from '../modules/auth'
import {VerifyTokenPage} from '../modules/auth/VerifyToken'
import {ChangePasswordPage} from '../modules/auth/ChangePassword'
import {ExplorerPage} from '../pages/explorer/public/publicPage'
import {LancamentoPage} from '../pages/lancamento'
import {FichaPublic} from '../pages/relatorios/ficha/indexPublic'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {Profile} from '../pages/profile'
import {Pessoas} from '../pages/pessoas'
import {Corretores} from '../pages/corretores'
import {Pacotes} from '../pages/pacotes'
import CreatePacotes from '../pages/pacotes/create'
import {Explorer} from '../pages/explorer'
import {Empreendimento} from '../pages/empreendimento'
import {MapaReport} from '../pages/empreendimento/mapaReport'
import {DashboardEmpreendimento} from '../pages/empreendimento/dashboardEmpreendimento'
import {EmpreendimentoMesmaQuadra} from '../pages/empreendimento/empreendimentoMesmaQuadra'
import {CreateEmpreendimentos} from '../pages/empreendimento/create'
import {DetailsEmpreendimentos} from '../pages/empreendimento/empreendimento'
import {RelatorioEmpreendimento} from '../pages/empreendimento/relatorio'
import {MeusImoveis} from '../pages/meus-imoveis'
import {ExportPdf} from '../pages/meus-imoveis/pdf'
import {ExportPdf as ExportPdfEmpreendimento} from '../pages/empreendimento/pdf'
import {ExportFichaPdf} from '../pages/relatorios/ficha/FichaPDF'
import {DetailsImovel} from '../pages/meus-imoveis/imovel'
import {Fichas} from '../pages/relatorios/ficha'
import {CadastroGrupos} from '../pages/cadastro'
import {SituacaoLotes} from '../pages/situacao-lotes'
import {SituacaoEmpreendimentos} from '../pages/situacao-empreendimentos'
import {SugestoesUsuarios} from '../pages/sugestoes-usuarios'
import {Sugestoes} from '../pages/sugestoes-usuarios/sugestao'
import {useAuth} from '../modules/auth/components/Context/authcontext'
import {RelatoriosImovel} from '../pages/meus-imoveis/relatorio'
import {ProspeccaoTerreno} from '../pages/prospeccao-terrenos'

// Rotas BACKOFFICE
import {
  AdminUsuarios,
  AdminEditUsuarios,
  AdminEmpresas,
  AdminEditEmpresas,
  AdminCupons,
  AdminPacotes,
  AdminEditPacotes,
  AdminPedidos,
  AdminProdutos,
  AdminNewsFeed,
  AdminExplorerLayers,
  AdminExplorerLayersCreate,
  AdminExplorerLayersEdit,
  AdminServicos,
  AdminTermos,
  AdminEmpreendimentos,
  AdminEditEmpreendimentos,
  AdminCreateEmpreendimentos,
} from '../backoffice/pages'
import {TermsConditionsPage} from '../pages/public/terms_conditions'
import {SupportPage} from '../pages/public/support'
import {Proprietarios} from '../pages/proprietarios'
import {PainelMercado} from '../pages/public/PainelMercado'
import {AdminCreatePacotes} from '../backoffice/pages/pacotes/create'
import {SuportWrapper} from '../pages/support'
import AdminCreateServicos from '../backoffice/pages/servicos/create'
import {AdminEditServicos} from '../backoffice/pages/servicos/edit'
import {AnaliseMercado} from '../pages/analise-mercado'

import {InteligenciaMercado} from '../pages/inteligencia-mercado'
import {Mercado} from '../pages/inteligencia-mercado/inteligencia-mercado'
import {MercadoSecundario} from '../pages/mercado-secundario'
import {MercadoSecundarioVenda} from '../pages/mercado-secundario/venda'
import {MercadoSecundarioAluguel} from '../pages/mercado-secundario/aluguel'

import {ModalLogin} from '../components/UI'
import {Avaliacoes} from '../pages/avaliacoes'
import {AdminAssinaturas} from '../backoffice/pages/assinaturas'
import {Equipes} from '../pages/equipes'
import {TemperaturaEmpreendimentos} from '../pages/empreendimentos-temperatura'
import {Prospeccao} from '../pages/prospeccao-terrenos/prospecao'

const Routes: FC = () => {
  const {authenticated} = useAuth()

  let isAuthenticated = false
  if (authenticated) {
    isAuthenticated = true
  }
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/auth/registration' component={RegisterPage} />
        <Route path='/auth/recovery' component={ForgotPage} />
        <Route path='/auth/verify-token' component={VerifyTokenPage} />
        <Route path='/auth/change-password/:id' component={ChangePasswordPage} />
        <Route path='/explorer-public' component={ExplorerPage} />
        <Route path='/lancamento/:id' component={LancamentoPage} />
        <Route path='/auth/terms' component={TermsConditionsPage} />
        <Route path='/ficha/public/:uuid?' component={FichaPublic} />

        <Route path='/painel-de-mercado/:lat/:lng/:token/:gtoken' exact component={PainelMercado} />

        <Route path='/support' component={SupportPage} />
        <Route path='/logout' component={Logout} />
        <Route exact path='/'>
          <Redirect to='/auth/login' />
        </Route>

        <Route exact path='/auth/login'>
          {isAuthenticated ? <Redirect to='/dashboard' /> : <LoginPage />}
        </Route>
        <PrivateRoute
          path='/dashboard'
          component={DashboardWrapper}
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          path='/tutorial'
          component={SuportWrapper}
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute path='/profile' component={Profile} isAuthenticated={isAuthenticated} />
        <PrivateRoute path='/pessoas' component={Pessoas} isAuthenticated={isAuthenticated} />

        <PrivateRoute path='/pacotes' component={Pacotes} isAuthenticated={isAuthenticated} />
        <PrivateRoute
          path='/create-pacotes'
          component={CreatePacotes}
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute path='/explorer' component={Explorer} isAuthenticated={isAuthenticated} />

        <PrivateRoute
          exact
          path='/meus-imoveis'
          component={MeusImoveis}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          exact
          path='/meus-imoveis/export-pdf/:id'
          component={ExportPdf}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          exact
          path='/meus-imoveis/details/:id/:hash?'
          component={DetailsImovel}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          exact
          path='/meus-imoveis/relatorios'
          component={RelatoriosImovel}
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          exact
          path='/ficha/:uuid?'
          component={Fichas}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          exact
          path='/ficha/:uuid/download'
          component={ExportFichaPdf}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          exact
          path='/empreendimentos'
          component={Empreendimento}
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          exact
          path='/empreendimentos/create'
          component={CreateEmpreendimentos}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          exact
          path='/empreendimentos/details/:id'
          component={DetailsEmpreendimentos}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          exact
          path='/empreendimentos/relatorios'
          component={RelatorioEmpreendimento}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          exact
          path='/empreendimentos/mapa'
          component={MapaReport}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          exact
          path='/empreendimentos/dashboard'
          component={DashboardEmpreendimento}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          exact
          path='/empreendimento/export-pdf/:id'
          component={ExportPdfEmpreendimento}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          exact
          path='/empreendimentos/mesma-quadra'
          component={EmpreendimentoMesmaQuadra}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          path='/analise-mercado'
          component={AnaliseMercado}
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          exact
          path='/inteligencia-mercado'
          component={InteligenciaMercado}
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          exact
          path='/inteligencia-mercado/:id'
          component={Mercado}
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          exact
          path='/mercado-secundario/:tipo'
          component={MercadoSecundario}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          exact
          path='/mercado-secundario/:tipo/novo'
          component={MercadoSecundarioVenda}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          exact
          path='/mercado-secundario/:tipo/:id'
          component={MercadoSecundarioVenda}
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          exact
          path='/mercado-secundario/:tipo/:id'
          component={MercadoSecundarioAluguel}
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          exact
          path='/prospecaco-terreno'
          component={ProspeccaoTerreno}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          exact
          path='/prospeccao-terreno/novo'
          component={Prospeccao}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute path='/avaliacoes' component={Avaliacoes} isAuthenticated={isAuthenticated} />

        <PrivateRoute
          path='/cadastro/cadastro-grupos'
          component={CadastroGrupos}
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          exact
          path='/cadastro/situacao-lotes'
          component={SituacaoLotes}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          exact
          path='/cadastro/situacao-empreendimentos'
          component={SituacaoEmpreendimentos}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          exact
          path='/cadastro/temperatura-empreendimentos'
          component={TemperaturaEmpreendimentos}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          exact
          path='/cadastro/corretores'
          component={Corretores}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          exact
          path='/cadastro/proprietarios'
          component={Proprietarios}
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          path='/cadastro/equipes'
          component={Equipes}
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          path='/sugestoes-usuarios'
          component={SugestoesUsuarios}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute path='/sugestoes' component={Sugestoes} isAuthenticated={isAuthenticated} />

        {/* ROTAS BACKOFFICE */}
        <PrivateRoute
          exact
          path='/backoffice/usuarios'
          component={AdminUsuarios}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          exact
          path='/backoffice/usuarios/edit/:id'
          component={AdminEditUsuarios}
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          exact
          path='/backoffice/empreendimentos'
          component={AdminEmpreendimentos}
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          exact
          path='/backoffice/empreendimentos/create'
          component={AdminCreateEmpreendimentos}
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          exact
          path='/backoffice/empreendimentos/edit/:id'
          component={AdminEditEmpreendimentos}
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          exact
          path='/backoffice/assinaturas'
          component={AdminAssinaturas}
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          exact
          path='/backoffice/empresas'
          component={AdminEmpresas}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          exact
          path='/backoffice/empresas/edit/:id'
          component={AdminEditEmpresas}
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          exact
          path='/backoffice/pedidos'
          component={AdminPedidos}
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          exact
          path='/backoffice/pacotes'
          component={AdminPacotes}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          exact
          path='/backoffice/pacotes/create'
          component={AdminCreatePacotes}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          exact
          path='/backoffice/pacotes/edit/:id'
          component={AdminEditPacotes}
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          exact
          path='/backoffice/servicos'
          component={AdminServicos}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          exact
          path='/backoffice/servicos/create'
          component={AdminCreateServicos}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          exact
          path='/backoffice/servicos/edit/:id'
          component={AdminEditServicos}
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          path='/backoffice/produtos'
          component={AdminProdutos}
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          path='/backoffice/news-feed'
          component={AdminNewsFeed}
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          exact
          path='/backoffice/explorer-layers'
          component={AdminExplorerLayers}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          exact
          path='/backoffice/explorer-layers/create'
          component={AdminExplorerLayersCreate}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          exact
          path='/backoffice/explorer-layers/edit/:id'
          component={AdminExplorerLayersEdit}
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          path='/backoffice/cupons'
          component={AdminCupons}
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          path='/backoffice/termos'
          component={AdminTermos}
          isAuthenticated={isAuthenticated}
        />
        {/* FIM DAS ROTAS BACKOFFICE */}

        <Route path='*'>
          <NoMatch></NoMatch>
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

export {Routes}

function NoMatch() {
  // let location = useLocation()
  return <ErrorsPage></ErrorsPage>
}

function PrivateRoute({component: Component, isAuthenticated, ...rest}) {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <MasterLayout>
            <Component {...props} />
            <MasterInit />
            <ModalLogin />
          </MasterLayout>
        ) : (
          <Redirect to={{pathname: '/auth/login', state: {from: props.location}}} />
        )
      }
    />
  )
}
