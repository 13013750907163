import React, {useEffect, useRef, useState} from 'react'
import {Offcanvas} from 'react-bootstrap'
import Scrollbars from 'react-custom-scrollbars-2'
import {Composicao as ComposicaoClass} from '../../../Map/ComponentsControl/ComposicaoControl/composicao'
import Swal from 'sweetalert2'
import {useExplorerComposicao} from '../../../../../../../hooks/useExplorerComposicao'
import {Explorer} from '../../../Map/Explorer'
import {BiMapPin, BiScreenshot, BiTrash} from 'react-icons/bi'
import {useEndereco} from '../../../../../../../hooks/useEndereco'
import {ComposicaoModalSave} from './ComposicaoModalSave'

type Props = {
  show: boolean
  onChangeFiltro: any
  onAdicionarCamada: any
  onHandleClose: any
  layerCurrrent: any
}

export const Composicao: React.FC<Props> = ({
  show,
  onChangeFiltro,
  onAdicionarCamada,
  onHandleClose,
  layerCurrrent,
}) => {
  const {composicao, onUpdateComposicao} = useExplorerComposicao()
  const menuLateral = useRef<HTMLDivElement | null>(null)
  const windowHeight = window.innerHeight - 50
  const [showModal, setShowModal] = useState<boolean>(false)
  const [municipio, setMunicipio] = useState({
    nome: null,
    id: null,
    lote_fiscal: null,
  })

  const wsize = windowHeight

  function resize() {
    if (menuLateral.current !== null) {
    }
  }

  const {endereco} = useEndereco()
  useEffect(() => {
    setMunicipio(endereco.municipio[0])
  }, [endereco])

  const index = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

  function remove(item) {
    const removedItem = ComposicaoClass.remove(composicao, item)
    onUpdateComposicao(removedItem)
    setTimeout(() => {
      removeSelecao(item)
    }, 500)
  }

  function removeSelecao(item) {
    const idLote = composicao[item].gid
    const idLayer = ComposicaoClass.getComposicaoLayer()
    Explorer.removeClickPolygon(idLayer, idLote)
  }

  async function salvarComposicao() {
    if (composicao.length === 0) {
      Swal.fire(
        'Erro!',
        'Composição vazia.Selecione um lote no mapa para começar a composição',
        'info'
      )
      return false
    } else {
      setShowModal(true)
    }
  }
  useEffect(() => {
    if (!show) {
      setShowModal(false)
    }
  }, [show])

  function drawMode() {
    Explorer.setDrawMode('polygon')
  }

  function selectMode() {
    Explorer.setDrawMode('')
  }

  function limpar() {
    onUpdateComposicao([])
    ComposicaoClass.clearSelecao()
    Explorer.clearClickPolygon()
    // Explorer.setDrawMode('')

    // eslint-disable-next-line array-callback-return
    composicao.map(function (comp) {
      if (typeof comp.polygon !== 'undefined') {
        comp.polygon.setMap(null)
      }
    })

    // Explorer.removeLayer(layerCurrrent)
  }

  function updateComposicao(index) {
    const frm = document.forms['update-data-composicao' + index]
    const itens = frm.elements
    let composicomUpate = []
    Array.from(itens).forEach((element) => {
      composicomUpate = update(index, element['name'], element['value'])
    })
    onUpdateComposicao(composicomUpate)

    // const value = frm.elements["testada_para_calculo"].value
  }

  function update(item, key, value) {
    const updatedItem = ComposicaoClass.update(composicao, item, key, value)
    return updatedItem
    // onUpdateComposicao(removedItem)
    // setTimeout(() => {
    //   removeSelecao(item)
    // }, 500)
  }

  let totalArea = 0

  return (
    <>
      <Offcanvas
        show={show}
        onHide={onHandleClose}
        id='offcanvas-direita'
        data-bs-focus='false'
        tabIndex={''}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Composição</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Scrollbars style={{width: '100%', height: wsize}} ref={resize}>
            <div className=' p-5'>
              <div className='row m-0 d-flex p-5 '>
                {municipio && municipio['lote_fiscal'] === 1 ? (
                  <button
                    className='col-md-3 btn btn-sm btn-light-primary me-3'
                    onClick={selectMode}
                  >
                    <BiMapPin className='fs-1'></BiMapPin>
                    <br />
                    Selecionar área
                  </button>
                ) : (
                  <button
                    className='col-md-3 btn btn-sm btn-light-primary me-3'
                    title='Selecionar área'
                    onClick={drawMode}
                  >
                    <BiScreenshot className='fs-1'></BiScreenshot> <br />
                    Desenhar área
                  </button>
                )}

                <button
                  className='col-md-3 btn btn-sm btn-light-danger me-3'
                  title='Limpar área'
                  onClick={limpar}
                >
                  <BiTrash className='fs-1'></BiTrash> <br />
                  Limpar
                </button>
              </div>
              <div className='row m-0 py-5'>
                <div className='px-5 py-5 col-md-9'>
                  {composicao.length > 0
                    ? // eslint-disable-next-line array-callback-return
                      composicao.map(function (data, y) {
                        totalArea = totalArea + Number(data.area_lote)
                      })
                    : ''}
                  <p>
                    Área total: <strong>{totalArea ? totalArea.toFixed(0) : 0} m²</strong>
                  </p>
                </div>
                <div className='col-md-3 text-right'>
                  <button className='btn btn-info' onClick={salvarComposicao}>
                    Salvar
                  </button>
                </div>
              </div>
              <div className='accordion accordion-flush' id='accordionFlushExample'>
                {composicao.length > 0
                  ? composicao.map(function (data, y) {
                      return (
                        <div className='accordion-item' key={y}>
                          <h2 className='accordion-header' id={'flush-headingOne' + y}>
                            <button
                              className='accordion-button collapsed  py-4 px-5'
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target={'#flush-collapseOne' + y}
                              aria-expanded='false'
                              aria-controls={'flush-collapseOne' + y}
                            >
                              <span className='badge bg-primary'>{index[y]}1</span>
                              <p className='m-1'>
                                {data.logradouro} , nº {data.numero}
                              </p>
                            </button>
                          </h2>
                          <div
                            id={'flush-collapseOne' + y}
                            className='accordion-collapse collapse'
                            aria-labelledby={'flush-headingOne' + y}
                            data-bs-parent='#accordionFlushExample'
                          >
                            <div className='accordion-body'>
                              <div className='flex text-right'>
                                <button
                                  className='btn btn btn-light-danger m-2'
                                  onClick={() => {
                                    remove(y)
                                  }}
                                >
                                  <span className='remove'>
                                    <BiTrash className='fs-1'></BiTrash>
                                  </span>
                                </button>
                                {/* <button
                                className='btn btn btn-primary m-2'
                                onClick={() => {
                                  verNoMapa(y)
                                }}
                              >
                                <span className='pin'>
                                  <BiPin className='fs-1'></BiPin>
                                </span>
                              </button> */}
                              </div>
                              <form name={'update-data-composicao' + y}>
                                <ul className='list-group d-flex justify-content-space-between '>
                                  <li className='list-group-item d-flex align-items-center justify-content-between'>
                                    Endereço :{' '}
                                    <input
                                      type='text'
                                      className='form-control form-control-sm sm  w-60'
                                      name='nm_logrado'
                                      defaultValue={data.logradouro}
                                    />
                                  </li>
                                  <li className='list-group-item d-flex align-items-cente justify-content-between'>
                                    Número :{' '}
                                    <input
                                      type='text'
                                      className='form-control form-control-sm sm w-60'
                                      name='numero'
                                      defaultValue={data.numero}
                                    />
                                  </li>
                                  <li className='list-group-item d-flex align-items-center justify-content-between'>
                                    Área do Terreno :{' '}
                                    <input
                                      type='text'
                                      className='form-control form-control-sm sm  w-60'
                                      name='area_lote'
                                      defaultValue={
                                        data.area_lote ? Number(data.area_lote).toFixed(0) : 0
                                      }
                                    />
                                  </li>
                                  <li className='list-group-item d-flex align-items-center justify-content-between'>
                                    Testada :{' '}
                                    <input
                                      type='text'
                                      className='form-control form-control-sm sm  w-60'
                                      name='testada'
                                      defaultValue={data.testada}
                                    />
                                  </li>
                                  <li className='list-group-item d-flex align-items-center justify-content-between'>
                                    Número do contribuinte :{' '}
                                    <input
                                      type='text'
                                      className='form-control form-control-sm sm  w-60'
                                      name='numero_contribuinte'
                                      defaultValue={data.numero_contribuinte}
                                    />
                                  </li>
                                  <li className='list-group-item d-flex align-items-center justify-content-between'>
                                    Uso :{' '}
                                    <input
                                      type='text'
                                      className='form-control sm  w-60'
                                      name='uso_atual'
                                      defaultValue={data.uso_atual}
                                    />
                                  </li>
                                  <li className='list-group-item d-flex align-items-center justify-content-between'>
                                    Pavimentos :{' '}
                                    <input
                                      type='text'
                                      className='form-control sm  w-60'
                                      name='quantidade_de_pavimentos'
                                      defaultValue={data.quantidade_de_pavimentos}
                                    />
                                  </li>
                                  <li className='list-group-item d-flex align-items-center justify-content-between'>
                                    Tipo terreno :
                                    <input
                                      type='text'
                                      className='form-control sm  w-60'
                                      name=' '
                                      defaultValue={data.tipo_de_terreno}
                                    />
                                  </li>
                                </ul>
                              </form>
                              <button
                                className='btn btn-primary d-flex mt-5 '
                                style={{float: 'right'}}
                                onClick={() => updateComposicao(y)}
                              >
                                {' '}
                                Salvar
                              </button>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  : ''}
              </div>
            </div>
          </Scrollbars>
        </Offcanvas.Body>
      </Offcanvas>

      <ComposicaoModalSave
        showInt={showModal}
        setShowModal={setShowModal}
        layerCurrrent={layerCurrrent}
      ></ComposicaoModalSave>
    </>
  )
}
