import {useContext, useMemo, useState} from 'react'
import {DataContext} from '../../../context/DataContext'
import {FilterContext} from '../../../context/FilterContext'
import {BarChartPorVagaQtde} from './BarChartPorVagaQtde'

type Props = {
  label?: string
  itens: any[]
}

export default function OfertasUnidaesPorAnoDorm({label, itens}: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {data} = useContext(DataContext)
  const {filter} = useContext(FilterContext)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedItem, setSelectedItem] = useState(null)
  let {chartData, groups} = useMemo(() => {
    let ticketMedio = []
    let chartData = []
    let groups = []

    if (data && filter !== null) {
      // eslint-disable-next-line array-callback-return
      data.map((item) => {
        if (!ticketMedio[item.ano_lancamento]) {
          ticketMedio[item.ano_lancamento] = {} // Inicializa o ano se ainda não existir
        }

        // eslint-disable-next-line array-callback-return
        item.tipologias.map((itemTipologias) => {
          if (itemTipologias.computed) {
            if (!ticketMedio[item.ano_lancamento][itemTipologias.bedrooms]) {
              ticketMedio[item.ano_lancamento][itemTipologias.bedrooms] = 0 // Inicializa a contagem se não existir
            }

            ticketMedio[item.ano_lancamento][itemTipologias.bedrooms] += itemTipologias.total_units
          }
        })
      })
      // eslint-disable-next-line array-callback-return
      ticketMedio.map((item, index) => {
        if (Object.entries.length > 0) {
          let count = 0

          // eslint-disable-next-line array-callback-return
          Object.entries(item).map((valor, index2) => {
            if (valor) {
              chartData.push({
                x: '' + valor[0], //Vagas
                y: valor[1],
                dorm: index,
              })

              count++
            }
          })
          groups.push({title: index, cols: count})
        }
      })
      chartData = ordenarPorVagas(chartData)
    }

    return {chartData, groups}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itens, filter])
  function ordenarPorVagas(array) {
    return array.sort((a, b) => a.bedrooms - b.bedrooms)
  }

  if (!itens) {
    return <>Carregando..</>
  }

  return (
    <BarChartPorVagaQtde
      className={'primary'}
      title={'Oferta de unidades por dorm por ano'}
      subtitle={''}
      data={chartData}
      groups={groups}
      onChange={(id: number) => {
        setSelectedItem(id)
      }}
      legenda={['Total Unidades']}
    >
      <table className='table  table-row-dashed table-row-gray-300 table-hover'>
        <thead>
          <tr className='text-dark fw-bold'>
            {/* {chartDataVSOMetragemTable && chartDataVSOMetragemTable.length > 0
              ? Object.entries(chartDataVSOMetragemTable[0]).map((item, index) => {
                  return item[0] !== 'id' ? (
                    <th key={index} className='text-center'>
                      {Dicionario.translate(item[0])}
                    </th>
                  ) : null
                })
              : null} */}
          </tr>
        </thead>
        <tbody>
          {/* {chartDataVSOMetragemTable
            ? chartDataVSOMetragemTable.map((item, index) => {
                return (
                  <tr key={index} className={item.id === selectedItem ? 'select-data-table' : ''}>
                    <td className='text-center'>{item.preco}</td>
                    <td className='text-center'>
                      {formatNumber(item.ticketMedio, 'currency_inteiro')}
                    </td>
                    <td className='text-center'>
                      {formatNumber(item.precoMedioM2, 'currency_inteiro')}
                    </td>
                    <td className='text-center'>{item.totalUnidades}</td>
                  </tr>
                )
              })
            : null} */}
        </tbody>
      </table>
    </BarChartPorVagaQtde>
  )
}
