import {HeaderPerformance} from '../../Common/HeaderPerformance'
import OfertasUnidades from './OfertasUnidades'
import OfertasUnidaesPorAnoDorm from './OfertasUnidaesPorAnoDorm'
// import PosicaoAtualDoEstoque from './PosicaoAtualDoEstoque'
import PrecoMedioM2 from './PrecoMedioM2'
import TickeMedio from './TickeMedio'
// import VendasPorAno from './VendasPorAno'

export function AnaliseTemporal() {
  return (
    <>
      <div className=' d-flex flex-column justify-content-start p-10'>
        <HeaderPerformance titulo={'Análise temporal'}></HeaderPerformance>
        <div className='content pt-5'>
          <div className='row g-5 g-xl-8'>
            <div className='col-xl-6'>
              <div className='card bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance'>
                <OfertasUnidades itens={[]}></OfertasUnidades>
              </div>
            </div>
            <div className='col-xl-6'>
              <div className='card bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance'>
                <OfertasUnidaesPorAnoDorm itens={[]}></OfertasUnidaesPorAnoDorm>
              </div>
            </div>

            <div className='col-xl-6'>
              <div className='card bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance'>
                <TickeMedio itens={[]}></TickeMedio>
                {/* <PrecoMedioUnidades></PrecoMedioUnidades> */}
              </div>
            </div>
            <div className='col-xl-6'>
              <div className='card bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance'>
                <PrecoMedioM2 itens={[]}></PrecoMedioM2>
                {/* <PrecoMedioEstoque></PrecoMedioEstoque> */}
              </div>
            </div>

            {/* <div className='col-xl-6'>
              <div className='card bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance'>
                 <PrecoMedioVendas></PrecoMedioVendas> 
              </div>
            </div>*/}

            {/* <div className='col-xl-6'>
              <div className='card bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance'>
                <h1>5. Posição atual do estoque por ano de lançamento</h1>
                <PosicaoAtualDoEstoque itens={[]}></PosicaoAtualDoEstoque>
              </div>
            </div>
            <div className='col-xl-6'>
              <div className='card bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance'>
                <h1>6. Vendas por ano</h1>
                <VendasPorAno itens={[]}></VendasPorAno>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  )
}
