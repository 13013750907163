import {useEffect, useState} from 'react'
import {ModalServices} from './ModalServices'

import {BiInfoCircle} from 'react-icons/bi'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useImoveisStore} from '../../../context/DataContext'
import {formatDistancia} from '../../../../explorer/components/common'
import {APiUrbit} from '../../../../../services/api/apiUrbit'
import {formatNumber} from '../../../../../components/util'

type props = {
  onChange: any
}
const api = new APiUrbit()

type Props = {
  info: any
  servico: string
  distancia: number
  nome: string
  resultado: any
  tipo?: string
  key?: string
  format?: (data: any) => any
}[]

export default function CheckServices({onChange}: props) {
  // const [checked, setChecked] = useState([])
  const [resultado, setResultado] = useState<Props>()
  const {endereco, filtro} = useImoveisStore()

  // let geom = geometria
  // const jsonEncoded = btoa(unescape(encodeURIComponent(JSON.stringify(geom))))

  useEffect(() => {
    async function loadservice() {
      const servicos = [
        {
          servico: 'area-vulnerabilidade',
          distancia: 500,
          nome: 'Area de Vulnerabilidade',
          resultado: null,
          tipo: 'qtde',
          info: 'comunidades ou assentamentos precários segundo IBGE ou/e Prefeitura',
        },
        {
          servico: 'metro',
          distancia: 1000,
          nome: 'Metrô',
          resultado: null,
          tipo: 'qtde',
          info: 'Metrô em 1km',
        },
        {
          servico: 'metro-planejado',
          distancia: 1000,
          nome: 'Metrô planejado',
          resultado: null,
          tipo: 'qtde',
          info: 'Estações planejadas de metrô, VLT ou monotrilho',
        },
        {
          servico: 'equipamentos-educacao',
          distancia: 500,
          nome: 'Equipamentos de Educaçaõ',
          resultado: null,
          tipo: 'qtde',
          info: 'Equipamentos de Educação em 1km',
        },
        {
          servico: 'area-alagamento',
          distancia: 500,
          nome: 'Área de alagamento',
          resultado: null,
          tipo: 'qtde',
          info: 'Áreas inundáveis ou Pontos de Alagamentos',
        },
        {
          servico: 'risco-geologico',
          distancia: 500,
          nome: 'Risco Geoloógico',
          resultado: null,
          tipo: 'qtde',
          info: 'Áreas de risco geológico',
        },
        {
          servico: 'shopping',
          distancia: 500,
          nome: 'Shopping',
          resultado: null,
          tipo: 'qtde',
          info: 'Shopping Centers',
        },
        {
          servico: 'renda-mensal-domiciliar',
          distancia: 500,
          nome: 'Renda Mensal Domiciliar',
          resultado: null,
          tipo: 'valor',
          key: 'renda_mensal_domiciliar',
          format: (valor) => {
            return formatNumber(valor, 'currency')
          },
          info: 'Censo IBGE 2010 com valores corrigidos a IPCA.',
        },
        {
          servico: 'renda-domiciliar-intersect',
          distancia: 500,
          nome: 'Domicílios Total',
          resultado: null,
          tipo: 'valor',
          key: 'dom_total',
          format: (valor) => {
            return formatNumber(valor, 'inteiro')
          },
          info: 'Domicílios Total',
        },
        {
          servico: 'renda-domiciliar-intersect',
          distancia: 500,
          nome: 'Domicilios BAIXA Renda',
          resultado: null,
          tipo: 'valor',
          key: 'perc_dom_baixa_renda',
          format: (valor) => {
            return formatNumber(valor, 'inteiro') + '%'
          },
          info: 'Percentual Domicílios BAIXA Renda',
        },
        {
          servico: 'renda-domiciliar-intersect',
          distancia: 500,
          nome: 'Domicílios ALTA Renda',
          resultado: null,
          tipo: 'valor',
          key: 'perc_dom_alta_renda',
          format: (valor) => {
            return formatNumber(valor, 'inteiro') + '%'
          },
          info: 'Percentual Domicílios ALTA Renda',
        },
        // {
        //   servico: 'renda-domiciliar-intersect',
        //   distancia: 500,
        //   nome: 'Potencial Consumo Mensal',
        //   resultado: null,
        //   tipo: 'valor',
        //   key: 'potencial_de_consumo_mensal',
        //   format: (valor) => {
        //     return formatNumber(valor, 'inteiro')
        //   },
        //   info: 'Potencial Consumo',
        // },
        {
          servico: 'indice-verticalizacao',
          distancia: 500,
          nome: 'indice-verticalizacao',
          resultado: null,
          tipo: 'valor',
          key: 'indice_verticalizacao',
          format: (valor) => {
            return formatNumber(valor, 'inteiro') + '%'
          },
          info: 'Número de apartamentos em relação ao total de domicílios',
        },
        {
          servico: 'ivs',
          distancia: 500,
          nome: 'Índice vunerabilidade Social',
          resultado: null,
          tipo: 'valor',
          key: 'ivs',
          format: (valor) => {
            return formatNumber(valor, 'inteiro')
          },
          info: 'Índice de Vulnerabilidade Social. Indica proximidade a aglomerados subnormais, e áreas de conflito, tais como facções, milícias, e outras variáveis sóciodemográficas.',
        },
        {
          servico: 'supply-location-quality-index',
          distancia: 500,
          nome: 'Índice de Liquidez',
          resultado: null,
          tipo: 'valor',
          key: 'index_city',
          format: (valor) => {
            return formatNumber(valor, 'inteiro')
          },
          info: 'Índice de qualidade-oferta local (Liquidez)',
        },
      ]
      let servicosCarregado = []
      // eslint-disable-next-line array-callback-return
      servicos.forEach(async (item, index) => {
        let url = `service/${item.servico}/${endereco.longitude}/${endereco.latitude}?distance=${item.distancia}`
        // &intersect=${jsonEncoded}`
        let result = await api.get(url)

        item.resultado = result
        servicosCarregado.push(item)
      })

      setResultado(servicos)

      return servicos
    }
    loadservice()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtro, endereco])

  const link = '#'
  const Link = ({id, children, title}) => (
    <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
      <a href={link}>{children}</a>
    </OverlayTrigger>
  )
  return (
    <table className='table table-row-dashed table-row-gray-300 gy-2'>
      <thead>
        <tr className='fw-bolder fs-6 text-gray-800'>
          <th>Indicador</th>
          <th className='text-center'>Valor/Qtde</th>
          <th className='text-center'>Lista </th>
        </tr>
      </thead>
      <tbody>
        {resultado ? (
          resultado.map((item, index) => {
            return (
              <tr key={index}>
                <td>
                  <Link id={index} title={item.info}>
                    <BiInfoCircle className='fs-1 me-2'></BiInfoCircle>
                  </Link>
                  {item.nome} ({formatDistancia(item.distancia)})
                </td>
                <td className='w-80px text-center'>
                  {item.resultado ? (
                    item.resultado.itens.length > 0 ? (
                      item.tipo === 'qtde' ? (
                        <span className=' text-right fs-semibold'>{item.resultado.total}</span>
                      ) : (
                        <span className=' text-right fs-semibold'>
                          {item.format(item.resultado.itens[0][item.key])}
                        </span>
                      )
                    ) : (
                      <span className='badge badge-warning'>Não</span>
                    )
                  ) : null}
                </td>
                <td className='text-center'>
                  {item.resultado ? (
                    item.tipo === 'qtde' && item.resultado.itens.length > 0 ? (
                      <ModalServices list={item.resultado.itens}></ModalServices>
                    ) : null
                  ) : null}
                </td>
              </tr>
            )
          })
        ) : (
          <tr>
            <td className='text-center' colSpan={2}>
              {' '}
              <div>Carregando</div>{' '}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}
