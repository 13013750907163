import {type} from 'os'
import {differenceInMonths, differenceInDays, parseISO, isAfter, differenceInYears} from 'date-fns'

export type PropsFormatValor = 'decimal' | 'currency' | 'currency_inteiro' | 'inteiro' | 'default'

export function formatNumber(valor: any, style: PropsFormatValor = 'default') {
  if (style === 'decimal') {
    return Number(valor).toLocaleString('pt-br', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  } else if (style === 'currency_inteiro') {
    return Number(valor).toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
  } else if (style === 'inteiro') {
    return Number(valor).toLocaleString('pt-br', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })
  } else {
    return Number(valor).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})
  }

  //    return  parseFloat(valor).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

export function formatValor(valor) {
  if (valor <= 9999) {
    const valorFormatado = Math.floor(valor / 100) / 10
    return valorFormatado + 'k'
  }
  if (valor >= 10000 && valor <= 99999) {
    const valorFormatado = Math.floor(valor / 1000)
    return valorFormatado + 'k'
  } else if (valor >= 100000 && valor <= 999999) {
    const valorFormatado = Math.floor(valor / 1000)
    return valorFormatado + 'K'
  } else if (valor >= 1000000) {
    const valorFormatado = Math.floor(valor / 100000) / 10
    return valorFormatado + 'M'
  }
}

export function calculateDateDifference(targetDate: string): string {
  const now: Date = new Date()
  const futureDate: Date = parseISO(targetDate)

  if (now >= futureDate) {
    return `A data já passou: ${targetDate}`
  }

  const years: number = differenceInYears(futureDate, now)
  const months: number = differenceInMonths(futureDate, now) % 12
  const days: number = differenceInDays(futureDate, now) % 30

  const parts: string[] = []
  if (years > 0) parts.push(`${years} ano${years > 1 ? 's' : ''}`)
  if (months > 0 || (years > 0 && days > 0)) parts.push(`${months} mês${months > 1 ? 'es' : ''}`)
  if (days > 0 || (years === 0 && months === 0)) parts.push(`${days} dia${days > 1 ? 's' : ''}`)

  return parts.length > 0 ? `Há ${parts.join(', ')}.` : 'Nenhum tempo restante.'
}

export default function clearText(str) {
  if (str !== null) {
    var map = {
      // '-' : '',
      '-': '_',
      a: 'á|à|ã|â|ä|À|Á|Ã|Â|Ä',
      e: 'é|è|ê|ë|É|È|Ê|Ë',
      i: 'í|ì|î|ï|Í|Ì|Î|Ï',
      o: 'ó|ò|ô|õ|ö|Ó|Ò|Ô|Õ|Ö',
      u: 'ú|ù|û|ü|Ú|Ù|Û|Ü',
      c: 'ç|Ç',
      n: 'ñ|Ñ',
      '': "'",
      ' ': ' ',
    }

    for (var pattern in map) {
      str = str.replace(new RegExp(map[pattern], 'g'), pattern)
    }

    return str.toLowerCase()
  } else {
    return ''
  }
}

export function textCidadeToMunicipioUrl(cidade) {
  let text = clearText(cidade)
  return text.replace(new RegExp(' ', 'g'), '-')
}

export const removeColunas = [
  'cor',
  'gid',
  'id',
  'distancia',
  'geom',
  'layerName',
  'centroid',
  'cor',
  'cor_zona',
]
export function removeItensObj(obj) {
  const removeColunas = [
    'cor',
    'gid',
    'id',
    'distancia',
    'geom',
    'layerName',
    'centroid',
    'cor',
    'cor_zona',
  ]

  let index
  let novoObj = {}
  for (var i in obj) {
    index = removeColunas.indexOf(i)
    if (index === -1) {
      novoObj[i] = obj[i]
    }
  }
  return novoObj
}
export const currencyConfig = {
  locale: 'pt-BR',
  formats: {
    number: {
      BRL: {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
}

export const percentualConfig = {
  locale: 'pt-BR',
  formats: {
    number: {
      BRL: {
        style: 'decimal',
        currency: '%',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
}

export function DateTimToView(data: string | Date) {
  if (data) {
    let theDate = new Date(data)

    // Obtenha o fuso horário local em minutos
    const offsetMinutes = theDate.getTimezoneOffset()

    // Adicione o deslocamento para obter a data correta no fuso horário local
    theDate.setMinutes(theDate.getMinutes() - offsetMinutes)

    // Obtenha o dia, mês e ano
    const day = theDate.getDate()
    const month = theDate.getMonth() + 1 // Meses são baseados em zero
    const year = theDate.getFullYear()

    // Formate dia e mês para dois dígitos
    const dayFormatted = day.toString().padStart(2, '0')
    const monthFormatted = month.toString().padStart(2, '0')

    // Retorne a data formatada
    const formattedDate = `${dayFormatted}/${monthFormatted}/${year}`
    return formattedDate
  } else {
    return ''
  }
}

export function CurrencyFormat(value: number) {
  if (value) {
    var ValueFormated = value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    return ValueFormated
  } else {
    return ''
  }
}

export function DecimalFormat(value: number) {
  if (value) {
    var ValueFormated = value.toLocaleString('pt-br', {
      style: 'decimal',
      currency: 'BRL',
      maximumFractionDigits: 2,
    })
    return ValueFormated
  } else {
    return ''
  }
}

export type latLng = {
  lat: number
  lng: number
}

export class LatLng {
  latitude: number
  longitude: number

  constructor(lat: number, lng: number) {
    this.latitude = lat
    this.longitude = lng
  }
  lat(): number {
    return this.latitude
  }
  lng(): number {
    return this.longitude
  }
}

export function b64DecodeUnicode(str) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    atob(str)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )
}

export function validarCPF(cpf) {
  cpf = cpf.replace(/[^\d]/g, '') // Remove caracteres não numéricos
  if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false // Verifica se o CPF tem 11 dígitos e se não é uma sequência repetida

  let soma = 0
  for (let i = 0; i < 9; i++) soma += parseInt(cpf.charAt(i)) * (10 - i)
  let resto = soma % 11
  let digitoVerificador1 = resto < 2 ? 0 : 11 - resto
  if (digitoVerificador1 !== parseInt(cpf.charAt(9))) return false

  soma = 0
  for (let i = 0; i < 10; i++) soma += parseInt(cpf.charAt(i)) * (11 - i)
  resto = soma % 11
  let digitoVerificador2 = resto < 2 ? 0 : 11 - resto
  if (digitoVerificador2 !== parseInt(cpf.charAt(10))) return false

  return true
}

export function validarCNPJ(cnpj) {
  cnpj = cnpj.replace(/[^\d]/g, '') // Remove caracteres não numéricos
  if (cnpj.length !== 14 || /^(\d)\1{13}$/.test(cnpj)) return false // Verifica se o CNPJ tem 14 dígitos e se não é uma sequência repetida

  let soma = 0
  let peso = 2
  let digitoVerificador1 = parseInt(cnpj.charAt(12))
  let digitoVerificador2 = parseInt(cnpj.charAt(13))

  // Verificação do primeiro dígito
  for (let i = 11; i >= 0; i--) {
    soma += parseInt(cnpj.charAt(i)) * peso
    peso = peso === 9 ? 2 : peso + 1
  }

  let resto = soma % 11
  let resultado = resto < 2 ? 0 : 11 - resto
  if (resultado !== digitoVerificador1) return false

  // Verificação do segundo dígito
  soma = 0
  peso = 2
  for (let i = 12; i >= 0; i--) {
    soma += parseInt(cnpj.charAt(i)) * peso
    peso = peso === 9 ? 2 : peso + 1
  }

  resto = soma % 11
  resultado = resto < 2 ? 0 : 11 - resto
  if (resultado !== digitoVerificador2) return false

  return true
}

export const marcadoresMap = [
  'A1',
  'B1',
  'C1',
  'D1',
  'E1',
  'F1',
  'G1',
  'H1',
  'I1',
  'J1',
  'K1',
  'L1',
  'M1',
  'N1',
  'O1',
  'P1',
  'Q1',
  'R1',
  'S1',
  'T1',
  'U1',
  'V1',
  'W1',
  'Y1',
  'Z1',
  'A2',
  'B2',
  'C2',
  'D2',
  'E2',
  'F2',
  'G2',
  'H2',
  'I2',
  'J2',
  'K2',
  'L2',
  'M2',
  'N2',
  'O2',
  'P2',
  'Q2',
  'R2',
  'S2',
  'T2',
  'U2',
  'V2',
  'W2',
  'Y2',
  'Z2',
  'A3',
  'B3',
  'C3',
  'D3',
  'E3',
  'F3',
  'G3',
  'H3',
  'I3',
  'J3',
  'K3',
  'L3',
  'M3',
  'N3',
  'O3',
  'P3',
  'Q3',
  'R3',
  'S3',
  'T3',
  'U3',
  'V3',
  'W3',
  'Y3',
  'Z3',
]

export function decodeUrl(text: string) {
  return atob(text)
}
export function encodeUrl(text: string) {
  return btoa(unescape(encodeURIComponent(text)))
}

export function DifferenceDate(dataEspecifica: string | Date) {
  const dataAtual = new Date()
  const dataEspecificaDate =
    typeof dataEspecifica === 'string' ? parseISO(dataEspecifica) : dataEspecifica
  const diff = differenceInDays(dataEspecificaDate, dataAtual)

  if (isAfter(dataEspecificaDate, dataAtual)) {
    return `Faltam ${diff} dias.`
  } else {
    return `A data de termino já passou.`
  }
}

export function formatPhone(number) {
  return number
    .toString()
    .replace(/^\s*(\d{2}|\d{0})[-. ]?(\d{5}|\d{4})[-. ]?(\d{4})[-. ]?\s*$/, '($1) $2-$3')
}
