import {useMemo} from 'react'
import {BarChart} from './BarChart'
import {average} from 'simple-statistics'
import {Imoveis} from '../../types'

type Props = {
  label?: string
  itens: Imoveis[]
}

export default function TicketMedio({label, itens}: Props) {
  let {chartDataVSOMetragem} = useMemo(() => {
    let precoMedioM2 = Array.from({length: 15}, (e) => [])
    let totalUnidades = Array(15).fill(0, 0, 15)

    let chartDataVSOMetragem = [
      {
        name: 'Ticket R$',
        data: [],
      },
    ]
    // eslint-disable-next-line array-callback-return
    itens.map((item) => {
      let i = item.quartos
      if (i <= 7) {
        totalUnidades[i] = totalUnidades[i] + 1
        precoMedioM2[i].push(Number(item.valor))
      }
    })

    // eslint-disable-next-line array-callback-return
    totalUnidades.map((item, key) => {
      chartDataVSOMetragem[0].data.push({
        x: key + ' Dorm.',
        y: precoMedioM2[key].length > 0 ? average(precoMedioM2[key]).toFixed(2) : 0,
        total_unidades: item,
        id: key,
      })
    })

    return {chartDataVSOMetragem}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itens])
  return (
    <>
      <BarChart
        data={chartDataVSOMetragem}
        className={''}
        title={'Ticket médio por dormitórios'}
        subtitle={''}
        onChange={() => {}}
        legenda={['Preço médio m²']}
      ></BarChart>
      {/* </div>
      <div className='col-6'>Map</div> */}
    </>
  )
}
