import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {useThemeMode} from '../../../../../_metronic/partials'
import {getCSS} from '../../../../../_metronic/assets/ts/_utils'
import {formatNumber} from '../../../../components/util'

type Props = {
  className: string
  title: string
  subtitle: string
  legenda: [string]
  tooltip?: string
  data: any
  onChange: any
  groups: any
}

export const BarChartPorVagaQtde: React.FC<Props> = ({
  title,
  subtitle,
  data,
  groups,
  className,
  legenda = ['Qtde amostras.'],
  onChange,
  children,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }
    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(data, height, onChange, legenda, groups)
    )

    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, chartRef, mode])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>

          <span className='text-muted fw-semibold fs-7'>{subtitle}</span>
        </h3>
        <div className='card-toolbar' data-kt-buttons='true'>
          {/* {filtroDomitorio ? <SelectDormitorios onChange={onChange}></SelectDormitorios> : <></>} */}
        </div>
      </div>

      <div className='card-body'>
        <div ref={chartRef} id='kt_charts_widget_5_chart' style={{height: '350px'}}></div>
        <div>{children}</div>
      </div>
    </div>
  )
}

function getChartOptions(
  data: [],
  height: number,
  onChange: any,
  legenda,
  groups: any
): ApexOptions {
  return {
    series: [
      {
        name: 'Qtde.',
        data: data,
      },
    ],
    chart: {
      type: 'bar',
      height: 380,
    },
    colors: ['#a7a7a7'],
    yaxis: {
      labels: {
        formatter: function (val) {
          return formatNumber(Number(val).toFixed(0), 'inteiro')
        },
      },
    },
    xaxis: {
      type: 'category',
      labels: {
        formatter: function (val) {
          return formatNumber(Number(val).toFixed(0), 'inteiro') + ' Vaga(s)'
        },
      },
      group: {
        style: {
          fontSize: '10px',
          fontWeight: 700,
        },
        groups: groups,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return formatNumber(Number(val).toFixed(0), 'inteiro')
      },
      offsetY: 0,
      style: {
        fontSize: '12px',
        colors: ['#304758'],
      },
    },
    tooltip: {
      x: {
        formatter: function (val) {
          return formatNumber(Number(val).toFixed(0), 'inteiro')
        },
      },
    },
  }
}
