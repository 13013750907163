import {gql} from '@apollo/client'

// Criação de um empreendimento com campos e com ID do usuario
export const SET_EMPREENDIMENTO = gql`
  mutation SetEmpreendimentos($empreendimentos: empreendimentos!) {
    SetEmpreendimentos(empreendimentos: $empreendimentos) {
      id
      nome
    }
  }
`
// Criação de um empreendimento com campos e com ID do usuario
export const CREATE_EMPREENDIMENTOS_IMOVEIS = gql`
  mutation SetEmpreendimentosImovel($empreendimentos: empreendimentos!, $idCorretor: Float) {
    SetEmpreendimentosImovel(empreendimentos: $empreendimentos, id_corretor: $idCorretor) {
      id
      nome
      uuid
      empreendimento_terreno {
        id
        nome
        uuid
      }
    }
  }
`

// Deletar um empreendimento pelo ID
export const DELETE_EMPREENDIMENTO = gql`
  mutation DeleteEmpreendimento($deleteEmpreendimentoId: Float!, $motivo: String!) {
    DeleteEmpreendimento(id: $deleteEmpreendimentoId, motivo: $motivo) {
      nome
    }
  }
`
// Deletar um empreendimento pelo ID
export const DESBLOQUEAR_EMPREENDIMENTO = gql`
  mutation DesbloquearEmpreendimento($desbloquearEmpreendimentoId: Float!) {
    DesbloquearEmpreendimento(id: $desbloquearEmpreendimentoId) {
      id
      endereco
      nome
    }
  }
`
// Deletar um empreendimento pelo ID
export const DESBLOQUEAR_EXCECAO_EMPREENDIMENTO = gql`
  mutation DesbloquearExcecaoEmpreendimento(
    $situacao: Float!
    $usuario: Float!
    $desbloquearExcecaoEmpreendimentoId: Float!
  ) {
    DesbloquearExcecaoEmpreendimento(
      situacao: $situacao
      usuario: $usuario
      id: $desbloquearExcecaoEmpreendimentoId
    ) {
      id
      nome
    }
  }
`

// Deletar um empreendimento pelo ID
export const REATIVAR_EMPREENDIMENTO = gql`
  mutation ReativarEmpreendimento($situacao: Float!, $reativarEmpreendimentoId: Float!) {
    ReativarEmpreendimento(situacao: $situacao, id: $reativarEmpreendimentoId) {
      id
      nome
    }
  }
`

// Ediar um empreendimento
export const PUT_EMPREENDIMENTO_CHANGE_USER = gql`
  mutation PutEmpreendimentoChangeUsuario(
    $idUsuario: Float!
    $idEmpreendimento: Float!
    $idUsuarioOld: Float!
  ) {
    PutEmpreendimentoChangeUsuario(
      id_usuario: $idUsuario
      id_empreendimento: $idEmpreendimento
      id_usuario_old: $idUsuarioOld
    ) {
      id
    }
  }
`
// Ediar um empreendimento
export const PUT_EMPREENDIMENTO = gql`
  mutation PutEmpreendimento($empreendimentos: empreendimentos!) {
    PutEmpreendimento(empreendimentos: $empreendimentos) {
      nome
      id
    }
  }
`

export const GET_EMPREENDIMENTOS_RELATORIO = gql`
  query GetEmpreendimentosRelatorio($filtro: filter) {
    GetEmpreendimentosRelatorio(filtro: $filtro) {
      areaTotalTerreno
      areaTotalPrivativa
      totalEmpreendimentos
      totalTerrenos
      totalMediaTerrenosEmpreendimentos
      vgv
      vgvMedio
    }
  }
`

export const GET_EMPREENDIMENTOS_RELATORIO_STATUS = gql`
  query GetEmpreendimentosRelatorioStatusEmpreendimento(
    $situation: Float
    $pagination: Pagination
  ) {
    GetEmpreendimentosRelatorioStatusEmpreendimento(
      situation: $situation
      pagination: $pagination
    ) {
      equipe
      qtde
      status
      id_status
      id_equipe
    }
  }
`

export const GET_EMPREENDIMENTOS_RELATORIO_TEMPERATURA = gql`
  query GetEmpreendimentosRelatorioTemperatura($pagination: Pagination, $situation: Float) {
    GetEmpreendimentosRelatorioTemperatura(pagination: $pagination, situation: $situation) {
      equipe
      id_equipe
      id_temperatura
      qtde
      status
    }
  }
`
