import {useRef, useState} from 'react'
import {Endereco} from '../../ComponentsControl/EnderecoControl/endereco'
import {createTile} from '../../Layer/Layers/GoogleMapsTile'
import {Nav} from 'react-bootstrap'
import uuid from 'react-uuid'
import {Explorer} from '../../../Map/Explorer'
import {Layout} from '../../../Map/Layout'
import secureLocalStorage from 'react-secure-storage'
import {useMapaConfig} from '../../../../../../../hooks/useMapaConfig'

export const TerrenosBtn: React.FC = () => {
  const [activeLayer, setActiveLayer] = useState(null)
  const layerActive = useRef(null)
  const municipio = Endereco.getMunicipio()
  const [tipoForm, setTipoForm] = useState('')
  const {mapaConfig} = useMapaConfig()
  if (!municipio) {
    return <></>
  }

  if (municipio.lote_fiscal !== 1) {
    return <></>
  }

  const idEmpresa = secureLocalStorage.getItem('uuid')

  if (
    idEmpresa !== 'd7397efd-1461-11ee-adfa-2618765f0467' &&
    idEmpresa !== '9d4c4046-8d59-449e-83ac-dfd66c983e9b' &&
    idEmpresa !== 'e5e0020a-b002-4ac0-b516-34d2270817ca' &&
    idEmpresa !== '97a7a864-1c76-478c-aa17-056810a39514' &&
    idEmpresa !== 'f1c7344e-1553-11ee-adfa-2618765f0467' &&
    idEmpresa !== 'f1574c14-1553-11ee-adfa-2618765f0467' &&
    idEmpresa !== 'f1ae626b-1553-11ee-adfa-2618765f0467' &&
    idEmpresa !== 'f1ba9b9d-1553-11ee-adfa-2618765f0467' &&
    idEmpresa !== 'f1d5b059-1553-11ee-adfa-2618765f0467' &&
    idEmpresa !== 'f1e39cf3-1553-11ee-adfa-2618765f0467' &&
    idEmpresa !== 'f1f0836b-1553-11ee-adfa-2618765f0467' &&
    idEmpresa !== 'f21793b4-1553-11ee-adfa-2618765f0467' &&
    idEmpresa !== '6cf0b159-3e0b-11ee-adfa-2618765f0467' &&
    idEmpresa !== 'fd9a5dab-3e0b-11ee-adfa-2618765f0467' &&
    idEmpresa !== 'fd9eec95-3e0b-11ee-adfa-2618765f0467' &&
    idEmpresa !== 'fda6efa3-3e0b-11ee-adfa-2618765f0467' &&
    idEmpresa !== 'fdab6c4b-3e0b-11ee-adfa-2618765f0467' &&
    idEmpresa !== 'fdb36f9c-3e0b-11ee-adfa-2618765f0467' &&
    idEmpresa !== 'fdb714c1-3e0b-11ee-adfa-2618765f0467' &&
    idEmpresa !== 'fdbe3907-3e0b-11ee-adfa-2618765f0467' &&
    idEmpresa !== 'fdd0f22d-3e0b-11ee-adfa-2618765f0467' &&
    idEmpresa !== 'fdf827da-3e0b-11ee-adfa-2618765f0467' &&
    idEmpresa !== '06c15c8d-3e0c-11ee-adfa-2618765f0467' &&
    idEmpresa !== '5bb5ff16-3e20-11ee-adfa-2618765f0467' &&
    idEmpresa !== 'b8fca759-3e20-11ee-adfa-2618765f0467' &&
    idEmpresa !== 'ff58e1bd-3e28-11ee-adfa-2618765f0467' &&
    idEmpresa !== '1443a2c6-50e0-11ee-b708-2618765f0467' &&
    idEmpresa !== '11b249c6-6b2b-4608-9824-d4c62c76a85b' &&
    idEmpresa !== 'c7b0a578-fdba-4525-95e8-686020dcf707' &&
    idEmpresa !== '609c7933-b6ca-4bec-a58d-c6dcbdf08118' &&
    idEmpresa !== 'db25d083-1d13-4f1a-ae59-2198683b16b8' &&
    idEmpresa !== 'caf24c34-bc25-4138-b7b4-e67668f75e9f' &&
    idEmpresa !== '0293a2b6-c97b-403a-8111-909b4c429ed6' &&
    idEmpresa !== 'ceb6fd45-7ccd-4215-a4c6-706846a432ec' &&
    idEmpresa !== '23a097fb-04ed-418a-bb31-48c865ad915d' &&
    idEmpresa !== '3957ef5d-27d5-4599-8977-fac6478b456c' &&
    idEmpresa !== 'e07ce767-98ca-4a5a-b0da-e199750f29f8' &&
    idEmpresa !== '5831249c-ab2d-11ef-a725-2618765f0467'
  ) {
    return <></>
  }
  function lotesEmpreendimento() {
    const municipio = Endereco.getMunicipio()
    let municipiourl = municipio.municipio_url.replace(/-/gm, '_')

    if (municipiourl === 'florianopolis') {
      municipiourl = 'sc_florianopolis'
    }

    var loteFiscalConfig = {
      descricao: 'Terrenos',
      // zoom_maximo: 15,
      url: idEmpresa,
      tile: `https://empreendimentos.urbit.com.br/tile/${idEmpresa}/{z}/{x}/{y}.pbf`,
      id: 13,
      layout: '',
      label: 'cd_numero_',
      nacional: null,
      nome: 'Terrenos',
      nota_tecnica: null,
      paint: {'fill-color': ['get', 'cor'], 'fill-opacity': 0.4},
      // {
      //   'fill-color': '#9393fd',
      //   'line-width': 1,
      //   'fill-outline-color': '#fff',
      //   'line-color': '#fdfdff',
      //   'text-color': '#333',
      // },
      schema_table: municipiourl,
      source_layer: idEmpresa,
      source: idEmpresa,
      type: 'MVTLayer',
      descricao_completa: '',
      selectedPolygon: [],
      explorer_layer_tags: [],
      explorer_tiles: [
        {
          __typename: 'ExplorerTilesModel',
          id: 156,
          id_layer: '13',
          key_column: null,
          label: null,
          id_column: null,
          uuid: '7f4d8206-292e-11ed-9c4a-ea033c27ec52',
          type_data: 'text',
          table_name: 'lote_fiscal',
          select_columns: 'endereco',
          step: null,
          table_column: 'endereco',
          description: 'endereco',
          data_return: null,
          situacao: '1',
        },
        {
          __typename: 'ExplorerTilesModel',
          id: 157,
          id_layer: '13',
          key_column: null,
          label: null,
          id_column: null,
          uuid: '7f4d833a-292e-11ed-9c4a-ea033c27ec52',
          type_data: 'text',
          table_name: 'lote_fiscal',
          select_columns: 'numero',
          step: null,
          table_column: 'numero',
          description: 'Número',
          data_return: null,
          situacao: '1',
        },
        {
          __typename: 'ExplorerTilesModel',
          id: 162,
          id_layer: '13',
          key_column: null,
          label: null,
          id_column: null,
          uuid: '7f4d87fd-292e-11ed-9c4a-ea033c27ec52',
          type_data: 'range',
          table_name: 'lote_fiscal',
          select_columns: 'area_iptu',
          step: '50',
          table_column: 'area_iptu',
          description: 'Área de terreno',
          data_return:
            '[\r\n    {\r\n        "minimo": "0",\r\n        "maximo": "50000"\r\n    }\r\n]',
          situacao: '1',
        },
        {
          __typename: 'ExplorerTilesModel',
          id: 163,
          id_layer: '13',
          key_column: null,
          label: null,
          id_column: null,
          uuid: '7f4d88f2-292e-11ed-9c4a-ea033c27ec52',
          type_data: 'select',
          table_name: 'lote_fiscal',
          select_columns: 'situacao',
          table_column: 'situacao',
          description: 'Situação',
          data_return:
            '[\r\n"Prospecção Ativa",\r\n"Negociação",\r\n"Stand By",\r\n"Descartado",\r\n"Landbank"\r\n ]',
          situacao: '1',
        },
        {
          __typename: 'ExplorerTilesModel',
          id: 168,
          id_layer: '13',
          key_column: null,
          label: null,
          id_column: null,
          uuid: '7f4d8e11-292e-11ed-9c4a-ea033c27ec52',
          type_data: 'text',
          table_name: 'lote_fiscal',
          select_columns: 'setor_quadra',
          step: null,
          table_column: 'setor_quadra',
          description: 'Setor-Quadra',
          data_return: null,
          situacao: '1',
        },
        {
          __typename: 'ExplorerTilesModel',
          id: 171,
          id_layer: '13',
          key_column: null,
          label: null,
          id_column: null,
          uuid: '7f4d90d7-292e-11ed-9c4a-ea033c27ec52',
          type_data: 'text',
          table_name: 'lote_fiscal',
          select_columns: 'numero_contribuinte',
          step: null,
          table_column: 'numero_contribuinte',
          description: 'Número do Contribuinte',
          data_return: null,
          situacao: '1',
        },
        {
          __typename: 'ExplorerTilesModel',
          id: 171,
          id_layer: '13',
          key_column: null,
          label: null,
          id_column: null,
          uuid: '7f4d90d7-292e-11ed-9c4a-ea033c27ec52',
          type_data: 'text',
          table_name: 'lote_fiscal',
          select_columns: 'usuario',
          step: null,
          table_column: 'usuario',
          description: 'Usuário',
          data_return: null,
          situacao: '1',
        },
        {
          __typename: 'ExplorerTilesModel',
          id: 156,
          id_layer: '13',
          key_column: null,
          label: null,
          id_column: null,
          uuid: '7f4d8206-292e-11ed-9c4a-ea033c27ec52',
          type_data: 'text',
          table_name: 'v',
          select_columns: 'endereco',
          step: null,
          table_column: 'motivo_descarte',
          description: 'Motivo Descarte',
          data_return: null,
          situacao: '1',
        },
      ],
      visible: true,
      composite: '',
    }

    let layer = {...loteFiscalConfig}
    layer['uuid'] = uuid()
    layer['visible'] = true
    layer['tile'] = `https://empreendimentos.urbit.com.br/tile/${idEmpresa}/{z}/{x}/{y}.pbf`
    layer['paint'] = {...loteFiscalConfig.paint}
    console.log('layer')
    console.log(layer)
    return layer
  }
  function onClick(item) {
    if (item.target.checked) {
      let layer = lotesEmpreendimento()
      const layerId = Explorer.addLayerTile(layer, 'Empreendimentos')
      layerActive.current = layerId
      secureLocalStorage.setItem('layerComp', layerId)
      // Layout.setModoComposicao(true)
      // Layout.setMapaConfig(mapaConfig)
      setTipoForm('composicao')
      setActiveLayer(layerId)
    } else {
      Explorer.removeLayer(layerActive.current)
      // activeLayer.remove()
    }
  }
  return (
    <>
      <Nav className=' ms-3 my-2 my-lg-0  ' style={{maxHeight: '100px'}}>
        <div className='card pt-1 '>
          <div className='menu-link d-flex'>
            <span className='menu-icon- mx-2'>Terrenos</span>
            <span className='menu-title'>
              <div className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input h-20px w-30px'
                  type='checkbox'
                  value={0}
                  id='flexSwitchDefault'
                  onClick={onClick}
                />
              </div>
            </span>
          </div>
        </div>
      </Nav>
    </>
  )
}
