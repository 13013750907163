import React, {useState} from 'react'
import {AsideDefault} from '../../../_metronic/layout/components/aside/AsideDefault'
import '../global.css'
import {Footer} from '../../../_metronic/layout/components/Footer'
import {HeaderWrapperFluid} from '../../../_metronic/layout/components/header/HeaderWrapperFluid'
import {Table} from './partials/Table'
import {KTSVG} from '../../../_metronic/helpers'
import {Form, FormLabel} from 'react-bootstrap'
import {GetEmpreendimentosTagsChosen} from '../../components/UI/Chosens/GetEmpreendimentoTags'
import {GetEmpreendimentoSituacaoChosen} from '../../components/UI/Chosens/GetEmpreedimentoSituacao'
import {GetCorretoresChosen} from '../../components/UI/Chosens/GetCorretoresChosen'
import {TemperatureBar} from '../../components/UI/Bars/TemperatureBar'
import {GetUsuariosEquipesByCargoChosen} from '../../components/UI/Chosens/GetUsuariosEquipesByCargo'
import {GetAcessoUsuarioProduto, isDiretor} from '../../utils/PermissoesAcesso'
import {MensagemAlertAcesso} from '../../components/UI/MensagemAlertAcesso'
import {GetEquipeChosen} from '../../components/UI/Chosens/GetEquipeChosen'
type Props = {
  id_corretor?: any
  id_equipe?: any
  zoneamento?: any
}
export function Empreendimento() {
  const [toogleFilter, setToogleFilter] = useState(true)
  const [descart, setDescart] = React.useState(false)
  const [bloqueados, setBloqueados] = React.useState(false)
  const [excecaoQuadra, setExcecaoQuadra] = React.useState(false)
  const [filter, setFilter] = useState([])
  const [objectFilter, setObjectFilter] = useState<Props>({
    id_corretor: null,
    id_equipe: null,
    zoneamento: null,
  })
  // const [orderBy, setOrderBy] = useState({fields: []})
  const [filtroValores, setFiltroValores] = useState({
    id_tag: null,
    id_situacao: null,
    id_corretor: null,
    id_temperatura: null,
    id_usuario: null,
    id_equipe: [],
    zoneamento: null,
  })
  function addFilter(e) {
    e.preventDefault()
    let changeValue = {...objectFilter}
    const data = new FormData(e.target)
    const filterArray = []

    if (data.get('nome')) {
      filterArray.push({
        field: ['nome', 'endereco'],
        operator: 'LIKE',
        value: data.get('nome'),
      })
    }
    console.log('filtroValores.zoneamento')
    console.log(filtroValores.zoneamento)
    console.log(data.get('zoneamento'))
    if (!data.get('zoneamento')) {
      changeValue.zoneamento = null
    } else {
      changeValue.zoneamento = {
        objectFilter: {
          empreendimento_terreno: {
            some: {
              terreno: {
                zoneamento: data.get('zoneamento'),
              },
            },
          },
        },
      }
    }

    if (data.get('id_tag')) {
      filterArray.push({
        field: ['tag'],
        operator: 'LIKE',
        value: data.get('id_tag'),
      })
    }

    if (data.get('id_situacao')) {
      filterArray.push({
        field: ['situacao_id'],
        operator: 'IN',
        // value: [filtroValores.id_situacao + ''],
        value: filtroValores.id_situacao.map((item: string) => item + ''),
      })
    }

    if (excecaoQuadra && bloqueados) {
      filterArray.push({
        field: ['bloqueado'],
        operator: 'in',
        value: [1 + '', 2 + ''],
      })
      // setDescart(true)
    } else if (excecaoQuadra || bloqueados) {
      if (bloqueados) {
        filterArray.push({
          field: ['bloqueado'],
          operator: '=',
          value: [1 + ''],
        })
      }

      if (excecaoQuadra) {
        filterArray.push({
          field: ['bloqueado'],
          operator: '=',
          value: [2 + ''],
        })
      }
      // setDescart(true)
    } else {
      setDescart(false)
    }

    if (!filtroValores.id_equipe || filtroValores.id_equipe.length === 0) {
      changeValue.id_equipe = null
    } else {
      changeValue.id_equipe = {
        objectFilter: {
          users: {
            empresa_usuario_equipe: {
              some: {
                id_equipe: {
                  in: filtroValores.id_equipe,
                },
                situacao: 1,
              },
            },
          },
        },
      }
    }

    if (data.get('id_corretor') === '') {
      changeValue.id_corretor = null
    } else {
      changeValue.id_corretor = {
        objectFilter: {
          empreendimento_terreno: {
            some: {
              terreno: {
                terreno_corretor: {
                  some: {
                    corretor: {
                      id: Number(data.get('id_corretor')),
                      situacao: 1,
                    },
                    situacao: 1,
                  },
                },
              },
            },
          },
        },
      }
    }

    if (data.get('id_temperatura')) {
      if (data.get('id_temperatura') !== 'null') {
        filterArray.push({
          field: ['id_temperatura'],
          operator: 'in',
          value: [filtroValores.id_temperatura + ''],
        })
      }
    }

    if (data.get('id_usuario')) {
      if (data.get('id_usuario') !== 'null') {
        filterArray.push({
          field: ['user_id'],
          operator: '=',
          value: data.get('id_usuario'),
        })
      }
    }
    console.log(changeValue)
    setObjectFilter({...changeValue})
    setFilter([...filterArray])
    return false
  }

  function toggleTeste() {
    if (toogleFilter === false) {
      setToogleFilter(true)
    } else if (toogleFilter === true) {
      setToogleFilter(false)
    }
  }

  function limparForm() {
    setFiltroValores({
      id_tag: '',
      id_situacao: null,
      id_corretor: null,
      id_temperatura: '',
      id_usuario: null,
      id_equipe: [],
      zoneamento: null,
    })

    setFilter([])
    setObjectFilter({
      id_corretor: null,
      id_equipe: null,
      zoneamento: null,
    })
  }

  // let href = '#'
  const permissao = GetAcessoUsuarioProduto(28)
  return (
    <>
      <AsideDefault />
      <HeaderWrapperFluid title='Empreendimentos' />

      {permissao ? (
        <div className='wrapper d-flex flex-column flex-column-fluid' id='kt_wrapper'>
          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              {/* <HeaderPage /> */}
              <div id='kt_content_container' className='container-fluid'>
                <div
                  className='py-6  d-flex align-items-center justify-content-between'
                  id='kt_header_container'
                >
                  <div
                    className='page-title d-flex flex-column align-items-start justify-content-center flex-wrap mt-n5 mt-lg-0 me-lg-2 pb-2 pb-lg-0'
                    // data-kt-swapper='true'
                    // data-kt-swapper-mode='prepend'
                    // data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}"
                  >
                    <h1 className='text-dark fw-bold my-0 fs-2'>Listagem</h1>
                    <ul className='breadcrumb fw-semibold fs-base my-1'>
                      <li className='breadcrumb-item text-muted'>
                        <a href='/dashboard' className='text-muted'>
                          Dashboard
                        </a>
                      </li>
                      <li className='breadcrumb-item text-muted'>Empreendimentos</li>
                    </ul>
                  </div>
                  <div className='d-flex align-items-center ms-3'>
                    <button
                      className='me-4 btn btn-sm btn-flex btn-light btn-secondary'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                      onClick={toggleTeste}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen031.svg'
                        className='svg-icon-5 svg-icon-gray-500 me-1'
                      />
                      Filtros
                    </button>
                  </div>
                </div>
                <div className='content  d-flex flex-column flex-column-fluid '>
                  <div className={toogleFilter === true ? 'card show mb-10' : ' card hide mb-10'}>
                    <div className='border-0 pt-6 ps-5 pe-5'>
                      <div className='row gy-5 gx-xl-8'>
                        <div className='col-xl-12'>
                          <div className='card-xxl-stretch '>
                            <div className='card-form px-12 py-12 mb-6'>
                              <Form className=' form form-horizontal' onSubmit={addFilter}>
                                <div className='row'>
                                  <div className='col-md-4  col-sm-4 mb-2'>
                                    <FormLabel className='form-label'>Nome/Endereço</FormLabel>
                                    <input
                                      type='text'
                                      className='form-control'
                                      placeholder='Digite o nome ou Endereço'
                                      name='nome'
                                    />
                                  </div>
                                  {/* <div className='col-md-4 mb-2'>
                                  <FormLabel className='form-label'>Situação</FormLabel>
                                  <input
                                    type='text'
                                    className='form-control'
                                    placeholder='name@example.com'
                                  />
                                </div> */}
                                  <div className='col-md-4 col-sm-4 mb-2'>
                                    <FormLabel className='form-label'>Zoneamento</FormLabel>
                                    <input
                                      type='text'
                                      className='form-control'
                                      placeholder='Zoneamento'
                                      name='zoneamento'
                                      onChange={filtroValores.zoneamento}
                                    />
                                  </div>

                                  <div className='col-md-4 col-sm-4 mb-2'>
                                    <GetEmpreendimentoSituacaoChosen
                                      SetValueSituacao={(value) => {
                                        setFiltroValores({
                                          ...filtroValores,
                                          ...{id_situacao: value},
                                        })
                                      }}
                                      isMulti
                                      valueSituacao={filtroValores.id_situacao || null}
                                    ></GetEmpreendimentoSituacaoChosen>
                                  </div>
                                </div>

                                <div className='row pt-2'>
                                  <div className='col-md-4  col-sm-4 mb-2'>
                                    <GetCorretoresChosen
                                      SetValueCorretor={(value) => {
                                        setFiltroValores({
                                          ...filtroValores,
                                          ...{id_corretor: value},
                                        })

                                        // console.log(value)
                                      }}
                                      valueCorretor={filtroValores.id_corretor || ''}
                                    ></GetCorretoresChosen>
                                  </div>
                                  <div className='col-md-4  col-sm-4 mb-2'>
                                    <FormLabel className='form-label'>Tags</FormLabel>
                                    <GetEmpreendimentosTagsChosen
                                      defaultValue={filtroValores.id_tag || ''}
                                      onChange={(value) => {
                                        setFiltroValores({...filtroValores, ...{id_tag: value}})
                                      }}
                                    ></GetEmpreendimentosTagsChosen>
                                  </div>

                                  <div className='col-md-4  col-sm-4 '>
                                    <FormLabel className='form-label'>Descartados</FormLabel>
                                    <div className='form-check pt-2'>
                                      <label className='form-check-label'>
                                        <input
                                          className='form-check-input'
                                          // checked={check}
                                          onChange={(e) => {
                                            // console.log(e.target.checked)
                                            setDescart(e.target.checked)
                                          }}
                                          value='4'
                                          type='checkbox'
                                          id='gridCheck'
                                        />
                                        Exibir Descartados
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className='row pt-2'>
                                  <div className='col-md-4  col-sm-4 mb-2'>
                                    {/* <FormLabel className='form-label align-itens'>Usuário</FormLabel> */}
                                    <GetUsuariosEquipesByCargoChosen
                                      SetValue={(value) => {
                                        setFiltroValores({
                                          ...filtroValores,
                                          ...{id_usuario: Number(value)},
                                        })

                                        // changeUser(response.id ,Number(value),Number(response.users.id))
                                        // setValue('situacao_id', value)
                                      }}
                                      value={filtroValores.id_usuario + '' || null}
                                      showLabel={true}
                                    ></GetUsuariosEquipesByCargoChosen>
                                  </div>
                                  <div className='col-md-4  col-sm-4 mb-2'>
                                    <GetEquipeChosen
                                      SetValueEquipe={(value) => {
                                        setFiltroValores({
                                          ...filtroValores,
                                          ...{id_equipe: value},
                                        })
                                      }}
                                      valueEquipe={filtroValores.id_equipe + '' || null}
                                    ></GetEquipeChosen>
                                  </div>
                                  <div className='col-md-4  col-sm-4 mb-2'>
                                    <TemperatureBar
                                      SetValueSituacao={(value) => {
                                        setFiltroValores({
                                          ...filtroValores,
                                          ...{id_temperatura: value},
                                        })

                                        // setValue('id_temperatura', value)
                                      }}
                                      valueSituacao={filtroValores.id_temperatura + '' || null}
                                      showLabel={true}
                                    />
                                  </div>
                                </div>

                                {isDiretor() ? (
                                  <div className='row pt-2'>
                                    <div className='col-md-4  col-sm-4 '>
                                      <FormLabel className='form-label'>Bloquados</FormLabel>
                                      <div className='form-check pt-2'>
                                        <label className='form-check-label'>
                                          <input
                                            className='form-check-input'
                                            // checked={check}
                                            onChange={(e) => {
                                              // console.log(e.target.checked)
                                              setBloqueados(e.target.checked)
                                            }}
                                            value='4'
                                            type='checkbox'
                                            id='gridCheck'
                                          />
                                          Exibir Bloqueados
                                        </label>
                                      </div>
                                    </div>
                                    <div className='col-md-4  col-sm-4 '>
                                      <FormLabel className='form-label'>Exceção Quadra</FormLabel>
                                      <div className='form-check pt-2'>
                                        <label className='form-check-label'>
                                          <input
                                            className='form-check-input'
                                            // checked={check}
                                            onChange={(e) => {
                                              // console.log(e.target.checked)
                                              setExcecaoQuadra(e.target.checked)
                                            }}
                                            value='4'
                                            type='checkbox'
                                            id='gridCheck'
                                          />
                                          Exbir Exceção Quadra
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                <div className='row pt-2'>
                                  <div className='col-md-12 all-end pt-5'>
                                    <button
                                      type='submit'
                                      className='btn btn-sencondary me-5'
                                      onClick={limparForm}
                                    >
                                      Limpar
                                    </button>

                                    <button type='submit' className='btn btn-primary'>
                                      Buscar
                                    </button>
                                  </div>
                                </div>

                                <div className='row  mt-3'>
                                  {/* <div className='col-md-4'>
                                  <FormLabel className='form-label'>Tipo</FormLabel>
                                  <GetEmpreendimentoTipoChosen />
                                </div>
                                <div className='col-md-4'>
                                  <FormLabel className='form-label'>Tags</FormLabel>
                                  <input
                                    type='text'
                                    className='form-control'
                                    placeholder='name@example.com'
                                  />
                                </div> */}
                                </div>
                                <div className='row  mt-3'>
                                  <div className='col-md-4'></div>
                                </div>
                              </Form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='content  d-flex flex-column flex-column-fluid'>
                  <div className='col-xl-12'>
                    <Table active={descart} filtro={filter} objectFilter={objectFilter} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div className='wrapper d-flex flex-column flex-column-fluid' id='kt_wrapper'>
          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            {' '}
            <MensagemAlertAcesso mensagem='Somente para assinantes do Produto - Urbit Empreendimentos.'>
              {' '}
            </MensagemAlertAcesso>
          </div>
        </div>
      )}
    </>
  )
}
