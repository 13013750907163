import {useImoveisStore} from '../../context/DataContext'
import {MapView} from './MapView'
import {Imoveis} from '../../types'
import {Legenda} from '../SocioDemografico/Legenda'
import {styleM2, styleTicket} from './variaveis'

interface MapVendaProps {
  height: number
  amostras: Imoveis[]
}

const Map4Dorm = ({height, amostras}: MapVendaProps) => {
  const {endereco} = useImoveisStore()
  if (!endereco) {
    return <></>
  }
  const filtered = amostras.filter((item) => item.quartos === 4)

  return (
    <>
      <div className='row'>
        <div className='col-6'>
          <MapView
            center={{
              lat: endereco?.latitude,
              lng: endereco?.longitude,
            }}
            layer={[]}
            json={undefined}
            dataImoveis={filtered}
            style={styleTicket}
            zoom={15}
          >
            <Legenda style={styleTicket} title={'Ticket'} visivel={true}></Legenda>
          </MapView>
        </div>
        <div className='col-6'>
          <MapView
            center={{
              lat: endereco?.latitude,
              lng: endereco?.longitude,
            }}
            layer={[]}
            json={undefined}
            dataImoveis={filtered}
            style={styleM2}
            zoom={15}
          >
            <Legenda style={styleM2} title={'Preço m2'} visivel={true}></Legenda>
          </MapView>
        </div>
      </div>
    </>
  )
}

export default Map4Dorm
