import {useMutation, useQuery} from '@apollo/client'
import {GET_CORRETOR_BY_ID, GET_AI_CHAT_HISTORICO, GET_AI_CHAT_HISTORICO_BY_ID} from '../query'
import {DELETE_CORRETOR, PUT_CORRETOR, SET_CORRETOR} from '../mutations'
import {zodResolver} from '@hookform/resolvers/zod'
import {useForm} from 'react-hook-form'
import {
  TypesDeleteAiChatHistoricoResponse,
  TypesGetAiChatHistoricoById,
  TypesGetAiChatHistoricoResponse,
  TypesPutAiChatHistoricoResponse,
  TypesSetAiChatHistoricoResponse,
} from '../type/AiChatHistorico'
import {
  putAiChatHistoricoFormData,
  putAiChatHistoricoFormSchema,
  setAiChatHistoricoFormData,
  setAiChatHistoricoFormSchema,
} from '../formValidations/AiChatHistorico'

interface QueryProps {
  variables: any
}

export function QueryGetAiChatHistorico({variables}: QueryProps) {
  const {data, loading, error} = useQuery<TypesGetAiChatHistoricoResponse>(GET_AI_CHAT_HISTORICO, {
    variables: {...variables},
  })
  return {data, loading, error}
}

export function QueryGetAiChatHistoricoByID({variables}: QueryProps) {
  const {data, loading} = useQuery<TypesGetAiChatHistoricoById>(GET_AI_CHAT_HISTORICO_BY_ID, {
    variables: {...variables},
  })
  return {data, loading}
}

export function MutationDeleteAiChatHistorico() {
  const [deleteAiChatHistorico, {loading, data, error}] =
    useMutation<TypesDeleteAiChatHistoricoResponse>(DELETE_CORRETOR, {
      refetchQueries: [GET_AI_CHAT_HISTORICO, GET_CORRETOR_BY_ID],
    })

  async function HandleDeleteAiChatHistorico({variables}: QueryProps) {
    try {
      const result = await deleteAiChatHistorico({
        variables: {...variables},
        refetchQueries: [GET_AI_CHAT_HISTORICO],
      })
      return result
    } catch (e) {
      if (e) {
        return false
      }
    }
  }

  return {HandleDeleteAiChatHistorico, loading, error, data}
}

export function MutationCreateAiChatHistorico() {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<setAiChatHistoricoFormData>({
    resolver: zodResolver(setAiChatHistoricoFormSchema),
  })

  const [setAiChatHistoricoBody, {error, loading, data: DataSetAiChatHistorico}] =
    useMutation<TypesSetAiChatHistoricoResponse>(SET_CORRETOR, {
      refetchQueries: [GET_AI_CHAT_HISTORICO],
    })

  async function FormAiChatHistorico(data: setAiChatHistoricoFormData) {
    delete data.id
    await setAiChatHistoricoBody({
      variables: {
        corretor: {...data},
      },
    })
  }

  return {
    register,
    handleSubmit,
    FormAiChatHistorico,
    loading,
    errors,
    error,
    DataSetAiChatHistorico,
  }
}

export function MutationPutAiChatHistorico() {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<putAiChatHistoricoFormData>({
    resolver: zodResolver(putAiChatHistoricoFormSchema),
  })

  const [setAiChatHistoricoBody, {error, loading, data: DataSetAiChatHistorico}] =
    useMutation<TypesPutAiChatHistoricoResponse>(PUT_CORRETOR, {
      refetchQueries: [GET_AI_CHAT_HISTORICO, GET_CORRETOR_BY_ID],
    })

  async function FormAiChatHistorico(data: putAiChatHistoricoFormData) {
    const id = data.id
    delete data.id
    await setAiChatHistoricoBody({
      variables: {
        putAiChatHistoricoId: parseInt(id),
        corretor: {...data},
      },
    })
  }

  return {
    register,
    handleSubmit,
    FormAiChatHistorico,
    loading,
    errors,
    error,
    DataSetAiChatHistorico,
  }
}
