import React, {useMemo, useState} from 'react'
import {QueryGetEmpreendimentos} from '../../../graphql'
import Pagination from '../../../components/UI/Pagination'
import {Loader} from '../../../components/UI/Loader/Loader'
import {KTSVG} from '../../../../_metronic/helpers'
import {formatNumber} from '../../explorer/components/common'
import {BiExport, BiInfoCircle, BiLinkExternal} from 'react-icons/bi'
import {ExportTable} from '../../../utils/ExportXLSX'
import DataTable from 'react-data-table-component'
import {customStylesTable} from '../../inteligencia-mercado/components/Common/common'
type Props = {
  active: boolean
  filtro: any[]
  toggleFilter: any
  toggleInfos: any
  objectFilter: any
}

const Table: React.FC<Props> = ({active, filtro, toggleFilter, toggleInfos, objectFilter}) => {
  const customSortNumeric = <T,>(
    rowA: any,
    rowB: any,
    columnId: keyof T,
    direction: 'asc' | 'desc'
  ) => {
    const valueA = rowA.empreendimento_temperatura?.nome

    const valueB = rowB.empreendimento_temperatura?.nome

    // Verifica valores nulos ou vazios e coloca no final
    const isEmptyA = valueA === null || valueA === undefined
    const isEmptyB = valueB === null || valueB === undefined

    if (isEmptyA && isEmptyB) return -1 // Ambos vazios, mantém posição
    if (isEmptyA) return 0 // A é vazio, vai para o final
    if (isEmptyB) return -1 // B é vazio, vai para o final

    return direction === 'asc' ? valueA - valueB : valueB - valueA
  }

  const [pages, setPages] = useState(0)

  const {data} = QueryGetEmpreendimentos({
    variables: {
      pagination: {pagina: pages, quantidade: 1000},
      filtro: {
        fields: filtro,
        objectFilter: {
          ...{
            empreendimento_terreno:
              objectFilter.id_corretor?.objectFilter?.empreendimento_terreno || undefined,
          },
          ...{users: objectFilter.id_equipe?.objectFilter?.users || undefined},
        },
      },
      situation: undefined,
    },
  })

  const columnsData = useMemo(() => {
    return [
      {
        name: '',
        selector: (row) => {
          return (
            <a
              href={`/empreendimentos/details/${row.id}#composicao-terrenos`}
              target='_blank'
              rel='noreferrer'
              className='fs-2'
            >
              <BiLinkExternal />
            </a>
          )
        },
        sortable: true,
        wrap: true,
        width: '55px',
      },
      {
        name: 'Temperatura',
        selector: (row) => {
          // console.log(isNaN(row.empreendimento_temperatura?.nome))
          // console.log(row.empreendimento_temperatura?.nome)
          if (row.empreendimento_temperatura?.nome) {
            if (isNaN(row.empreendimento_temperatura?.nome)) {
              if (row.empreendimento_temperatura?.nome === '') {
                return 0
              } else {
                return row.empreendimento_temperatura?.nome
              }
            } else {
              return Number(row.empreendimento_temperatura?.nome)
            }
          } else {
            return 0
          }
        },
        format: (row) => {
          return row.empreendimento_temperatura ? (
            <span
              className='badge badge-temperatura fw-bold me-auto px-4 py-2 p-5 '
              style={{
                backgroundColor: row.empreendimento_temperatura?.cor,
              }}
            >
              {row.empreendimento_temperatura?.nome}
            </span>
          ) : (
            ''
          )
        },

        sortable: true,
        width: '140px',
        center: true,
        sortFunction: (a, b) => customSortNumeric(a, b, 'Temperatura', 'asc'),
      },
      {
        name: 'Nome',
        selector: (row) => (row.nome ? row.nome : 'Não informado'),
        sortable: true,
        wrap: true,
        width: '200px',
      },
      {
        name: 'Qtd. Lotes',
        selector: (row) => row.empreendimento_terreno?.length,
        sortable: true,
        width: '130px',
      },
      {
        name: 'Área de terreno(m²)',
        selector: (row) => row.area_total_terreno,
        format: (row) => {
          return row.area_total_terreno ? formatNumber(row?.area_total_terreno, 'decimal') : '-'
        },
        sortable: true,
        width: '170px',
        center: true,
      },
      {
        name: 'Área Privativa (m²)',
        selector: (row) => row.area_total_privativa,
        format: (row) => {
          return row?.area_total_privativa
            ? formatNumber(row?.area_total_privativa, 'decimal')
            : '-'
        },
        sortable: true,
        width: '170px',
        center: true,
      },
      {
        name: 'Zoneamento',
        selector: (row) => {
          let terreno =
            row.empreendimento_terreno.length > 0 ? row.empreendimento_terreno[0].terreno : null

          return terreno ? (terreno.zoneamento ? terreno.zoneamento : '-') : '-'
        },
        sortable: true,
        center: true,
      },
      {
        name: 'Valor do terreno',
        selector: (row) => row.preco_medio_m2 * row.area_total_terreno,
        format: (row) => {
          return row?.preco_medio_m2
            ? formatNumber(row?.preco_medio_m2 * row?.area_total_terreno, 'decimal')
            : '-'
        },
        sortable: true,
        width: '170px',
        center: true,
      },
      {
        name: 'Valor da comissão',
        selector: (row) =>
          row.preco_medio_m2 * row.area_total_terreno * (row.percentual_comissao / 100),
        format: (row) => {
          return row?.percentual_comissao
            ? formatNumber(
                row.preco_medio_m2 * row.area_total_terreno * (row.percentual_comissao / 100),
                'decimal'
              )
            : '-'
        },
        sortable: true,
        width: '170px',
        center: true,
      },
      {
        name: 'Status',
        selector: (row) => row.empreendimento_situacao.nome,
        sortable: true,
        width: '120px',
        center: true,
      },
      {
        name: 'Op. Urbana',
        selector: (row) => row.valor,
        format: (row) => {
          let terreno =
            row.empreendimento_terreno.length > 0 ? row.empreendimento_terreno[0].terreno : null

          return terreno ? (
            terreno.operacao_urbana ? (
              <div data-bs-toggle='tooltip' data-bs-placement='top' title={terreno.operacao_urbana}>
                <BiInfoCircle className='fs-1'></BiInfoCircle>SIM
              </div>
            ) : (
              'Não'
            )
          ) : (
            '-'
          )

          // formatNumber(row.valor, 'currency_inteiro')
        },
        sortable: true,
        width: '140px',
        right: true,
      },
      {
        name: 'VGV',
        selector: (row) => row.vgv,
        format: (row) => (row.vgv ? formatNumber(row.vgv, 'currency') : '-'),
        sortable: true,
        right: true,
      },
      {
        name: 'Responsavél',
        selector: (row) => (row.users ? (row.users.name ? row.users.name : '-') : '-'),
        sortable: true,
        center: true,
      },
    ]

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtro])

  function exportTable() {
    const dataToExport = []
    // eslint-disable-next-line array-callback-return
    data.GetEmpreendimentos.result.map((Response) => {
      let terreno =
        Response.empreendimento_terreno.length > 0
          ? Response.empreendimento_terreno[0].terreno
          : null

      dataToExport.push({
        nome: Response.nome,
        Temperatura: Response.empreendimento_temperatura?.nome,
        'Qtd. Lotes': Response?.empreendimento_terreno?.length,
        'Área do terreno (m²)': Response?.area_total_terreno
          ? formatNumber(Response?.area_total_terreno, 'decimal')
          : '-',
        'Área total (m²)': Response?.area_total_privativa
          ? formatNumber(Response?.area_total_privativa, 'decimal')
          : '-',
        Zoneamento: terreno ? (terreno.zoneamento ? terreno.zoneamento : '-') : '-',
        Status: Response.empreendimento_situacao?.nome,
        'Operação Urbana': terreno
          ? terreno.operacao_urbana
            ? terreno.operacao_urbana
            : '-'
          : '-',
        VGV: Response?.vgv ? formatNumber(Response?.vgv, 'currency') : '-',
        'Cadastrado por': Response.users ? (Response.users.name ? Response.users.name : '-') : '-',
        // "latitude":Response.empreendimento_terreno[0].terreno.latitude,
        // "longitude":Response.empreendimento_terreno[0].terreno.longitude
      })
    })
    ExportTable(dataToExport, '', 'Relatório Empreendimentos.xlsx')
  }

  // Tratativa de loading
  if (!data) {
    return (
      <div className='card  px-10 py-6'>
        <div className='border-0'>
          <div className='row'>
            <div className='col-xl-12'>
              <div className='card-xxl-stretch '>
                <div className='card-header border-0    p-0'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>
                      <KTSVG
                        path='/media/icons/duotune/files/fil003.svg'
                        className='svg-icon-1 svg-icon-gray-500 me-1'
                      />
                      Relatórios do Empreendimento
                    </span>
                  </h3>
                  <div
                    className='card-toolbar all-end'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                  >
                    <button className='me-4 btn btn-sm btn-flex  btn-primary'>
                      <BiExport></BiExport>
                      Exportar
                    </button>

                    <button
                      className='me-4 btn btn-sm btn-flex btn-light btn-primary'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                      // onClick={toggleInfos}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen046.svg'
                        className='svg-icon-5 svg-icon-gray-500 me-1'
                      />
                      Infos
                    </button>
                    <button
                      className='me-4 btn btn-sm btn-flex btn-light btn-secondary'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                      // onClick={toggleFilter}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen031.svg'
                        className='svg-icon-5 svg-icon-gray-500 me-1'
                      />
                      Filtros
                    </button>

                    {/* <button
                        className='me-4 btn btn-sm btn-flex btn-light btn-secondary'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                        onClick={toggleFilter}
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen031.svg'
                          className='svg-icon-5 svg-icon-gray-500 me-1'
                        />
                        Download
                      </button> */}
                  </div>
                  {/* <div className='all-end'></div> */}
                </div>
                {/* end::Header */}
                <div className='separator my-2'></div>
                <Loader text={'caregando...'}></Loader>
              </div>
              <div className='card-footer all-end'>
                <Pagination setPages={setPages} pages={pages} total={100} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  // data.GetEmpreendimentos.result[0].preco_medio_m2
  return (
    <>
      {' '}
      <div className='card  px-10 py-6'>
        <div className='border-0'>
          <div className='row'>
            <div className='col-xl-12'>
              <div className='card-header border-0    p-0'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3 mb-1'>
                    <KTSVG
                      path='/media/icons/duotune/files/fil003.svg'
                      className='svg-icon-1 svg-icon-gray-500 me-1'
                    />
                    Relatórios do Empreendimento
                  </span>
                </h3>
                <div
                  className='card-toolbar all-end'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Exportar table'
                >
                  <button className='me-4 btn btn-sm btn-flex  btn-primary' onClick={exportTable}>
                    <BiExport></BiExport>
                    Exportar
                  </button>

                  <button
                    className='me-4 btn btn-sm btn-flex btn-light btn-light-warning'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                    onClick={toggleInfos}
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen046.svg'
                      className='svg-icon-5 svg-icon-gray-500 me-1'
                    />
                    Infos
                  </button>
                  <button
                    className='me-4 btn btn-sm btn-flex btn-light btn-secondary'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                    onClick={toggleFilter}
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen031.svg'
                      className='svg-icon-5 svg-icon-gray-500 me-1'
                    />
                    Filtros
                  </button>

                  {/* <button
                        className='me-4 btn btn-sm btn-flex btn-light btn-primary'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                        onClick={toggleFilter}
                      >
                        <KTSVG
                          path='/media/icons/duotune/files/fil009.svg'
                          className='svg-icon-5 svg-icon-gray-500 me-1'
                        />
                        Download
                      </button> */}
                </div>
                {/* <div className='all-end'></div> */}
              </div>
              {/* end::Header */}
              <div className='separator my-2'></div>
              {/* begin::Body */}
              <div className='card-body p-0'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                  {columnsData ? (
                    <DataTable
                      title='Mercado Secundário'
                      noHeader={true}
                      customStyles={customStylesTable}
                      columns={columnsData}
                      subHeader={false}
                      data={data.GetEmpreendimentos.result}
                      pagination={true}
                      paginationRowsPerPageOptions={[10, 25, 50, 100]}
                      // conditionalRowStyles={conditionalRowStyles}
                      highlightOnHover
                      pointerOnHover
                      striped
                      dense
                      paginationPerPage={50}
                      onColumnOrderChange={(cols) => console.log(cols)}
                    />
                  ) : (
                    <Loader text={'Carregando...'}></Loader>
                  )}
                </div>
                {/* end::Table container */}
              </div>
              {/* <div className='card-footer all-end'>
                  <Pagination setPages={setPages} pages={pages} total={100} />
                </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {Table}
