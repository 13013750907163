import {useContext, useEffect, useMemo, useState} from 'react'
import {DataContext} from '../../../context/DataContext'
import {FilterContext} from '../../../context/FilterContext'
import {Dicionario} from '../../../../../utils/Dicionario'
import {BoxPlotChart} from '../../Charts/BoxPlotChart'
import {min, max, quantile} from 'simple-statistics'
import {formatNumber} from '../../../../explorer/components/common'

type Props = {
  label?: string
}

export default function TicketUnidades({label}: Props) {
  const {data, cacheResult, setCacheResult} = useContext(DataContext)
  const {filter} = useContext(FilterContext)
  const [selectedItem, setSelectedItem] = useState(null)

  const {chartData, chartDataTable} = useMemo(() => {
    let tempData = []
    let chartData = []
    let chartDataTable = Array(7).fill([], 0, 7)
    let arrayQuintile = Array(7).fill([], 0, 7)
    let totalUnidadesTipologias = Array(7).fill(0, 0, 7)
    let stockUnidadesTipologias = Array(7).fill(0, 0, 7)
    let totalUnidades = Array(7).fill(0, 0, 7)

    if (data) {
      // eslint-disable-next-line array-callback-return
      data.map((item) => {
        // eslint-disable-next-line array-callback-return
        item.tipologias.map((itemTipologias) => {
          let indice = itemTipologias.bedrooms
          const id = `faixapreco_${indice}`

          if (itemTipologias.computed) {
            if (itemTipologias.original_price > 0.1 && itemTipologias.stock > 0) {
              // console.log(itemTipologias)
              if (itemTipologias.bedrooms === 0) {
                indice = 1
              }

              totalUnidadesTipologias[indice] =
                totalUnidadesTipologias[indice] + itemTipologias.total_units
              stockUnidadesTipologias[indice] =
                stockUnidadesTipologias[indice] + itemTipologias.stock
              totalUnidades[indice] = totalUnidades[indice] + itemTipologias.total_units

              let qtde = itemTipologias.total_units
              //itemTipologias.preco_lancamento

              let valor = itemTipologias.preco_lancamento
              let arrayFinal = Array(qtde).fill(valor)
              arrayQuintile[indice] = [...arrayQuintile[indice], ...arrayFinal]

              chartDataTable[indice] = {
                dormitorios: `${indice}  Dorm.`,
                min: 0,
                mediana: 0,
                max: 0,
                total_unidades: totalUnidadesTipologias[indice],
                // vendas: totalUnidadesTipologias[indice] - stockUnidadesTipologias[indice],
                // estoque: stockUnidadesTipologias[indice],
                id: id,
              }
            }
          }
        })
      })

      // eslint-disable-next-line array-callback-return
      arrayQuintile.map((item, index) => {
        if (item.length > 0) {
          const id = `faixapreco_${index}`
          let minimo = Math.round(min(item))
          let maximo = Math.round(max(item))
          let quant = quantile(item, [0.25, 0.5, 0.75])
          if (typeof tempData[index] == 'undefined') {
            tempData[index] = []
          }

          tempData[index].push(minimo)
          tempData[index] = [...tempData[index], ...quant]
          tempData[index].push(maximo)
          chartData.push({
            x: index + ' Dorm.',
            y: tempData[index],
            id: id,
          })

          chartDataTable[index]['min'] = minimo
          chartDataTable[index]['mediana'] = quant[1]
          chartDataTable[index]['max'] = maximo
        }
      })
    }

    return {chartData, chartDataTable}
  }, [data])

  useEffect(() => {
    cacheResult.performanceResumoMercado.precoMedioEstoque = {
      chartData: chartData,
      tableData: chartDataTable,
    }
    setCacheResult(cacheResult)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData])

  if (!data) {
    return <>Carregando..</>
  }
  if (filter.segmentacaoArea === null || !filter.segmentacaoPreco === null) {
    return <>Carregando..</>
  }

  return (
    <BoxPlotChart
      className={'primary'}
      title={'Ticket Médio de Lançamento - R$/m²'}
      subtitle={'Preço histórico sem correção.'}
      data={chartData}
      onChange={(id: number) => {
        setSelectedItem(id)
      }}
    >
      <div className='alert alert-info w-100'>
        <b>Atenção:</b> Preços da data de lançamento ou mais próxima.
      </div>
      <table className='table  table-row-dashed table-row-gray-300 table-hover'>
        <thead>
          <tr className='text-dark fw-bold'>
            {chartDataTable && chartDataTable.length > 0
              ? Object.entries(chartDataTable[1]).map((item, index) => {
                  return item[0] !== 'id' ? (
                    <th className='text-center' key={index}>
                      {Dicionario.translate(item[0])}
                    </th>
                  ) : null
                })
              : null}
          </tr>
        </thead>
        <tbody>
          {chartDataTable
            ? // eslint-disable-next-line array-callback-return
              chartDataTable.map((item, index) => {
                if (!Array.isArray(item)) {
                  return (
                    <tr key={index} className={item.id === selectedItem ? 'select-data-table' : ''}>
                      <td className='text-center'>{item.dormitorios}</td>
                      <td className='text-center'>
                        {formatNumber(Math.ceil(item.min), 'currency')}{' '}
                      </td>
                      <td className='text-center'>{formatNumber(item.mediana, 'currency')}</td>
                      <td className='text-center'>{formatNumber(item.max, 'currency')}</td>
                      <td className='text-center'>{item.total_unidades}</td>
                      {/*<td className='text-center'>{item.vendas}</td> 
                      <td className='text-center'>{item.estoque}</td>*/}
                    </tr>
                  )
                }
              })
            : ''}
        </tbody>
      </table>
    </BoxPlotChart>
  )
}
