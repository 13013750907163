import {QueryGetEmpreendimentosMotivoDescarte} from '../../../../../../../graphql'
import {formatDate} from '../../../../common'

type PropsClickInfo = {
  id: number
}

export const MotivosDescarte: React.FC<PropsClickInfo> = ({id}) => {
  console.log(id)

  const {data} = QueryGetEmpreendimentosMotivoDescarte({
    variables: {
      idEmpreendimento: id,
    },
  })
  console.log(data)
  if (!data) {
    return <div>Sem registro</div>
  }
  return (
    <div className='py-5'>
      <table className='table table-striped'>
        <thead className='thead-dark'>
          <tr>
            <th className='text-dark'>Data Cadastro</th>
            <th>Usuário</th>
            <th>Motivo</th>
          </tr>
        </thead>
        <tbody>
          {data ? (
            data.GetEmpreendimentosMotivoDescarte.result.map((item) => {
              return (
                <tr>
                  <th>{formatDate(item.data_cadastro)}</th>
                  <th>{item.users?.name}</th>
                  <th>{item.motivo}</th>
                </tr>
              )
            })
          ) : (
            <></>
          )}
        </tbody>
      </table>
    </div>
  )
}
