import {useContext, useEffect} from 'react'
import {DataContext} from '../../context/DataContext'
import {formatNumber} from '../../../explorer/components/common'
import {HeaderPerformance} from '../Common/HeaderPerformance'
import {FilterContext} from '../../context/FilterContext'

type Props = {
  label?: string
}

export function Performance({label}: Props) {
  const {data, setCacheResult, cacheResult} = useContext(DataContext)
  const {filter} = useContext(FilterContext)

  useEffect(() => {
    if (!data) {
      return
    }

    const totalUnidade = data.reduce(
      (valor, item) =>
        valor +
        item.tipologias.reduce((valor2, item2) => {
          let valortmp = valor2 + (item2.computed ? item2.total_units : 0)
          return valortmp
        }, 0),
      0
    )

    const stock = data.reduce(
      (valor, item) =>
        valor +
        item.tipologias.reduce(
          (valor2, item2) => valor2 + (item2.computed ? Number(item2.stock) : 0),
          0
        ),
      0
    )

    const totalUnidadesVendidas = data.reduce(
      (valor, item) =>
        valor +
        item.tipologias.reduce(
          (valor2, item2) =>
            valor2 + (item2.computed ? item2.total_units - Number(item2.stock) : 0),
          0
        ),
      0
    )

    const precoMedioStock =
      data.reduce(
        (valor, item) =>
          valor +
          item.tipologias.reduce((valor2, item2) => {
            return (
              valor2 +
              (item2.computed
                ? (item2.original_price / Number(item2.private_area)) * Number(item2.stock)
                : 0)
            )
          }, 0),
        0
      ) / stock

    const vgvLancado = data.reduce(
      (valor, item) =>
        valor +
        item.tipologias.reduce((valor2, item2) => {
          return (
            valor2 +
            (item2.computed
              ? typeof item2.preco_lancamento !== 'undefined' && item2.preco_lancamento !== null
                ? Number(item2.preco_lancamento) * Number(item2.total_units)
                : 0
              : 0)
          )
        }, 0),
      0
    )

    const vgvStock = data.reduce(
      (valor, item) =>
        valor +
        item.tipologias.reduce((valor2, item2) => {
          return valor2 + (item2.computed ? Number(item2.stock) * Number(item2.original_price) : 0)
        }, 0),
      0
    )

    const vgvVendido = vgvLancado - vgvStock

    let unidadesAno = [2019, 2020, 2021, 2022, 2023, 2024, 2025]
    let unidadePorAno = {}
    // eslint-disable-next-line array-callback-return
    unidadesAno.map((ano) => {
      unidadePorAno[ano] = data.reduce((valor, item) => {
        if (item.ano_lancamento === ano) {
          return (
            valor +
            item.tipologias.reduce(
              (valor2, item2) => valor2 + (item2.computed ? item2.total_units : 0),
              0
            )
          )
        } else {
          return valor
        }
      }, 0)
    })

    let stockAno = [2019, 2020, 2021, 2022, 2023, 2024, 2025]
    let stockPorAno = {}
    // eslint-disable-next-line array-callback-return
    stockAno.map((ano) => {
      stockPorAno[ano] = data.reduce((valor, item) => {
        if (item.ano_lancamento === ano) {
          return (
            valor +
            item.tipologias.reduce(
              (valor2, item2) => valor2 + (item2.computed ? item2.stock : 0),
              0
            )
          )
        } else {
          return valor
        }
      }, 0)
    })

    // let stockAno = [2019, 2020, 2021, 2022, 2023, 2024, 2025]
    let vendaPorAno = {}
    // eslint-disable-next-line array-callback-return
    stockAno.map((ano) => {
      vendaPorAno[ano] = data.reduce((valor, item) => {
        if (item.ano_lancamento === ano) {
          return (
            valor +
            item.tipologias.reduce(
              (valor2, item2) => valor2 + (item2.computed ? item2.total_units - item2.stock : 0),
              0
            )
          )
        } else {
          return valor
        }
      }, 0)
    })

    let lancamentosPorAno = {}

    // eslint-disable-next-line array-callback-return
    unidadesAno.map((ano) => {
      lancamentosPorAno[ano] = data.reduce((valor, item) => {
        if (item.computed) {
          if (item.ano_lancamento === ano) {
            return (
              valor +
              (item.tipologias.filter((item2) => {
                if (item2.computed) {
                  return true
                } else {
                  return false
                }
              }).length > 0
                ? 1
                : 0)
            )
          } else {
            return valor
          }
        } else {
          return valor
        }
      }, 0)
    })

    let totalemp = data.reduce((valor, item) => {
      return (
        valor +
        (item.tipologias.filter((item2) => {
          if (item2.computed) {
            return true
          } else {
            return false
          }
        }).length > 0
          ? 1
          : 0)
      )
    }, 0)

    let cacheResultTemp = cacheResult
    cacheResultTemp['performance'] = {
      precoMedioM2Estoque: precoMedioStock,
      vgvLancado: vgvLancado,
      vgvVendido: vgvVendido,
      vgvEstoque: vgvStock,
      totalProdutosLancados: totalemp,
      unidadesLancadas: totalUnidade,
      unidadesVendidas: totalUnidadesVendidas,
      estoque: stock,
      lancamentosPorAno: lancamentosPorAno,
      unidadesLancadasPorAno: unidadePorAno,
      stockPorAno: stockPorAno,
      vendaPorAno: vendaPorAno,
    }
    setCacheResult({...cacheResult, ...cacheResultTemp})

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  if (!data) {
    return <>Carregando...</>
  }

  if (!filter) {
    return <>Carregando...</>
  }

  if (!cacheResult) {
    return <>Carregando...</>
  }

  return (
    <>
      <div className=' d-flex flex-column justify-content-start p-10'>
        <HeaderPerformance titulo={'Performance'}></HeaderPerformance>
        <div className='content pt-5'>
          <div className='row'>
            <div className='col-12'>
              <div className=' bgi-no-repeat card-xl-stretch mb-xl-8 '>
                <div className='row '>
                  <div className='col-xl-3'>
                    <div
                      className='card bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance'
                      style={{
                        backgroundPosition: 'right top',
                        backgroundSize: '30%',
                        backgroundImage: "url('/media/svg/shapes/abstract-4.svg')",
                      }}
                    >
                      <div className='card-body'>
                        <div className='card-title fw-bold fs-4'>PREÇO MÉDIO M² ESTOQUE</div>
                        <div className='fw-bold text-success my-6 fs-6 '>
                          Valor preço médio do m² do estoque
                        </div>
                        <p className='text-gray-900-75 fw-semibold fs-1 m-0'>
                          {formatNumber(
                            Math.round(cacheResult.performance?.precoMedioM2Estoque),
                            'currency'
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-3'>
                    <div
                      className='card bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance'
                      style={{
                        backgroundPosition: 'right top',
                        backgroundSize: '30%',
                        backgroundImage: "url('/media/svg/shapes/abstract-4.svg')",
                      }}
                    >
                      <div className='card-body'>
                        <div className='card-title fw-bold fs-4'>VGV LANÇADO</div>
                        <div className='fw-bold text-success my-6 fs-6 '>Total do VGV lançado</div>
                        <p className='text-gray-900-75 fw-semibold fs-1 m-0'>
                          {formatNumber(
                            Math.round(cacheResult.performance?.vgvLancado),
                            'currency_inteiro'
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-3'>
                    <div
                      className='card bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance'
                      style={{
                        backgroundPosition: 'right top',
                        backgroundSize: '30%',
                        backgroundImage: "url('/media/svg/shapes/abstract-4.svg')",
                      }}
                    >
                      <div className='card-body'>
                        <div className='card-title fw-bold fs-4'>VGV VENDIDO</div>
                        <div className='fw-bold text-success my-6 fs-6 '>Total do VGV Vendido</div>
                        <p className='text-gray-900-75 fw-semibold fs-1 m-0'>
                          {formatNumber(
                            Math.round(cacheResult.performance?.vgvVendido),
                            'currency_inteiro'
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-3'>
                    <div
                      className='card bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance'
                      style={{
                        backgroundPosition: 'right top',
                        backgroundSize: '30%',
                        backgroundImage: "url('/media/svg/shapes/abstract-4.svg')",
                      }}
                    >
                      <div className='card-body'>
                        <div className='card-title fw-bold fs-4'>VGV ESTOQUE</div>
                        <div className='fw-bold text-success my-6 fs-6 '>
                          Total do VGV do estoque
                        </div>
                        <p className='text-gray-900-75 fw-semibold fs-1 m-0'>
                          {formatNumber(
                            Math.round(cacheResult.performance?.vgvEstoque),
                            'currency_inteiro'
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row g-5 g-xl-8'>
                  <div className='col-xl-3'>
                    <div
                      className='card bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance'
                      style={{
                        backgroundPosition: 'right top',
                        backgroundSize: '30%',
                        backgroundImage: "url('/media/svg/shapes/abstract-4.svg')",
                      }}
                    >
                      <div className='card-body'>
                        <div className='card-title fw-bold fs-4'> PRODUTOS LANÇADOS</div>
                        <div className='fw-bold text-success my-6 fs-6'>
                          Total de Produtos Lançados
                        </div>
                        <p className='text-gray-900-75 fw-semibold fs-1 m-0'>
                          {formatNumber(cacheResult.performance?.totalProdutosLancados, 'inteiro')}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-3'>
                    <div
                      className='card bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance'
                      style={{
                        backgroundPosition: 'right top',
                        backgroundSize: '30%',
                        backgroundImage: "url('/media/svg/shapes/abstract-4.svg')",
                      }}
                    >
                      <div className='card-body'>
                        <div className='card-title fw-bold fs-4'>UNIDADES LANÇADAS</div>
                        <div className='fw-bold text-success my-6 fs-6 '>
                          Total de unidades lançadas
                        </div>
                        <p className='text-gray-900-75 fw-semibold fs-1 m-0'>
                          {formatNumber(cacheResult.performance?.unidadesLancadas, 'inteiro')}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-3'>
                    <div
                      className='card bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance'
                      style={{
                        backgroundPosition: 'right top',
                        backgroundSize: '30%',
                        backgroundImage: "url('/media/svg/shapes/abstract-4.svg')",
                      }}
                    >
                      <div className='card-body'>
                        <div className='card-title fw-bold fs-4'>UNIDADES VENDIDAS</div>
                        <div className='fw-bold text-success my-6 fs-6 '>
                          Total de unidades vendidas
                        </div>
                        <p className='text-gray-900-75 fw-semibold fs-1 m-0'>
                          {formatNumber(cacheResult.performance?.unidadesVendidas, 'inteiro')} /{' '}
                          {formatNumber(
                            (
                              ((cacheResult.performance?.unidadesLancadas -
                                cacheResult.performance?.estoque) /
                                cacheResult.performance?.unidadesLancadas) *
                              100
                            ).toFixed(0),
                            'inteiro'
                          )}
                          %
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-3'>
                    <div
                      className='card bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance'
                      style={{
                        backgroundPosition: 'right top',
                        backgroundSize: '30%',
                        backgroundImage: "url('/media/svg/shapes/abstract-4.svg')",
                      }}
                    >
                      <div className='card-body'>
                        <div className='card-title fw-bold fs-4'>ESTOQUE</div>
                        <div className='fw-bold text-success my-6 fs-6 '>
                          Total de unidades em estoque
                        </div>
                        <p className='text-gray-900-75 fw-semibold fs-1 m-0'>
                          {' '}
                          {formatNumber(cacheResult.performance?.estoque, 'inteiro')} /{' '}
                          {formatNumber(
                            (
                              (cacheResult.performance?.estoque /
                                cacheResult.performance?.unidadesLancadas) *
                              100
                            ).toFixed(0),
                            'inteiro'
                          )}
                          %
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row g-5 g-xl-8'>
                  <div className='col-xl-3'>
                    <div
                      className='card bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance'
                      style={{
                        backgroundPosition: 'right top',
                        backgroundSize: '30%',
                        backgroundImage: "url('/media/svg/shapes/abstract-4.svg')",
                      }}
                    >
                      <div className='card-body'>
                        <div className='card-title fw-bold fs-4'>LANÇAMENTOS POR ANO</div>
                        <div className='fw-bold fs-6 text-success my-6 fs-6 '>
                          Total de empreendimentos lançados
                        </div>

                        <div className='text-gray-900-75 fs-6 m-0'>
                          <table className='table table-striped table-condensed'>
                            <thead>
                              <tr className='fw-semibold'>
                                <th>Ano</th>
                                <th className='text-center'>Quantidade</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.entries(cacheResult.performance?.lancamentosPorAno).map(
                                (item, key) => {
                                  if (
                                    Number(item[0]) >= filter.anoLancamento[0] &&
                                    Number(item[0]) <= filter.anoLancamento[1]
                                  ) {
                                    return (
                                      <tr key={key}>
                                        <td>{item[0]}</td>
                                        <td className='fw-semibold text-center'>
                                          {' '}
                                          {formatNumber(item[1], 'inteiro')}
                                        </td>
                                      </tr>
                                    )
                                  } else {
                                    return null
                                  }
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-3'>
                    <div
                      className='card bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance'
                      style={{
                        backgroundPosition: 'right top',
                        backgroundSize: '30%',
                        backgroundImage: "url('/media/svg/shapes/abstract-4.svg')",
                      }}
                    >
                      <div className='card-body'>
                        <div className='card-title fw-bold fs-4'>UNIDADES LANÇADAS POR ANO</div>
                        <div className='fw-bold text-success my-6 fs-6 '>
                          Total de unidades lançadas
                        </div>
                        <div className='text-gray-900-75 fs-6 m-0'>
                          <table className='table table-striped table-condensed'>
                            <thead>
                              <tr className='fw-semibold'>
                                <th>Ano</th>
                                <th className='text-center'>Quantidade</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.entries(cacheResult.performance?.unidadesLancadasPorAno).map(
                                (item, key) => {
                                  if (
                                    Number(item[0]) >= filter.anoLancamento[0] &&
                                    Number(item[0]) <= filter.anoLancamento[1]
                                  ) {
                                    return (
                                      <tr key={key}>
                                        <td>{item[0]}</td>
                                        <td className='fw-semibold text-center'>
                                          {formatNumber(item[1], 'inteiro')}
                                        </td>
                                      </tr>
                                    )
                                  } else {
                                    return null
                                  }
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-xl-3'>
                    <div
                      className='card bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance'
                      style={{
                        backgroundPosition: 'right top',
                        backgroundSize: '30%',
                        backgroundImage: "url('/media/svg/shapes/abstract-4.svg')",
                      }}
                    >
                      <div className='card-body'>
                        <div className='card-title fw-bold fs-4'>ESTOQUE POR ANO DE LANÇAMENTO</div>
                        <div className='fw-bold text-success my-6 fs-6 '>
                          Total de unidades em estoque
                        </div>
                        <div className='text-gray-900-75 fs-6 m-0'>
                          <table className='table table-striped table-condensed'>
                            <thead>
                              <tr className='fw-semibold'>
                                <th>Ano</th>
                                <th className='text-center'>Quantidade</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.entries(cacheResult.performance?.stockPorAno).map(
                                (item, key) => {
                                  if (
                                    Number(item[0]) >= filter.anoLancamento[0] &&
                                    Number(item[0]) <= filter.anoLancamento[1]
                                  ) {
                                    return (
                                      <tr key={key}>
                                        <td>{item[0]}</td>
                                        <td className='fw-semibold text-center'>
                                          {formatNumber(item[1], 'inteiro')}
                                        </td>
                                      </tr>
                                    )
                                  } else {
                                    return null
                                  }
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-3'>
                    <div
                      className='card bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance'
                      style={{
                        backgroundPosition: 'right top',
                        backgroundSize: '30%',
                        backgroundImage: "url('/media/svg/shapes/abstract-4.svg')",
                      }}
                    >
                      <div className='card-body'>
                        <div className='card-title fw-bold fs-4'>VENDA POR ANO DE LANÇAMENTO</div>
                        <div className='fw-bold text-success my-6 fs-6 '>
                          Venda atual por ano de lançamento
                        </div>
                        <div className='text-gray-900-75 fs-6 m-0'>
                          <table className='table table-striped table-condensed'>
                            <thead>
                              <tr className='fw-semibold'>
                                <th>Ano</th>
                                <th className='text-center'>Quantidade</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.entries(cacheResult.performance?.vendaPorAno).map(
                                (item, key) => {
                                  if (
                                    Number(item[0]) >= filter.anoLancamento[0] &&
                                    Number(item[0]) <= filter.anoLancamento[1]
                                  ) {
                                    let unidadeslancadasporano =
                                      cacheResult.performance?.unidadesLancadasPorAno[item[0]]
                                    return (
                                      <tr key={key}>
                                        <td>{item[0]}</td>
                                        <td className='fw-semibold text-center'>
                                          {unidadeslancadasporano === 0
                                            ? 0
                                            : formatNumber(
                                                (
                                                  (Number(item[1]) /
                                                    Number(unidadeslancadasporano)) *
                                                  100
                                                ).toFixed(0),
                                                'inteiro'
                                              )}
                                          %
                                        </td>
                                      </tr>
                                    )
                                  } else {
                                    return null
                                  }
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
