import React, {useEffect, useState} from 'react'
import {
  EmpreendimentoClienteResponse,
  MutationPutEmpreendimentoChangeUser,
  QueryGetEmpreendimentos,
} from '../../../graphql'
import Pagination from '../../../components/UI/Pagination'
import {Buttons} from './ButtonsEmpreendimentos'
import {Loader} from '../../../components/UI/Loader/Loader'
import {CreateEmpreendimentos} from '../create'
import {BiCompass, BiInfoCircle} from 'react-icons/bi'
import {encodeUrl} from '../../../components/util'
import {useHistory} from 'react-router-dom'
import PaginationComponent from '../../../components/UI/PaginationComponents'
import {CardTables} from '../../../components/UI/CardTables'
import LoadingTable from '../../../components/UI/CardTables/LoadingTable'
import ErrorTable from '../../../components/UI/CardTables/ErrorTable'
import {GetUsuariosEquipesByCargoChosen} from '../../../components/UI/Chosens/GetUsuariosEquipesByCargo'
import {getPermissaoAcao} from '../../../utils/PermissoesAcesso'
import Swal from 'sweetalert2'
import {formatDate} from '../../explorer/components/common'

type Props = {
  active: boolean
  filtro: any[]
  objectFilter: any
}

const Table: React.FC<Props> = ({active, filtro, objectFilter}) => {
  let result = null

  if (active === true) {
    result = 4
  } else if (active === false) {
    result = undefined
  }
  type DataRow = {
    id: number
    nome: string | null
    created_at: string | null
    empreendimento_temperatura: any
  }

  const [pages, setPages] = useState(0)
  const [selecionado, setselecionado] = useState([])
  const history = useHistory()
  const [pagiancao, setPaginacao] = useState({pagina: 0, quantidade: 10})
  const [orderBy, setOrderBy] = useState({fields: null})
  const {FormEmpreendimentoChangeUser} = MutationPutEmpreendimentoChangeUser()
  const [dataTable, setDataTable] = useState<EmpreendimentoClienteResponse>()
  const [sortConfig, setSortConfig] = useState<{
    key: keyof DataRow
    direction: 'asc' | 'desc'
  } | null>({key: 'id', direction: 'asc'})

  useEffect(() => {
    setPaginacao({pagina: 0, quantidade: 10})
  }, [filtro])

  let empreendimento_terreno = undefined
  if (objectFilter.id_corretor && objectFilter.zoneamento) {
    empreendimento_terreno = {
      some: {
        terreno: {
          terreno_corretor:
            objectFilter.id_corretor?.objectFilter?.empreendimento_terreno.some.terreno
              .terreno_corretor,
          zoneamento:
            objectFilter.zoneamento?.objectFilter?.empreendimento_terreno.some.terreno.zoneamento,
        },
      },
    }
  } else if (objectFilter.id_corretor) {
    empreendimento_terreno = objectFilter.id_corretor?.objectFilter?.empreendimento_terreno
  } else if (objectFilter.zoneamento) {
    empreendimento_terreno = objectFilter.zoneamento?.objectFilter?.empreendimento_terreno
  }

  let query = {
    variables: {
      pagination: {
        pagina: pagiancao.pagina,
        quantidade: pagiancao.quantidade,
      },
      filtro: {
        fields: filtro,

        objectFilter: {
          ...{users: objectFilter.id_equipe?.objectFilter?.users || undefined},

          ...{
            empreendimento_terreno,
          },
        },
      },
      situation: result,
      orderBy: {...orderBy},
    },
  }
  const {data, loading, error} = QueryGetEmpreendimentos({...query})

  useEffect(() => {
    setDataTable(data)
  }, [data])

  function sortDataTemperatura(campo: any) {
    let newfields = orderBy
    newfields.fields = campo
    setOrderBy({...newfields})
  }

  function explorerOpen() {
    history.push('/explorer?eids=' + encodeUrl(selecionado.join(',')))
  }

  // Tratativa de loading
  if (!data) {
    return (
      <div className='card  px-10 py-6'>
        <div className='border-0'>
          <div className='row'>
            <div className='col-xl-12'>
              <div className='card-xxl-stretch '>
                <div className='card-header border-0    p-0'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>Empreendimentos</span>
                    {/* <span className='text-muted mt-1 fw-bold fs-7'>
                      Até o momento  empreendimentos
                      registrados
                    </span> */}
                  </h3>
                  <div
                    className='card-toolbar all-end'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to add a user'
                  >
                    <button
                      className='btn btn-info me-5'
                      onClick={() => {
                        explorerOpen()
                      }}
                    ></button>
                    <CreateEmpreendimentos />
                  </div>
                  {/* <div className='all-end'></div> */}
                </div>
                {/* end::Header */}
                <div className='separator my-2'></div>
                <Loader text={'caregando...'}></Loader>
              </div>
              <div className='card-footer all-end'>
                <Pagination setPages={setPages} pages={pages} total={100} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const isChecked = (id: number) => {
    return selecionado.includes(id)
  }

  const handleCheckboxAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked
    if (!checked) {
      setselecionado([])
    } else {
      const checkdAllItens = data.GetEmpreendimentos.result.map((item) => {
        return item.id
      })
      setselecionado(checkdAllItens)
    }
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const checked = event.target.checked
    if (checked) {
      setselecionado((prevselecionado) => [...prevselecionado, id])
    } else {
      setselecionado((prevselecionado) => prevselecionado.filter((item) => item !== id))
    }
  }

  function changeUser(id_empreendimento: number, id_user_new: number, id_user_old: number) {
    Swal.fire({
      title: 'Deseja alterar o dono do empreendimento?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Alterar',
      showCloseButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await FormEmpreendimentoChangeUser({
          variables: {
            idEmpreendimento: id_empreendimento,
            idUsuario: id_user_new,
            idUsuarioOld: id_user_old,
          },
        })
        if (!result.data) {
          Swal.fire('ERRO!', 'Ocorreu um erro durante a execução.', 'warning')
        } else {
          Swal.fire('Sucesso!', 'Empreendimento deletado com sucesso.', 'success')
        }
      }
    })
  }

  return (
    <>
      <CardTables
        headerTitle={'Empreendimentos'}
        headerSubtTitle={`Até o momento ${
          dataTable ? dataTable.GetEmpreendimentos.pageInfo.totalItems : 0
        } empreendimento(s) registrado(s).`}
        // headerToolbar={
        //   // <button
        //   //   className='btn btn-info me-5'
        //   //   onClick={() => {
        //   //     explorerOpen()
        //   //   }}
        //   // >
        //   //   <BiCompass className='fs-2'></BiCompass> Explorer
        //   // </button>
        // }
        footer={
          data ? (
            <PaginationComponent
              pagesInfo={data.GetEmpreendimentos.pageInfo}
              setPagesInfo={(pagina: number, quantidade: number) => {
                setPaginacao({pagina: pagina, quantidade: quantidade})
              }}
            ></PaginationComponent>
          ) : (
            <></>
          )
        }
      >
        <div className='table-responsive  '>
          <table className='table table-striped table-row-dashed  gy-2 gs-6  table-row-bordered   table-hover  table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                <th className=''>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    {/* <input
                      className='form-check-input widget-9-check'
                      type='checkbox'
                      value='all'
                      onChange={handleCheckboxAll}
                    />*/}
                    id
                  </div>
                </th>
                <th
                  className='text-center mouse-pointer'
                  role='button'
                  onClick={() => {
                    setSortConfig({
                      ...{
                        key: 'created_at',
                        direction: sortConfig?.direction === 'asc' ? 'desc' : 'asc',
                      },
                    })

                    sortDataTemperatura({
                      ...{
                        created_at: sortConfig.direction ? sortConfig.direction : 'asc',
                      },
                      // {
                      //   empreendimento_temperatura: {
                      //     nome: {_count: 'asc'},
                      //   },
                      // },
                    })
                  }}
                >
                  Data Cadastro{' '}
                  {sortConfig?.key === 'created_at'
                    ? sortConfig.direction === 'asc'
                      ? '↑'
                      : '↓'
                    : ''}
                </th>
                <th
                  className='text-center '
                  role='button'
                  onClick={() => {
                    setSortConfig({
                      ...{
                        key: 'empreendimento_temperatura',
                        direction: sortConfig?.direction === 'asc' ? 'desc' : 'asc',
                      },
                    })

                    sortDataTemperatura({
                      ...{
                        empreendimento_temperatura: {
                          nome: sortConfig.direction ? sortConfig.direction : 'asc',
                        },
                      },
                      // {
                      //   empreendimento_temperatura: {
                      //     nome: {_count: 'asc'},
                      //   },
                      // },
                    })
                  }}
                >
                  Temperatura
                  {sortConfig?.key === 'empreendimento_temperatura'
                    ? sortConfig.direction === 'asc'
                      ? '↑'
                      : '↓'
                    : ''}
                </th>
                <th className='text-center'>Situação</th>
                <th
                  className=''
                  role='button'
                  onClick={() => {
                    setSortConfig({
                      ...{
                        key: 'nome',
                        direction: sortConfig?.direction === 'asc' ? 'desc' : 'asc',
                      },
                    })

                    sortDataTemperatura({
                      ...{
                        nome: sortConfig.direction ? sortConfig.direction : 'asc',
                      },
                      // {
                      //   empreendimento_temperatura: {
                      //     nome: {_count: 'asc'},
                      //   },
                      // },
                    })
                  }}
                >
                  Endereço/Nome{' '}
                  {sortConfig?.key === 'nome' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : ''}
                </th>
                <th className=''>Setor/Quadra</th>
                {getPermissaoAcao('EDICAO_MASTER') ? <td className='w-250px'>Usuário</td> : <></>}
                {/* <th className=''>Número Contribuinte</th> */}
                <th className=''>Área</th>
                <th className=''>Zoneamento</th>
                <th className=''>Tags</th>
                <th className=' text-end'>Ações</th>
              </tr>
            </thead>
            <tbody>
              <LoadingTable loading={loading} />
              <ErrorTable error={error} />
              {dataTable ? (
                dataTable.GetEmpreendimentos.result.length > 0 ? (
                  dataTable.GetEmpreendimentos.result.map((response) => {
                    let tags = []
                    if (response.tag && typeof response.tag === 'string') {
                      tags = response.tag.split(',')
                    }

                    // let color = null
                    // if (response.empreendimento_temperatura){
                    // //  color =  chroma(response.empreendimento_temperatura?.cor  ).alpha(0.5).css()
                    // }

                    return (
                      <tr key={response.id}>
                        <td className=''>
                          <div className='form-check form-check-sm form-check-custom form-check-solid'>
                            {response.id}

                            {/* <input
                              className='form-check-input widget-9-check'
                              type='checkbox'
                              checked={isChecked(response.id)}
                              onChange={(event) => handleCheckboxChange(event, response.id)}
                            /> */}
                          </div>
                        </td>
                        <td className='text-center '> {formatDate(response.created_at)}</td>
                        <td className='w-50px text-center '>
                          <div className='d-flex justify-content-center align-items-center gap-1 '>
                            {response.empreendimento_temperatura ? (
                              <>
                                <span
                                  className='badge badge-temperatura fw-bold me-auto px-4 py-2 p-5 '
                                  style={{
                                    backgroundColor: response.empreendimento_temperatura?.cor,
                                  }}
                                >
                                  {response.empreendimento_temperatura?.nome}
                                </span>
                              </>
                            ) : (
                              <span className='flex-fill'> </span>
                            )}
                          </div>
                        </td>

                        <td className='text-center'>
                          <div className='d-flex justify-content-center align-items-center gap-1 '>
                            {response.empreendimento_situacao ? (
                              <>
                                <span
                                  className='badge  fw-bold me-auto px-1 py-2'
                                  style={{
                                    backgroundColor: response.empreendimento_situacao?.cor,
                                    color: response.empreendimento_situacao?.cor,
                                  }}
                                >
                                  .
                                </span>
                                <span className='flex-fill'>
                                  {' '}
                                  {response.empreendimento_situacao?.nome}
                                </span>
                              </>
                            ) : (
                              <span className='flex-fill'>
                                {' '}
                                {response.empreendimento_situacao?.nome}
                              </span>
                            )}
                          </div>
                        </td>

                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start align-items-center flex-row'>
                              {response.bloqueado === 1 ? (
                                <div>
                                  <BiInfoCircle
                                    className='fs-6 me-5 text-warning'
                                    title='O terreno foi descartado pois já se passaram 30 dias em Prospecção Ativa. Não é possível fazer alterações.'
                                  ></BiInfoCircle>
                                </div>
                              ) : (
                                <></>
                              )}
                              {response.bloqueado === 2 ? (
                                <div>
                                  <BiInfoCircle
                                    className='fs-6 me-5 text-danger'
                                    title='Terrenos com sobreposição de quadra pendente de aprovação..'
                                  ></BiInfoCircle>
                                </div>
                              ) : (
                                <></>
                              )}
                              <div className='d-flex justify-content-start flex-column'>
                                <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                  {response.nome}
                                </span>

                                {response.users.name}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td> {response.setor_quadra}</td>
                        {getPermissaoAcao('EDICAO_MASTER', response.users.id) ? (
                          <td>
                            {' '}
                            <GetUsuariosEquipesByCargoChosen
                              SetValue={(value) => {
                                changeUser(response.id, Number(value), Number(response.users.id))
                                // setValue('situacao_id', value)
                              }}
                              value={response.users.id}
                            ></GetUsuariosEquipesByCargoChosen>
                          </td>
                        ) : (
                          <></>
                        )}
                        {/* <td>
                            {response.empreendimento_terreno?.length > 0
                              ? response.empreendimento_terreno[0].terreno.numero_contribuinte
                              : '-'}{' '}
                          </td> */}
                        <td>{response.area_total_terreno}</td>
                        <td>
                          {response.empreendimento_terreno.length > 0
                            ? response.empreendimento_terreno[0].terreno.zoneamento
                            : '-'}{' '}
                        </td>
                        <td>
                          {tags.length > 0
                            ? tags.map(function (tag, y) {
                                return (
                                  <span className='badge text-bg-info me-2 mb-2' key={y}>
                                    {tag}
                                  </span>
                                )
                              })
                            : '-'}
                        </td>

                        <td>
                          <Buttons
                            id={response.id}
                            idUser={response.users.id}
                            bloqueado={response.bloqueado}
                          />
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={10} className='text-center'>
                      Nenhum registro encontrado
                    </td>
                  </tr>
                )
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      </CardTables>
    </>
  )
}

export {Table}
