import {useEffect, useState} from 'react'
import Select from 'react-select'
import {FormLabel} from 'react-bootstrap'
import {QueryGetEmpreendimentoSituacao} from '../../../../graphql/services/EmpreendimentoSituacao'
import {FaPersonWalkingWithCane} from 'react-icons/fa6'

export function GetEmpreendimentoSituacaoChosen({
  SetValueSituacao,
  valueSituacao,
  disabled = false,
  isMulti = false,
}) {
  let situacaoArray = []

  const [situacao, setSituacao] = useState<any>(isMulti ? [] : '')

  useEffect(() => {
    setSituacao(valueSituacao)
  }, [valueSituacao])

  const {data} = QueryGetEmpreendimentoSituacao({
    variables: {
      pagination: {
        pagina: 0,
        quantidade: 100,
      },
    },
  })

  if (!data) {
    return (
      <>
        <div className='col-form-label'>
          <FormLabel className='form-label'>Situação</FormLabel>
          <Select
            options={situacaoArray}
            onChange={(e) => {
              setSituacao(e.value)
            }}
            value={situacaoArray.filter((e) => e.value)[0]}
            name='id_situacao'
            placeholder='Situações Disponiveis'
          />
        </div>
      </>
    )
  }
  if (!isMulti) {
    situacaoArray.push({value: '', label: '--selecione--'})
  }
  data.GetEmpreendimentoSituacao.result.map((Options) => {
    situacaoArray.push({value: Options.id, label: Options.nome})
    return []
  })

  return (
    <>
      <div className='form-group'>
        <FormLabel className='form-label'>Situação</FormLabel>
        <Select
          value={
            isMulti
              ? situacaoArray.filter((e) => situacao?.includes(e.value))
              : situacaoArray.find((e) => e.value === situacao) || null
          }
          options={situacaoArray}
          onChange={(e) => {
            if (isMulti) {
              setSituacao(e.map((item: {value: any}) => item.value))
              SetValueSituacao(e.map((item: {value: any}) => item.value))
            } else {
              setSituacao(e.value)
              SetValueSituacao(e.value)
            }
          }}
          name='id_situacao'
          placeholder='Situação'
          isDisabled={disabled}
          isMulti={isMulti}
        />
      </div>
    </>
  )
}
