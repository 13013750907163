import React from 'react'

import {Link} from 'react-router-dom'
import {MutationSetLogAcessoRecurso} from '../../../../../app/graphql/services/LogAcesso'
import {BiChart, BiCopy, BiListUl, BiMap, BiSolidDashboard} from 'react-icons/bi'
import {isDiretor} from '../../../../../app/utils/PermissoesAcesso'

export function MenuEmpreendimentos() {
  const {FormSetLogAcessoRecurso} = MutationSetLogAcessoRecurso()

  return (
    <>
      <span className='menu-link'>
        <span className='menu-icon'>
          <i className='ki-duotone ki-address-book fs-2'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
          </i>
        </span>
        <span className='menu-title'>Empreendimentos</span>
        <span className='menu-arrow'></span>
      </span>
      <div className='m-0'>
        <ul className='nav nav-pills nav-pills-custom flex-column border-transparent fs-5 fw-bold'>
          <li className='nav-item mt-5 d-flex align-items-center'>
            <Link
              to='/empreendimentos/dashboard'
              onClick={() =>
                FormSetLogAcessoRecurso({
                  ferramenta: 'empreendimentos',
                  funcionalidade: 'dashboard',
                  action: 'list',
                  url: '/empreendimentos/dashboard',
                })
              }
              className='sub-menu-secondary nav-link text-muted text-active-primary text-hover-success p-0 border-0 '
            >
              <BiSolidDashboard className='fs-1 me-1'></BiSolidDashboard>
              Dashboard
            </Link>
          </li>
          <li className='nav-item  mt-2 d-flex align-items-center'>
            <Link
              to='/empreendimentos'
              onClick={() =>
                FormSetLogAcessoRecurso({
                  ferramenta: 'empreendimentos',
                  funcionalidade: 'empreendimentos',
                  action: 'list',
                  url: '/empreendimentos',
                })
              }
              className='sub-menu-secondary nav-link text-muted text-active-primary text-hover-success p-0 border-0 '
            >
              <BiListUl className='fs-1 me-1'></BiListUl>
              Meus Empreendimentos
            </Link>
          </li>
          <li className='nav-item mt-2 d-flex align-items-center'>
            <Link
              to='/empreendimentos/mapa'
              onClick={() =>
                FormSetLogAcessoRecurso({
                  ferramenta: 'empreendimentos',
                  funcionalidade: 'mapa',
                  action: 'list',
                  url: '/empreendimentos/mapa',
                })
              }
              className='sub-menu-secondary nav-link text-muted text-active-primary text-hover-success p-0 border-0 '
            >
              <BiMap className='fs-1 me-1'></BiMap>
              {/* <FaCaretRight
                size={20}
                color='#8a8a8a'
                className='pe-2 pb-1 text-active-primary text-hover-secondary'
              /> */}
              Mapa
            </Link>
          </li>
          <li className='nav-item mt-2 d-flex align-items-center'>
            <Link
              to='/empreendimentos/relatorios'
              onClick={() =>
                FormSetLogAcessoRecurso({
                  ferramenta: 'empreendimentos',
                  funcionalidade: 'relatorios',
                  action: 'list',
                  url: '/empreendimentos/relatorios',
                })
              }
              className='sub-menu-secondary nav-link text-muted text-active-primary text-hover-success p-0 border-0 '
            >
              <BiChart className='fs-1 me-1'></BiChart>
              Relatório
            </Link>
          </li>
          {isDiretor() ? (
            <li className='nav-item mt-2 d-flex align-items-center'>
              <Link
                to='/empreendimentos/mesma-quadra'
                onClick={() =>
                  FormSetLogAcessoRecurso({
                    ferramenta: 'empreendimentos',
                    funcionalidade: 'relatorios',
                    action: 'list',
                    url: '/empreendimentos/relatorios',
                  })
                }
                className='sub-menu-secondary nav-link text-muted text-active-primary text-hover-success p-0 border-0 '
              >
                <BiCopy className='fs-1 me-1'></BiCopy>
                Empreendimentos mesma quadra
              </Link>
            </li>
          ) : (
            <></>
          )}
          {/* <li className='nav-item mt-2 d-flex align-items-center'>
            <Link
              to='/empreendimentos/excecao-quadra'
              onClick={() =>
                FormSetLogAcessoRecurso({
                  ferramenta: 'empreendimentos',
                  funcionalidade: 'relatorios',
                  action: 'list',
                  url: '/empreendimentos/relatorios',
                })
              }
              className='sub-menu-secondary nav-link text-muted text-active-primary text-hover-success p-0 border-0 '
            >
              <BiCopy className='fs-1 me-1'></BiCopy>
              Terrenos com sobreposição
            </Link>
          </li> */}
        </ul>
      </div>
    </>
  )
}
