import {useMemo, useState} from 'react'
import {Imoveis} from '../../types'
import {useImoveisStore} from '../../context/DataContext'
import {BarChartPorVaga} from './BarChartPorVaga'

type Props = {
  label?: string
  itens: Imoveis[]
}

export default function MediaPrecoPorVaga({label, itens}: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {filtro, segmentacao} = useImoveisStore()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedItem, setSelectedItem] = useState(null)
  let {chartData, groups} = useMemo(() => {
    let ticketMedio = Array(15).fill([], 0, 15)
    let totalQuartos = Array(15).fill(0, 0, 15)
    let chartData = []
    let groups = []

    if (itens && filtro !== null) {
      // eslint-disable-next-line array-callback-return
      itens.map((item) => {
        if (item.quartos < 10) {
          if (typeof ticketMedio[item.quartos] === 'undefined') {
            ticketMedio = []
          }

          if (typeof ticketMedio[item.quartos][item.vagas] === 'undefined') {
            ticketMedio[item.quartos] = Array(15).fill(0, 0, 15)
          }

          if (typeof totalQuartos[item.quartos][item.vagas] === 'undefined') {
            totalQuartos[item.quartos] = Array(15).fill(0, 0, 15)
          }

          totalQuartos[item.quartos][item.vagas] = totalQuartos[item.quartos][item.vagas] + 1
          ticketMedio[item.quartos][item.vagas] += item.valor / item.area
        }
      })

      // eslint-disable-next-line array-callback-return
      ticketMedio.map((item, index) => {
        if (item.length > 0) {
          let count = 0
          // eslint-disable-next-line array-callback-return
          item.map((valor, indexVagas) => {
            if (valor) {
              chartData.push({
                x: '' + indexVagas, //Vagas
                y: valor / totalQuartos[index][indexVagas],
                vagas: index,
              })

              count++
            }
          })
          groups.push({title: index, cols: count})
        }
      })
      chartData = ordenarPorVagas(chartData)
    }
    return {chartData, groups}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itens, filtro])
  function ordenarPorVagas(array) {
    return array.sort((a, b) => a.vagas - b.vagas)
  }

  if (!itens) {
    return <>Carregando..</>
  }

  return (
    <BarChartPorVaga
      className={'primary'}
      title={'Média do Preço/m² por Dorm-Vaga'}
      subtitle={''}
      data={chartData}
      groups={groups}
      onChange={(id: number) => {
        setSelectedItem(id)
      }}
      legenda={['Total Unidades']}
    >
      <table className='table  table-row-dashed table-row-gray-300 table-hover'>
        <thead>
          <tr className='text-dark fw-bold'>
            {/* {chartDataVSOMetragemTable && chartDataVSOMetragemTable.length > 0
              ? Object.entries(chartDataVSOMetragemTable[0]).map((item, index) => {
                  return item[0] !== 'id' ? (
                    <th key={index} className='text-center'>
                      {Dicionario.translate(item[0])}
                    </th>
                  ) : null
                })
              : null} */}
          </tr>
        </thead>
        <tbody>
          {/* {chartDataVSOMetragemTable
            ? chartDataVSOMetragemTable.map((item, index) => {
                return (
                  <tr key={index} className={item.id === selectedItem ? 'select-data-table' : ''}>
                    <td className='text-center'>{item.preco}</td>
                    <td className='text-center'>
                      {formatNumber(item.ticketMedio, 'currency_inteiro')}
                    </td>
                    <td className='text-center'>
                      {formatNumber(item.precoMedioM2, 'currency_inteiro')}
                    </td>
                    <td className='text-center'>{item.totalUnidades}</td>
                  </tr>
                )
              })
            : null} */}
        </tbody>
      </table>
    </BarChartPorVaga>
  )
}
