import {useState} from 'react'
import {Endereco} from '../../ComponentsControl/EnderecoControl/endereco'
import {createTile} from '../../Layer/Layers/GoogleMapsTile'
import {Nav} from 'react-bootstrap'

export const LoteBtn: React.FC = () => {
  const [activeLayer, setActiveLayer] = useState(null)
  const municipio = Endereco.getMunicipio()
  if (!municipio) {
    return <></>
  }

  if (municipio.lote_fiscal !== 1) {
    return <></>
  }

  function onClick(item) {
    if (item.target.checked) {
      let url = 'lote_fiscal'
      if (municipio.municipio_url !== 'sao-paulo') {
        url = 'lote_fiscal_' + municipio.municipio_url.replace(/-/gm, '_')
      }
      const layerImg = new createTile(url, function () {})
      setActiveLayer(layerImg)
    } else {
      activeLayer.remove()
    }
  }
  return (
    <>
      <Nav className=' ms-3 my-2 my-lg-0  ' style={{maxHeight: '100px'}}>
        <div className='card pt-1 '>
          <div className='menu-link d-flex'>
            <span className='menu-icon- mx-2'>Lotes</span>
            <span className='menu-title'>
              <div className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input h-20px w-30px'
                  type='checkbox'
                  value={0}
                  id='flexSwitchDefault'
                  onClick={onClick}
                />
              </div>
            </span>
          </div>
        </div>
      </Nav>
    </>
  )
}
