import {Col} from 'react-bootstrap'
import {RangeSlider} from '../../../../components/UI'
import {KTSVG} from '../../../../../_metronic/helpers'
import {AreaInfluencia} from './AreaInfluencia/AreaInfluencia'
import {useEffect, useState} from 'react'
import {useImoveisStore} from '../../context/DataContext'
import Swal from 'sweetalert2'

import SelectDormitoriosFiltro from '../../../inteligencia-mercado/components/UI/SelectDormitoriosFiltro'
import {SelectTipologia} from '../SelectTipologia'

export function Filtro({height}: {height: number}) {
  const [filtroLocal, setFiltroLocal] = useState(null)
  const {endereco, setFiltro, filtro, geometria, fetchData} = useImoveisStore()

  useEffect(() => {
    setFiltroLocal({...filtro})
  }, [filtro])

  let marks = {}
  marks[2000] = 2000
  marks[2024] = 2024

  function onchangeFilter(filterInput: string, valor: string | number | []) {
    if (filtroLocal) {
      let fiterTemp = filtroLocal
      fiterTemp[filterInput] = valor
      setFiltroLocal(fiterTemp)
    }
  }

  function aplicarFiltro() {
    if (!filtroLocal?.tipologia) {
      Swal.fire('', 'Selecione uma tipologia.', 'warning')
    } else {
      if (endereco) {
        setFiltro({...filtroLocal})
        if (geometria) {
          fetchData(geometria, 'RAIO')
        }
      } else {
        Swal.fire('', 'Digite um endereço.', 'warning')
      }
    }
  }

  return (
    <>
      <div className='filtro2-fixed ' style={{height: height}}>
        <div className='filtro2-form   pt-5  p-0 ps-6 pe-10' style={{height: height - 40}}>
          <div className='accordion accordion-icon-toggle' id='kt_accordion_2'>
            <div className='mb-5'>
              <div
                className='accordion-header py-3 d-flex'
                data-bs-toggle='collapse'
                data-bs-target='#kt_accordion_2_item_1'
              >
                <span className='accordion-icon'>
                  <KTSVG
                    className='svg-icon svg-icon-4'
                    path='media/icons/duotune/arrows/arr064.svg'
                  />
                </span>
                <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>Área de influência</h3>
              </div>
              <div
                id='kt_accordion_2_item_1'
                className='fs-6 collapse show ps-1'
                data-bs-parent='#kt_accordion_2'
              >
                <Col sm='12' className='py-5'>
                  <AreaInfluencia
                    setFiltroLocal={setFiltroLocal}
                    filtro={filtroLocal}
                  ></AreaInfluencia>
                </Col>
              </div>
            </div>

            <div className='mb-5'>
              <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'> Tipologia</h3>
              <Col sm='12' className='py-5 px-4'>
                <SelectTipologia
                  onChange={(e) => {
                    onchangeFilter('tipologia', e)
                  }}
                  itensSelected={filtroLocal?.tipologia || null} // selected={filter?.dormitorios || null}
                ></SelectTipologia>
              </Col>
            </div>
          </div>
        </div>
        <div className='all-start-between button-position px-5'>
          <button
            type='button'
            className='btn btn-primary d-flex align-items-end'
            name='Avançar'
            value='Avançar'
            onClick={() => aplicarFiltro()}
          >
            Aplicar Filtro
          </button>
        </div>
      </div>
    </>
  )
}
