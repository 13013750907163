import {EmpreendimentoResponse} from '../../../../../graphql/type'
import {QueryGetEmpreendimentoCorretores} from '../../../../../graphql/services/EmpreendimentoCorretores'
import {Buttons} from './partials/ButtonsCorretores'
import {Loader} from '../../../../../components/UI/Loader/Loader'
import {BiPlus} from 'react-icons/bi'
import {ModalCorretor} from './partials/ModalCorretor'
import {CreateFormImovelCorretor} from './Corretores/Meus-Imoveis-Corretor'
import {formatDate} from '../../../../explorer/components/common'

type Props = {
  data: EmpreendimentoResponse
  param: string
}

export const TabCorretores: React.FC<Props> = ({data, param}) => {
  const idEmpreendimento = data.GetEmpreendimento.id
  const dataCorretores = QueryGetEmpreendimentoCorretores({
    variables: {
      pagination: {
        quantidade: null,
        pagina: null,
      },
      idEmpreendimento: idEmpreendimento,
    },
  })

  if (!dataCorretores) {
    return <Loader text={'caregando...'}></Loader>
  }

  return (
    <div
      className={`tab-pane fade ${param === 'corretores' ? ' show active' : ''}`}
      id='corretores'
      role='tabpanel'
      aria-labelledby='corretores'
    >
      <div className='card'>
        <div className='card-header'>
          <h3 className='card-title'>Corretores Associados</h3>
          <div className='card-toolbar'>
            <CreateFormImovelCorretor id_empreendimento={idEmpreendimento} />
          </div>
        </div>
        <div className='card-body p-0 pt-5'>
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-hover table-row-bordered  table-rounded table-striped gs-7'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th className='min-w-50px'>Nome</th>
                  <th className='min-w-150px'>Email</th>
                  <th className='min-w-100px '>Telefone</th>
                  <th className='min-w-100px '>Empresa</th>
                  <th className='min-w-100px '>Data</th>
                  {/* <th className='min-w-100px text-end'>Ações</th> */}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {dataCorretores.GetEmpreendimentoCorretores.map((Response) => {
                  return (
                    <tr key={Response.id}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <p className='text-dark fs-6'>{Response.nome}</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p className='text-dark d-block fs-6'>{Response.email}</p>
                      </td>
                      <td>
                        <p className='text-dark d-block fs-6'>{Response.telefone}</p>
                      </td>
                      <td>
                        <p className='text-dark d-block fs-6'>{Response.empresa}</p>
                      </td>
                      <td>
                        <p className='text-dark d-block fs-6'>
                          {formatDate(Response.data_cadastro)}
                        </p>
                      </td>
                      <td>
                        {Response.situacao === 0 ? (
                          <p className=' badge badge-danger'>removido</p>
                        ) : (
                          <p className=' badge badge-success'>ativo</p>
                        )}
                      </td>
                      <td>
                        {Response.situacao !== 0 ? (
                          <Buttons idCorretor={Response.id} idEmpreendimento={idEmpreendimento} />
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            {/* end::Table */}
          </div>
        </div>
      </div>
    </div>
  )
}
