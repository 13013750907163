import {gql} from '@apollo/client'

export const GET_EQUIPE = gql`
  query GetEquipe($pagination: Pagination!) {
    GetEquipe(pagination: $pagination) {
      pageInfo {
        currentPage
        totalPages
        totalItems
        hasNextPage
        hasPreviousPage
      }
      result {
        id
        nome
      }
    }
  }
`

export const GET_EMPRESA_USUARIOS_EQUIPE_ADMIN = gql`
  query GetEmpresaUsuariosEquipesAdmin {
    GetEmpresaUsuariosEquipesAdmin {
      result {
        id
        name
      }
    }
  }
`

export const GET_EQUIPE_BY_ID = gql`
  query GetEquipeById($getEquipeByIdId: Float!) {
    GetEquipeById(id: $getEquipeByIdId) {
      id
      nome
      telefone
      email
      situacao
      empresa
    }
  }
`
